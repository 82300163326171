import React, { useRef, useState } from 'react'
import {motion} from "framer-motion";
import {  Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Tabs, TabsHeader, TabsBody, Tab, TabPanel,} from "@material-tailwind/react";

function NGO(){

  const[open, setOpen] = useState(true);

  // --------------------------------------------subscribe to our newsletter--------------------------------
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_2shjaqt', 
        'template_yb1ikfz', 
        form.current, {
        publicKey: 'KapdrjZ67SvjEm1Jl',
      })
      .then(
        () => {
          toast.success('Subscribed', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
            });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  // --------------------------------------------contact---------------------------
  const[user, setUser] = useState(
    {
        Name: '', Number:'', Message:''
    }
  )
  // const [errorMsg, setErrorMsg] = useState("");
  let name, value
  const data = (e) =>
  {
    console.log(user)
    name = e.target.name;
    value = e.target.value;
    setUser({...user, [name]:value});
  }
  const getdata = async (e) => 
  {
  if (!user.Name || !user.Number || !user.Message) {
    // setErrorMsg("Fill all fields");
    return;
  }
    // setErrorMsg("");
    
    const{Name, Number, Message} = user;
    e.preventDefault();
    const options = {
        method: 'POST',
        headers: {
            'Content-type' : 'application/json'
        },
        body: JSON.stringify({
            Name, Number, Message
        })
    }
    const res = await fetch(
        'https://govyapar-contact-default-rtdb.firebaseio.com/UserData.json',
        options
        )
        if(res)
        {
          toast.success('Message sent successfully', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
            });
        }
        else
        {
            alert("Error Ocurred")
        }
      }

      const [activeTab, setActiveTab] = useState("Overview");
      const data2 = [
        // ----------------------------------1-----------------------------
        {
          label: (<label className='z-0'>Overview</label>),
          value: "Overview",
          desc: (
            <div>
            <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Trust Registration- An Overview</h1>
            <p>Trust registration in Delhi involves establishing a trust that is managed by a person (the settlor) to a trustee. The trustee holds and manages it for the benefit of specific individuals (the beneficiaries). To register a trust in Delhi, one needs to draft a trust deed that outlines the trust's objectives. It encompasses details of trustees and beneficiaries. The trust deed should then be executed and signed by the settlor and trustees. They are registered with the local sub-registrar office where the trust's registered office. This registration helps establish the legal validity of the trust.</p>
            </div>
          )
        },
        // -----------------------------2----------------------------
        {
          label: (<label className='z-0'>Benefits</label>),
          value: "Benefits",
          desc: (
            <div>
            <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Overview</h1>
            <ul>
          <li>
            <h1 className='font-bold'>Perpetual Existence</h1>
            <p>A registered trust in Delhi has perpetual existence. It can continue its operations even if there are changes in the trustees. The trust's activities and objectives can be carried forward across generations. It ensures the continuity of its charitable or religious initiatives.</p>
          </li>
          <br/>
          <li>
            <h1 className='font-bold'>Limited liability</h1>
            <p>One significant benefit of trust registration is limited liability protection. The trustees of a registered trust generally have limited personal liability. In case of legal disputes or debts, the personal assets of the trustees are protected. The trust's assets are used to satisfy the liabilities.</p>
          </li>
          <br/>
          <li>
          <h1 className='font-bold'>Enhanced Credibility</h1>
            <p>Trust registration enhances the credibility of the organisation. Especially beneficial for the eyes of donors, and government authorities. Being a registered entity demonstrates transparency and accountability. It can attract more donations, grants, and collaborations with other organisations.</p>
          </li>
          <br/>
          <li>
          <h1 className='font-bold'>Operational Advantages</h1>
            <p>Registered trusts are subject to certain rules and regulations that provide a framework. This framework promotes accountability, transparency, and governance within the trust. It helps streamline the trust's activities, financial management, and reporting procedures. It also ensures better operational efficiency.</p>
          </li> 
        </ul>
            </div>
          )
        },
        // -----------------------------3----------------------------
        {
          label: (<label className='z-0'>Documents Required</label>),
          value: "Documents Required",
          desc: (
            <div>
        <h1 className='text-lg md:text-2xl  text-yellow-500 font-semibold'>Documents Required for Trust Registration in Delhi</h1>
        <p>The following documents are required for trust registration in Delhi:</p>
        <ul className='list-disc pl-4'>
          <li>Trust deed</li>
          <li>Proof of identity and address for trustee</li>
          <li>Proof of address of the trust</li>
          <li>Bank account details of the trust</li>
          <li>A copy of the PAN card of the trust</li>
        </ul>
        </div>
          )
        },
        // -----------------------------4----------------------------
        {
          label: (<label className='z-0'>Eligibility Criteria</label>),
          value: "Eligibility Criteria",
          desc: (
            <div>
            <h1 className='text-lg md:text-2xl  text-yellow-500 font-semibold'>Eligibility Criteria for Trust Registration in Delhi</h1>
            <ul className='list-disc pl-4'>
              <li>Seeking a competent and willing individual or entity for property transfer</li>
              <li>Minimum requirement of two individuals or corporate entities</li>
              <li>The trust activities must be lawful</li>
              <li>The trust should function within charitable, religious, educational, or social welfare domains</li>
              <li>Operation should be non-profit, utilising income and property solely for stated objectives</li>
              <li>Execution of a trust deed to define objectives, rules, and regulations</li>
              <li>Necessary to obtain relevant authority, submit documents, and pay fees</li>
              <li>Adherence to rules, regulations, and reporting obligations is expected.</li>
            </ul>
            </div>
          )
        },
        // -----------------------------5----------------------------
        {
          label: (<label className='z-0'>Checklist</label>),
          value: "Checklist",
          desc: (
            <div>
            <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Checklist for Trust Registration in Delhi</h1>
            <ul className='list-disc pl-4'>
              <li>Identify a competent settlor willing to transfer property</li>
              <li>Appoint a minimum of two trustees, individuals or corporate entities</li>
              <li>Determine lawful objectives falling under charitable, religious, educational, or social welfare activities</li>
              <li>Prepare a trust deed clearly defining objectives, rules, and regulations</li>
              <li>Execute the trust deed with proper signatures and witnesses</li>
              <li>Prepare the application for trust registration with required details</li>
              <li>Gather necessary documents, including identity proofs, address proofs, and property details</li>
              <li>Arrange for the payment of the registration fee</li>
              <li>Submit the application and supporting documents to the Registrar of Trusts</li>
              <li>Ensure compliance with all applicable rules and regulations.</li>
            </ul>
            </div>
          )
        },
        // -----------------------------6----------------------------
        {
          label: (<label className='z-0'>Process</label>),
          value: "Process",
          desc: (
            <div>
        <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Process for Trust Registration in Delhi</h1>
        <p>The process of trust registration in Delhi is as follows:</p><br/>
        <ul className=''>
          <li>
            <h1 className='font-bold'>Step 1: Consultation and Document Collection</h1>
            <p>Get expert consultation to understand your requirements. Avail help in choosing a unique trust name and the details of the trust you want to register. Submit the required documents for registration.</p>
          </li>
          <br/>
          <li>
          <h1 className='font-bold'>Step 2: Trust Deed Drafting</h1>
            <p>Govyapar's legal experts would draft the trust deed according to your specifications. We ensure it complies with the Indian Trusts Act and relevant state laws. We will tailor the trust deed to reflect your intentions and objectives.</p>
          </li>
          <br/>
          <li>
          <h1 className='font-bold'>Step 3: Submit the Application</h1>
            <p>With the trust deed and documents in hand, our team will file for registration. We make sure that all the information is accurate</p>
          </li> 
          <br/>
          <li>
          <h1 className='font-bold'>Step 4: Follow-Up and Verification</h1>
            <p>Our team will address any queries or requirements arising during the process.</p>
          </li> 
          <br/>
          <li>
          <h1 className='font-bold'>Step 5: Registration and Documentation</h1>
            <p>Post approval you will get a registration certificate from the Sub-Registrar's office. On request, we might also assist you in obtaining a PAN and open a bank account for the trust</p>
          </li> 
        </ul>
        </div>
          )
        },
      ]

  return (
    <>
   <div className=''>
    <a href='https://wa.me/919808030923'>
    <img src='WhatsappIcon.png' className='fixed w-[28%] md:w-[13%] lg:w-[10%] right-0 top-[520px] md:top-[470px] z-10' alt=''/></a>
     <Link to ="/scheduleCall"><button className='text-white bg-yellow-600 border-2 border-black p-2 rounded-full cursor-pointer z-20 fixed w-[40%] sm:w-[20%] md:w-[24%] lg:w-[20%] xl:w-[16%] right-3 top-[520px] md:top-[470px] overflow-hidden mt-16 sm:mt-24 xl:mt-24 text-xs md:text-lg'><i className="fa fa-clock text-white text-xs md:text-lg"></i> Schedule a Call</button></Link>
    </div>

   {/* -------------------------------------Mobile devices Registration ngo-------------------------------------/ */}
   <div className='max-w-full mx-0 px-8 block md:hidden pt-32'>
 <div className="relative flex flex-col text-gray-700 bg-[#C0C0C0] shadow-xl z-[-1]">
  <div className="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-clip-border">
  </div>
  <div className="p-6 text-center">
    <h4 className="block mb-2 font-sans text-2xl sm:text-3xl antialiased leading-snug tracking-normal font-bold text-black">
    Register Your NGO Today
    </h4>
    <p>
        <form className='py-4 text-center'>
        <label className='pt-2'>Email</label><br/>
        <input type='email'
         placeholder='Enter your Email'
         className='rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>Mobile Number</label><br/>
        <input type='tel'
          placeholder="Enter Phone Number" 
          maxLength={10}
         className='rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>City/Pincode</label><br/>
        <input type=''
         placeholder='Enter your City/Pincode'
         className='rounded-sm p-1 border-1'/>
         <br/>
      </form>
    </p>
  </div>
  <div className="flex justify-center p-6 py-2 mb-4 gap-7">
  <button className='items-center bg-black text-white p-2 rounded-md'>Book an Appointment Now</button>
  </div>
</div>
</div>
<div className=' bg-[#E0E0E0] relative bottom-40 z-[-2] pb-10 pt-80 px-4 block md:hidden'>
  <h1 className='text-2xl font-extrabold'>Register Your NGO with Govyapar</h1>
  <h2 className="py-6 text-2xl font-semibold">
  <ul className='text-lg md:text-xl space-y-2 pb-3 text-justify md:text-left py-2'>
      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Get started with your NGO registration. Bylaws/Deed/MOA drafting and filing completed within 7 business days. <span className='font-bold text-blue-950'>T&C*</span>
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Book a slot and consult an NGO expert, get complete guidance to set up your NGO
      </li>
  </ul>
  </h2> 
</div>

{/* ------------------------------------------------------MEDIUM AND LARGE SCREN- Registration ngo--------------------------------- */}
<div className="w-full mx-0 md:block hidden md:pt-28 pt-12">
  <div className='bg-[#E0E0E0] float-left w-[45%] h-screen pl-10 lg:pl-32 pt-24 xl:pl-52 2xl:pl-80'>
  <div className="relative flex flex-col text-gray-700 bg-[#C0C0C0] shadow-xl left-8 lg:left-12">
  <div className="relative mt-4 overflow-hidden text-gray-700 bg-clip-border">
  </div>
  <div className="p-6 text-center">
    <h4 className="block mb-2 font-sans text-2xl lg:text-3xl antialiased leading-snug tracking-normal font-bold text-black">
    Register Your NGO Today <br/>
    </h4>

    <p>
      <form className='py-4 text-center hidden md:block'>
      <label className='pt-2'>Email</label><br/>
      <input type='email'
       placeholder='Enter your Email'
       className='rounded-sm p-1 border-1'/>
      <br/>
      <label className='pt-2'>Mobile Number</label><br/>
      <input type='tel'
        placeholder="Enter Phone Number" 
        maxLength={10}
       className='rounded-sm p-1 border-1'/>
      <br/>
      <label className='pt-2'>City/Pincode</label><br/>
      <input type=''
       placeholder='Enter your City/Pincode'
       className='rounded-sm p-1 border-1'/>
       <br/>
    </form>
    </p>
  </div>
  <div className="flex justify-center p-6 py-2 mb-4 gap-7">
  <button className='items-center bg-black text-white p-2 rounded-md'>Book an Appointment Now</button>
  </div>
</div>
  </div>

  <div className='bg-white h-screen px-10 pt-3 pr-28 lg:pt-16 lg:pr-32 xl:pr-52 2xl:pr-80'>
  <div className='relative bottom-60 pb-10 md:pt-[340px] lg:pt-[310px] xl:pt-[] px-7 left-10 lg:left-20 lg:px-10 overflow-hidden'>
  <h1 className='md:text-3xl lg:text-4xl xl:text-6xl font-extrabold'>Register Your NGO with Govyapar</h1>
  <h2 className="py-5 text-xl lg:text-2xl font-semibold">
    <ul className='text-lg md:text-xl space-y-2 pb-3 text-justify md:text-left py-2'>
      <li><i className="fas fa-check-square text-yellow-500"></i> Get started with your NGO registration. Bylaws/Deed/MOA drafting and filing completed within 7 business days. <span className='font-bold text-blue-950'>T&C*</span></li>
      <li><i className="fas fa-check-square text-yellow-500"></i> Book a slot and consult an NGO expert, get complete guidance to set up your NGO</li>
      </ul>
  </h2>

</div>
  </div>
</div>

    {/* ---------------------------------------------------NGO Types------------------------------------------------- */}
    <div className='py-20 md:px-10 lg:px-32 md:bg-gradient-to-t from-black via-gray-400 to-black relative bottom-20 md:bottom-0'>
      <h1 className='text-2xl md:text-3xl text-center font-bold text-black md:text-white'>NGO Types</h1>
      <p className='text-md md:text-lg px-2 md:px-32 text-justify text-black md:text-white'>Non-governmental organisations like Trusts, Societies, and Section 8 Companies work towards the betterment of our society. You can register your NGO through Govyapar and get a certificate soon.</p>
      <div className="px-20 sm:px-40 md:px-10 py-4">
    <motion.ul 
    initial={{y:100, opacity:0}}
    whileInView={{y:0, opacity:1}}
    transition={{delay:0.2, y:{type:"spring", stiffness:60}, opacity:{duration:0.}, ease:"easeIn", duration:1}}
    className="grid grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-6">

    <li className="bg-white p-3 border-1 shadow-md rounded-md cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 text-center">
     <p className="text-md lg:text-2xl font-bold">
      <img src="ngo trust.jpg" className=' w-[30%] px-2 py-1 mx-auto rounded-lg' alt='ngo'/>  
      </p>
      <h1 className='font-bold text-xl text-center text-yellow-500 py-2'>Trust Registration</h1>
      <p className='text-sm text-justify text-black'>Trust is an entity registered under the Indian Trust Act, 1882. They are created to focus on the development of science, literature and other noble causes.</p><br/>
      <button type="submit" className="bg-yellow-600 border-2 border-black text-black font-extrabold py-2 px-4 rounded-md text-sm hover:scale-105 duration-300 ease-in">Get Started</button>
    </li>

    <li className="bg-white p-3 border-1 shadow-md rounded-md cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 text-center">
    <p className="text-md lg:text-2xl font-bold">
      <img src="ngo section.png" className='w-[28%] px-2 py-1 mx-auto rounded-lg' alt='ngo'/>  
      </p>
      <h1 className='font-bold text-xl text-center text-yellow-500 py-2'>Section 8</h1>
      <p className='text-sm text-justify text-black'>A Section 8 company registered under the Companies Act, 2013 is an organisation functioning with an objective to promote non - profit activities like education, social welfare and environmental preservation.</p><br/>
      <button type="submit" className="bg-yellow-600 border-2 border-black text-black font-extrabold py-2 px-4 rounded-md text-sm hover:scale-105 duration-300 ease-in">Get Started</button>
    </li>
    
    <li className="bg-white p-3 border-1 shadow-md rounded-md cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 text-center">
    <p className="text-md lg:text-2xl font-bold">
      <img src="ngo society.jpg" className='w-[40%] px-2 py-1 mx-auto rounded-lg' alt='ngo'/>  
      </p>
      <h1 className='font-bold text-xl text-center text-yellow-500 py-2'>Society Registration</h1>
      <p className='text-sm text-justify text-black'>A society is an organisation involved in charitable activities like education, art, religion, culture, music, and sports. It is registered under the Societies Registration Act of 1860.</p><br/>
      <button type="submit" className="bg-yellow-600 border-2 border-black text-black font-extrabold py-2 px-4 rounded-md text-sm hover:scale-105 duration-300 ease-in">Get Started</button>
    </li>
    </motion.ul>
    </div>
    </div>


    {/* -------------------------------------------------------NGO Side Navbar----------------------- */}
    <div className='px-28 py-10 hidden md:block'>
    {/* <Tabs value={activeTab}
    className="grid grid-cols-2 grid-rows-1">
  
      <TabsHeader
        className="rounded-md bg-white shadow-md border-1 border-black pt-10 z-0 flex-col md:w-[50%]"
        indicatorProps={{
          className:
            "bg-transparent shadow-none rounded-none",
        }}
      >
        {data2.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            className={activeTab === value ? "text-yellow-500 text-xl font-bold bg-black rounded-md" : ""}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody
      className='relative right-20 lg:right-32 text-justify'>
        {data2.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs> */}
</div>

    {/* -------------------------------------------------------NGO Side Navbar phone----------------------- */}
 
    <div className='pt-20 px-4 lg:px-20 cursor-pointer block md:hidden'>
      {/* ---------------------Overview------------------- */}
      <div>
            <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Overview</h1>
            <ul>
          <li>
            <h1 className='font-bold'>Perpetual Existence</h1>
            <p>A registered trust in Delhi has perpetual existence. It can continue its operations even if there are changes in the trustees. The trust's activities and objectives can be carried forward across generations. It ensures the continuity of its charitable or religious initiatives.</p>
          </li>
          <br/>
          <li>
            <h1 className='font-bold'>Limited liability</h1>
            <p>One significant benefit of trust registration is limited liability protection. The trustees of a registered trust generally have limited personal liability. In case of legal disputes or debts, the personal assets of the trustees are protected. The trust's assets are used to satisfy the liabilities.</p>
          </li>
          <br/>
          <li>
          <h1 className='font-bold'>Enhanced Credibility</h1>
            <p>Trust registration enhances the credibility of the organisation. Especially beneficial for the eyes of donors, and government authorities. Being a registered entity demonstrates transparency and accountability. It can attract more donations, grants, and collaborations with other organisations.</p>
          </li>
          <br/>
          <li>
          <h1 className='font-bold'>Operational Advantages</h1>
            <p>Registered trusts are subject to certain rules and regulations that provide a framework. This framework promotes accountability, transparency, and governance within the trust. It helps streamline the trust's activities, financial management, and reporting procedures. It also ensures better operational efficiency.</p>
          </li> 
        </ul>
            </div>

             {/* ------------------------------Documents Required----------------------------------- */}
       <div>
        <h1 className='text-lg md:text-2xl pt-5 text-yellow-500 font-semibold'>Documents Required for Trust Registration in Delhi</h1>
        <p>The following documents are required for trust registration in Delhi:</p>
        <ul className='list-disc pl-4'>
          <li>Trust deed</li>
          <li>Proof of identity and address for trustee</li>
          <li>Proof of address of the trust</li>
          <li>Bank account details of the trust</li>
          <li>A copy of the PAN card of the trust</li>
        </ul>
        </div>
      {/* ---------------------------Eligibilty Criteria-------------------------- */}
      <div>
            <h1 className='text-lg md:text-2xl pt-5 text-yellow-500 font-semibold'>Eligibility Criteria for Trust Registration in Delhi</h1>
            <ul className='list-disc pl-4'>
              <li>Seeking a competent and willing individual or entity for property transfer</li>
              <li>Minimum requirement of two individuals or corporate entities</li>
              <li>The trust activities must be lawful</li>
              <li>The trust should function within charitable, religious, educational, or social welfare domains</li>
              <li>Operation should be non-profit, utilising income and property solely for stated objectives</li>
              <li>Execution of a trust deed to define objectives, rules, and regulations</li>
              <li>Necessary to obtain relevant authority, submit documents, and pay fees</li>
              <li>Adherence to rules, regulations, and reporting obligations is expected.</li>
            </ul>
            </div>

      {/* ---------------------------Checklist--------------------- */}
    <div>
            <h1 className='text-lg md:text-2xl pt-5 text-yellow-500 font-semibold'>Checklist for Trust Registration in Delhi</h1>
            <ul className='list-disc pl-4'>
              <li>Identify a competent settlor willing to transfer property</li>
              <li>Appoint a minimum of two trustees, individuals or corporate entities</li>
              <li>Determine lawful objectives falling under charitable, religious, educational, or social welfare activities</li>
              <li>Prepare a trust deed clearly defining objectives, rules, and regulations</li>
              <li>Execute the trust deed with proper signatures and witnesses</li>
              <li>Prepare the application for trust registration with required details</li>
              <li>Gather necessary documents, including identity proofs, address proofs, and property details</li>
              <li>Arrange for the payment of the registration fee</li>
              <li>Submit the application and supporting documents to the Registrar of Trusts</li>
              <li>Ensure compliance with all applicable rules and regulations.</li>
            </ul>
     </div>

     {/* ------------------------------------Process-------------------- */}
     <div>
        <h1 className='text-lg md:text-2xl pt-5 text-yellow-500 font-semibold'>Process for Trust Registration in Delhi</h1>
        <p>The process of trust registration in Delhi is as follows:</p><br/>
        <ul className=''>
          <li>
            <h1 className='font-bold'>Step 1: Consultation and Document Collection</h1>
            <p>Get expert consultation to understand your requirements. Avail help in choosing a unique trust name and the details of the trust you want to register. Submit the required documents for registration.</p>
          </li>
          <br/>
          <li>
          <h1 className='font-bold'>Step 2: Trust Deed Drafting</h1>
            <p>Govyapar's legal experts would draft the trust deed according to your specifications. We ensure it complies with the Indian Trusts Act and relevant state laws. We will tailor the trust deed to reflect your intentions and objectives.</p>
          </li>
          <br/>
          <li>
          <h1 className='font-bold'>Step 3: Submit the Application</h1>
            <p>With the trust deed and documents in hand, our team will file for registration. We make sure that all the information is accurate</p>
          </li> 
          <br/>
          <li>
          <h1 className='font-bold'>Step 4: Follow-Up and Verification</h1>
            <p>Our team will address any queries or requirements arising during the process.</p>
          </li> 
          <br/>
          <li>
          <h1 className='font-bold'>Step 5: Registration and Documentation</h1>
            <p>Post approval you will get a registration certificate from the Sub-Registrar's office. On request, we might also assist you in obtaining a PAN and open a bank account for the trust</p>
          </li> 
        </ul>
        </div>
     </div>

    {/* ----------------------------------------------NGO Structure-wise Comparison---------------------------------------- */}
    <div className='pt-20 md:pt-40 md:px-10 lg:px-32'>
      <h1 className='text-2xl md:text-3xl text-center font-bold py-2'>NGO Structure-wise Comparison</h1>
      <p className='text-md md:text-lg px-2 text-justify'>From drafting your deed, registering the NGO  and opening a bank account - Govyaparprovides comprehensive NGO registration services in India. </p>

      {/* --------------------------------table---------------------- */}
      <table className="company-formation-table max-w-full mx-0 hidden md:block">
  <tr>
      <th></th>
      <th>Liability</th>
      <th>Taxation</th>
      <th>Formation</th>
      <th>Maintenance</th>
    </tr>

    <tr>
      <td>Section 8 Company</td>
      <td>Limited liability of members.</td>
      <td>Section 8 companies enjoy 100% tax exemption as their profits are utilised for charitable purposes, providing a significant tax advantage.</td>
      <td>Has to be registered under Section 8 of the Companies Act of 2013 with a minimum of 2 directors or shareholders.</td>
      <td>Submit annual financial statements and reports to the RoC.</td>
    </tr>

    <tr>
      <td>Trust registration</td>
      <td>Limited liability of members.</td>
      <td>For a registered trust to be fully exempt from taxes, it must furnish the certifications required by Section 12A 80G.</td>
      <td>A trust has to be registered under the Indian Trust Act of 1882 by submitting a trust deed</td>
      <td>Adhere to the rules and regulations outlined in the trust deed and relevant state laws. Maintain accurate records of financial transactions, beneficiaries, and activities</td>
    </tr>

    <tr>
      <td>Society registration</td>
      <td>Limited liability of members</td>
      <td>For tax exemption a society must get a certificate of completion from the income tax officials for each applicable exclusion, such as Section 12 A, 80G, etc.</td>
      <td>It has to be registered under the Societies Registration Act of 1986 with at least 7 members.</td>
      <td>Mandatory audit if turnover exceeds a threshold. Partners not personally liable for the LLP's debts.</td>
    </tr>

    </table>

    <table className="max-w-full mx-0 block md:hidden">
  {/* ---------------------------------Section 8 Company---------------------------- */}
  <tr>
  <td className='font-bold'></td>
  <td>Section 8 Company</td>
  </tr>

  <tr>
  <td className='font-bold'>Liability</td>
  <td>Limited liability of members</td>
  </tr>

  <tr>
  <td className='font-bold'>Taxation</td>
  <td>Section 8 companies enjoy 100% tax exemption as their profits are utilised for charitable purposes, providing a significant tax advantage.</td>
  </tr>

  <tr>
  <td className='font-bold'>Formation</td>
  <td>Has to be registered under Section 8 of the Companies Act of 2013 with a minimum of 2 directors or shareholders.</td>
  </tr>

  <tr>
  <td className='font-bold'>Maintenance</td>
  <td>Submit annual financial statements and reports to the RoC.</td>
  </tr>
  <p className='w-full  border-black border-1'></p>
  <p className='w-full  border-black border-1 mt-1'></p>

{/* ----------------------------------------Trust registration---------------------------------- */}
  <tr>
  <td className='font-bold'></td>
  <td>Trust registration</td>
  </tr>

  <tr>
  <td className='font-bold'>Liability</td>
  <td>	Limited liability of members.</td>
  </tr>

  <tr>
  <td className='font-bold'>Taxation</td>
  <td>For a registered trust to be fully exempt from taxes, it must furnish the certifications required by Section 12A 80G.</td>
  </tr>

  <tr>
  <td className='font-bold'>Formation</td>
  <td>A trust has to be registered under the Indian Trust Act of 1882 by submitting a trust deed</td>
  </tr>

  <tr>
  <td className='font-bold'>Maintenance</td>
  <td>Adhere to the rules and regulations outlined in the trust deed and relevant state laws. Maintain accurate records of financial transactions, beneficiaries, and activities.</td>
  </tr>
  <p className='w-full  border-black border-1'></p>
  <p className='w-full  border-black border-1 mt-1'></p>

  {/* ----------------------------------------Society registration---------------------------------- */}
  <tr>
  <td className='font-bold'></td>
  <td>Society registration</td>
  </tr>

  <tr>
  <td className='font-bold'>Liability</td>
  <td>Limited liability of members</td>
  </tr>

  <tr>
  <td className='font-bold'>Taxation</td>
  <td>For tax exemption a society must get a certificate of completion from the income tax officials for each applicable exclusion, such as Section 12 A, 80G, etc.</td>
  </tr>

  <tr>
  <td className='font-bold'>Formation</td>
  <td>It has to be registered under the Societies Registration Act of 1986 with at least 7 members.</td>
  </tr>

  <tr>
  <td className='font-bold'>Maintenance</td>
  <td>Mandatory audit if turnover exceeds a threshold. Partners not personally liable for the LLP's debts.</td>
  </tr>
  <p className='w-full  border-black border-1'></p>
  <p className='w-full  border-black border-1 mt-1'></p>

  </table>
      </div>

      {/* ------------------------------------------Post Incorporation Compliances---------------------------------------------------- */}

    <div className="xl:px-60 lg:px-40 md:px-32 px-10 py-4 ">
    <img src='ngo company.jpg' className='lg:px-10 xl:px-40' alt='ngo'/>
      <h1 className='text-2xl md:text-3xl text-center font-bold py-2'>Post Incorporation Compliances</h1>
    <p className='text-md md:text-lg text-center'>Ensuring Post Registration Legal Compliances for your NGO by experts! </p>
    <motion.ul 
    initial={{y:100, opacity:0}}
    whileInView={{y:0, opacity:1}}
    transition={{delay:0.2, y:{type:"spring", stiffness:60}, opacity:{duration:0.}, ease:"easeIn", duration:1}}
    className="grid grid-rows-3 md:grid-cols-2 md:grid-rows-2 lg:grid-cols-4 gap-2 pt-5 ">

    <li className="p-3 shadow-lg rounded-md cursor-pointer md:hover:-translate-x-1 border-1 border-black ease-in duration-300 hover:scale-105">
      <h1 className='font-bold text-md text-yellow-500 py-2'>NGO Compliance</h1>
      <p className='text-sm text-black'>Section 12A and 80G registration, FCRA compliance and ITR filing and DARPAN Registration done.</p>
    </li>

    <li className="p-3 shadow-lg rounded-md cursor-pointer md:hover:-translate-x-1 border-1 border-black ease-in duration-300 hover:scale-105">
      <h1 className='font-bold text-md text-yellow-500 py-2'>Trust Compliance</h1>
      <p className='text-sm text-black'>PAN card and ITR filing, Shop and Establishment act registration, Accounting and Bookkeeping support done.</p>
    </li>

    <li className="p-3 shadow-lg rounded-md cursor-pointer md:hover:-translate-x-1 border-1 border-black ease-in duration-300 hover:scale-105">
      <h1 className='font-bold text-md text-yellow-500 py-2'>Section 8 Compliance </h1>
      <p className='text-sm text-black'>Auditor Appointment, Filling Financial returns to ROC , Conduct annual general meeting</p>
    </li>

    <li className="p-3 shadow-lg rounded-md cursor-pointer md:hover:-translate-x-1 border-1 border-black ease-in duration-300 hover:scale-105">
      <h1 className='font-bold text-md text-yellow-500 py-2'>Society Compliance</h1>
      <p className='text-sm text-black'>GST, Professional tax and ITR filing, Filing documents with registrar of firms, PAN card, Accounting and Book keeping  support provided.</p>
    </li>
    </motion.ul>
    </div>

      {/* ------------------------------------------Grant Service---------------------------------------------------- */}

      <div className="xl:px-60 lg:px-40 md:px-32 px-10">
      <h1 className='text-2xl md:text-3xl text-center font-bold py-2'>Grant Service</h1>
    <p className='text-md md:text-lg text-center'>We'll secure your e-ANUDAN certificate for government grant-in-aid schemes. </p>
    <motion.ul 
    initial={{y:100, opacity:0}}
    whileInView={{y:0, opacity:1}}
    transition={{delay:0.2, y:{type:"spring", stiffness:60}, opacity:{duration:0.}, ease:"easeIn", duration:1}}
    className="grid grid-rows-3 md:grid-cols-3 md:grid-rows-1 lg:grid-cols-3 gap-2 pt-5 ">

    <li className="p-3 shadow-lg rounded-md cursor-pointer md:hover:-translate-x-1 border-1 border-black ease-in duration-300 hover:scale-105">
      <h1 className='font-bold text-md text-yellow-500 py-2'>e-ANUDAN</h1>
      <p className='text-sm text-black'>Section 12A and 80G registration, FCRA compliance and ITR filing and DARPAN Registration done.</p>
    </li>

    <li className="p-3 shadow-lg rounded-md cursor-pointer md:hover:-translate-x-1 border-1 border-black ease-in duration-300 hover:scale-105">
      <h1 className='font-bold text-md text-yellow-500 py-2'>DARPAN</h1>
      <p className='text-sm text-black'>Streamline your operations, obtain NGO Darpan Unique ID, and simplify FCRA registration with centralised support.</p>
    </li>

    <li className="p-3 shadow-lg rounded-md cursor-pointer md:hover:-translate-x-1 border-1 border-black ease-in duration-300 hover:scale-105">
      <h1 className='font-bold text-md text-yellow-500 py-2'>CSR form 1</h1>
      <p className='text-sm text-black'>Section 12A and 80G registration, FCRA compliance and ITR filing and DARPAN Registration done.</p>
    </li>
    </motion.ul>
    </div>

    {/* ---------------------------------------------------FAQS------------------------------------------------------- */}
<section className=" text-black py-32 min-h-screen">
  <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
    <h2 className="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">Frequently Asked Questions</h2>
    <div className="flex flex-col divide-y sm:px-8 lg:px-20 xl:px-32 divide-yellow-600">
      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can an NGO work in multiple states or districts after registration?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>Yes, after registration, an NGO can expand its operations to multiple states or districts, with the requirement to inform relevant authorities and comply with specific regulations in each region.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can an individual register more than one NGO?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>Yes, an individual can register more than one NGO, provided they meet legal requirements for each registration and can manage the responsibilities associated with multiple organisations.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How can I change the name of my society?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>To change the name of a society in the process of society registration, the following procedure must be followed:Convene a general meeting to discuss the name changePass a resolution on the name change with the approval of the majority of membersSubmit a notice of the name change signed by the secretary and members of the society to the registrar.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How do I convert my company to a Section 8 Company?</summary>
        <div className="px-4 pb-4">
          <p>Converting an existing company into a Section 8 Company involves the alteration of the company's Memorandum and Articles of Association and obtaining approval from the Registrar of Companies (RoC). You will need to meet the eligibility criteria, prepare the necessary documents, and follow the prescribed procedure as per the Companies Act, 2013.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Is an office required for starting a Section 8 Company?</summary>
        <div className="px-4 pb-4">
          <p>Yes, a registered office address is required for starting a Section 8 Company in India. You must provide a physical location where official communications can be received.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How Can I Register an NGO Online?</summary>
        <div className="px-4 pb-4">
          <p>To register an NGO online in India, follow the guidelines provided by the government and use platforms like NGO Darpan to submit your application and required documents.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How is a trust registered?</summary>
        <div className="px-4 pb-4">
          <p>To register a trust, you typically need to draft a trust deed specifying the trust's objectives, trustees, beneficiaries, and rules. The deed must be signed and executed by the settlor and trustees. It should then be registered with the local Sub-Registrar or the office of the Charity Commissioner, depending on the jurisdiction.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Where can I register my trust in Tamil Nadu?</summary>
        <div className="px-4 pb-4">
          <p>In Tamil Nadu, you can register your trust with the office of the Inspector General of Registration or with the office of the Charity Commissioner, depending on the nature and purpose of the trust.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Who is eligible for society registration?</summary>
        <div className="px-4 pb-4">
          <p>Foreigners, Company, Partnership Firm, and Registered Society are eligible for society registration.</p>
        </div>
      </details>
    </div>
  </div>
</section>
         
    </>
  )
}

export default NGO
