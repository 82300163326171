import React from 'react';
import { motion} from "framer-motion";

function Page3() {
  window.scrollTo(0,0);
  return (
    <>
 {/* ----------------------------------------------Heading------------------------ */}
      
      {/* -----------------------------------------Enhancing Internal Audit Processes Through Application of 5WIH Method----------------------- */}
          <section className='container max-w-6xl mx-auto pt-32'>
          <div className="flex flex-col space-y-2 text-yellow-500 text-center mb-10">
          <h1 className="text-xl font-bold md:text-3xl">
          Enhancing Internal Audit Processes Through Application of 5WIH Method
          </h1>
          <div className="flex items-center space-x-2 text-xs md:text-sm text-gray-600 mx-auto">
            <i className="material-icons">calendar_today</i>
            <span>05 Aug 2024</span>
            <i className="material-icons">visibility</i>
            <span>624 Views</span>
            <i className="material-icons">comment</i>
            <span>0 comment</span>
          </div>
        </div>
          <img src="Why Do.png" className='md:float-left md:w-[60%] xl:w-[50%]' alt=''/>
         
          <p className='text-lg text-justify '>
          The 5W1H method is one of the major tools central to numerous approaches, among which are problem solving, decisions, news reporting, and other general research. For its simplicity and effectiveness, this technique serves as a systematic and complete approach to answer the six basic questions—who, what, when, where, why, and how—involved in research.
          <br/>
           Drawing inspiration from principles conceived in a Rudyard Kipling poem, the 5W1H method furnishes a structured framework in order to gain a comprehensive and detailed insight into a given situation. The methodology is absolutely necessary for detailed analysis and for precision in communication.
          <br/> <br/>
          <h1 className="text-xl text-yellow-500">What is the 5W1H Method?</h1>
          The 5W1H is the method systemizing the process of questioning and solving problems, covering all basic elements of a problem: what, who, when, where, why, and how. This technique was proposed for inquiring about issues from different perspectives in order to give an overall understanding of the situation. It is broadly used in organizations as a continuous process-improvement tool and helps identify the key issues; later, it offers space for deeper analysis.
          <br/>
           This is often referred to as the Kipling method, after Rudyard Kipling, who is said to have used these questions in ensuring he fully answered queries and came up with points that aided in resolving problems. Over time, the 5W1H method has been borrowed into business to cut down on errors, improve efficiency, and make operations optimal.
          <br/>
          </p>
          <br/>
          {/* -----------------Origin---------- */}
          <p className='text-lg text-justify '>
          <h1 className="text-xl text-yellow-500">Origin</h1>
          The 5W1H method is from journalism and investigation reports, where it is used in the gathering of all possible information so that a news story may be prepared. This 'who', 'what', 'when', 'where', 'why', and 'how' method has been applied to various fields, new in corporate learning and project management, in order to ensure thoroughness in analysis and understanding.
          <br/> Considering the objectives of internal audit, which range from process refinement to error reduction and process streamlining, the question is relevant: could the 5W1H method be effectively applied within an internal audit? This will point to a need for further elucidation as to how such methodology will help to enhance the accuracy and efficiency of internal auditing procedures.
          </p>
          <br/>
          <br/>

       {/* --------------------The Components of the 5W1H Method--------------------- */}
          <p className='text-lg text-justify'>
          <h1 className="text-xl text-yellow-500">The Components of the 5W1H Method</h1> 
         <ol className=' list-inside'>
            <li>
                <strong>1. What</strong>
                <p>This refers to the specific situation or challenge addressed. It is the clear understanding of the existing circumstance, the problems being solved, or the overall goals of using a certain technique. This comprises an analysis of what has happened, and what might happen in case the problem is not solved.</p>
            </li>
            <li>
                <strong>2. Why</strong>
                <p>This is consideration of the reasons behind why things are the way they are at the moment, or why a solution is needed. It calls for an in-depth probe into the causes that have led to the situation, and other primary reasons that drive the need for a solution. Most importantly, it answers the questions: why is the situation the way it is? why is it important to find a solution? among other reflective issues that border on the root and motivations towards the need to find a solution.</p>
            </li>
            <li>
                <strong>3. When</strong>
                <p>This would involve the investigation of all timelines relevant to the case at hand: when did it happen, how long had it been happening, when did it stop, how long will it last, and when is the proper timing for action.</p>
            </li>
            <li>
                <strong>4. Who</strong>
                <p>This means the need to identify all persons involved in the scenario, from the individuals who identified the problem to the personnel who would actually carry out the solution, as well as those who will undertake each aspect of the solution itself.</p>
            </li>
            <li>
                <strong>5. Where</strong>
                <p>This is where the situation takes place, whether it be a particular premises, facility, or system. Some of the key areas include the exact location within the system where the issue first arose, as well as all possible locations where it might have any impact.</p>
            </li>
            <li>
                <strong>6. How</strong>
                <p>This is the last part of the technique to which tasks or solutions would best solve the problem. There should be a consideration of tools, means, resources, besides other necessities that the resolution of the problem demands.</p>
            </li>
         </ol>
          <br/>
          <br/>

           {/* --------------------The Components of the 5W1H Method--------------------- */}
          <h1 className="text-xl text-yellow-500">The Components of the 5W1H Method</h1> 
         <ol className='list-decimal list-inside'>
            <li>Be specific: Every question, in its wording and purpose, should be tricky, depending on the context and clearly eliciting information.           
            </li>
            <li>Open-ended questions: The set of questions should be such that they can’t be answered in terms of “yes” or “no” to get detailed responses
            </li>
            <li>Order the questions: Start with the fundamental ones: Who, what, When, Where, and then proceed to why and how. </li>
            <li>Team: Include the relevant stakeholders to ensure that the concerns of other people are taken into account and comprehensively bring out the scope of information that is to be garnered. </li>
            <li> Record the answers: It is for keeping a record of the answer in regard to each question for thorough keeping of record and facilitation of analyses.</li>
         </ol>
          <br/>
          <br/>

           {/* --------------------Features--------------------- */}
           <h1 className="text-xl text-yellow-500">Features</h1> 
         <ol className='list-decimal list-inside'> 
         <li>It ensures comprehensiveness in the subject since all the six questions are covered.</li> 
         <li> Systematic approach: Information can be gathered properly and structured in a way that allows for similarly organized analysis.</li>
         <li>Clarity: The questions are all focused, avoiding any ambiguity and providing clear guidance as to an investigation.</li> 
         <li> Flexibility: The applicability of the method due to the fact that it can be adjusted to suit different contexts and situations.</li>
         </ol>
          <br/>
          <br/>


        {/* -----------------------------------------Conclusion:- ------------------ */}
        <h1 className="text-xl text-yellow-500">Conclusion:-  </h1>
        By systematically employing this method, I conducted a thorough root cause analysis, affirming the existence of a problem with financial implications. This method has proven effective in guiding a clear determination of problems and facilitating exploration of viable solutions in my internal audit processes. <br/>
         While the 5W1H Method may seem overly simple, the best solutions often are. Following this line of inquiry can clarify nearly any process and lead to better problem solving and root-cause analysis. In other words, 5W1H can indeed enhance internal audit processes.
        </p>
        </section>

    {/* ---------------------------Leave a Comment------------------------------------- */}
      <div className="max-w-xl mx-auto bg-white p-6 rounded shadow-lg my-20">
  
    <h2 className="text-xl font-bold mb-4">Leave a Comment</h2>
    
    <div className="bg-gray-500 text-yellow-500 p-3 rounded mb-6">
      Your email address will not be published. Required fields are marked *
    </div>

    {/* ------------------------ Form ---------------------- */}
    <form>
      <div className="flex items-center mb-4">
        <span className="material-icons text-gray-500 mr-2">person</span>
        <input type="text" name="name" placeholder="Name *" className="w-full p-3 border rounded"/>
      </div>
      
      <div className="flex items-center mb-4">
        <span className="material-icons text-gray-500 mr-2">email</span>
        <input type="email" name="email" placeholder="Email *" className="w-full p-3 border rounded"/>
      </div>
      
      <div className="flex items-center mb-4">
        <span className="material-icons text-gray-500 mr-2">link</span>
        <input type="url" name="website" placeholder="Website" className="w-full p-3 border rounded"/>
      </div>

      <div className="mb-4">
        <textarea name="comment" placeholder="Comment" className="w-full p-3 border rounded  h-24"></textarea>
      </div>
      
      {/* <!-- Submit Button --> */}
      <div className="text-right">
        <button type="submit" className="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-600">
          Post Comment
        </button>
      </div>
    </form>
  </div>

    </>
  );
};

export default Page3;
