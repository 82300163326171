import React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';

const ScheduleCall = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [radioBtn, setRadioBtn] = useState("");
  const navigate = useNavigate();
  const data = (e) => {
    setRadioBtn(e.target.value)
  }
  const handleConfirm = (e) => {
    e.preventDefault();
    
    // Validate required fields (Example)
    if (!selectedDate || !selectedTime || !radioBtn) {
      alert("Please fill in all required fields!");
      return;
    }

    // Navigate to /assist route for chat
    navigate("/assist");
  };
  return (
    <>
    <div className="min-h-screen flex items-center justify-center pt-3">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="p-6 w-full"
      >
        <img
          src="scheduleCall.png" // replace with the actual path to the image
          alt="Schedule a Call"
          className="md:w-[50%] pt-10 md:pt-28 rounded-full md:float-left block lg:hidden"
        />
        <img
          src="scheduleCall.png"
          alt="Schedule a Call"
          className="lg:w-[50%] xl:w-[45%] pt-5 rounded-full md:float-left lg:block hidden"
        />

        <div className="mx-auto md:float-right md:pt-28 md:px-10 md:w-[50%] md:h-screen">
          <h2 className="text-xl xl:text-2xl text-justify font-semibold mb-4">
            Book a chat and see what we can do for you
          </h2>

          <label className="block text-black text-sm mb-2 text-justify">
            Choose a date and time to be contacted
          </label>
          <div className="md:flex md:space-x-4 mx-auto text-justify">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              className="w-full px-3 py-2 border rounded-md max-w-lg"
              dateFormat="dd/MM/yy"
              placeholderText="Select a date"
            />
            <br className="block lg:hidden" />
            <br className="block lg:hidden" />
            <div className="mb-4 max-w-lg">
              <select
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                className="w-full px-3 py-2 border rounded-md max-w-lg"
              >
                <option value="">Select best time to be called...</option>
                <option value="9AM-10AM">9AM - 10AM</option>
                <option value="10AM-11AM">10AM - 11AM</option>
                <option value="11AM-12PM">11AM - 12PM</option>
                <option value="12PM-1PM">12PM - 1PM</option>
                <option value="1PM-2PM">1PM - 2PM</option>
                <option value="2PM-3PM">2PM - 3PM</option>
                <option value="3PM-4PM">3PM - 4PM</option>
                <option value="4PM-5PM">4PM - 5PM</option>
              </select>
            </div>
          </div>
          <div className="mx-auto text-justify">
            <label className="lg:pr-18">
              Modes of Booking<span className="text-red-500">*</span>
            </label>
            <br className="block lg:hidden" />
            <br />
            <input
              type="radio"
              name="chat"
              value="Chat"
              checked={radioBtn === "Chat"}
              onChange={(e) => setRadioBtn(e.target.value)}
              className="px-3 py-2 border rounded-md max-w-lg"
            />
            <label>Chat</label> <br />

            <input
              type="radio"
              name="virtual_meet"
              value="Virtual Meet"
              checked={radioBtn === "Virtual Meet"}
              onChange={(e) => setRadioBtn(e.target.value)}
              className="px-3 py-2 border rounded-md max-w-lg"
            />
            <label>Virtual Meet</label> <br />
            <br />
            <br />

            <input
              type="text"
              name="Name"
              placeholder="Enter Full Name"
              className="w-full px-3 py-2 border rounded-md max-w-lg"
              required
            />
            <br />
            <br />

            <input
              type="tel"
              name="Number"
              placeholder="Enter Phone Number"
              maxLength={10}
              className="w-full px-3 py-2 border rounded-md  max-w-lg"
              required
            />
            <br />
            <br />
            <input
              type="email"
              name="email"
              placeholder="Enter Email Address"
              maxLength={50}
              className="w-full px-3 py-2 border rounded-md max-w-lg"
            />
            <br />
            <br />
            <button
              onClick={handleConfirm}
              className="w-full max-w-lg bg-yellow-500 text-black font-bold py-2 rounded-md mb-4"
            >
              Confirm
            </button>
          </div>
        </div>
      </motion.div>
    </div> 

    </>
  );
};

export default ScheduleCall;
