import React from 'react';

function Page1() {
  window.scrollTo(0,0);
  return (
    <>
 {/* ----------------------------------------------Heading------------------------ */}
      
      {/* -----------------------------------------Start, maintain Public Provident Fund account, even in new tax regime----------------------- */}
          <section className='container max-w-6xl mx-auto pt-32'>
          <div className="flex flex-col space-y-2 text-yellow-500 text-center mb-10">
          <h1 className="text-xl font-bold md:text-3xl">
          Start, maintain Public Provident Fund account, even in new tax regime
          </h1>
          <div className="flex items-center space-x-2 text-xs md:text-sm text-gray-600 mx-auto">
            <i className="material-icons">calendar_today</i>
            <span>05 Aug 2024</span>
            <i className="material-icons">visibility</i>
            <span>624 Views</span>
            <i className="material-icons">comment</i>
            <span>0 comment</span>
          </div>
        </div>
          <img src="Why Do.png" className='md:float-left md:w-[60%] xl:w-[50%]' alt=''/>
         
          <p className='text-lg text-justify '>
          The government expects investments in small savings instruments, especially Public Provident Fund (PPF), to plummet sharply in 2024-25 with over 70 per cent of taxpayers gravitating to the new tax regime, where they will not be eligible for the Section 80C tax deduction. Should investors opting for the new tax regime invest in PPF, purely for its merits as an investment product?
          <br/> <br/>
          <h1 className="text-xl text-yellow-500">High, tax-free returns</h1>
          PPF remains attractive even without the Section 80 C deduction. “The tax-free return of 7.1 per cent is very attractive. While the Employees Provident Fund (EPF) offers a higher return of 8.25 per cent which is tax-free for contributions up to Rs 2.5 lakh, it terminates upon retirement. PPF can be extended for life,” says Expert
          <br/>
          PPF becomes flexible after the initial 15-year maturity period ends. It can be extended in blocks of five years. Extensions can happen without or with contribution. “If the account is extended without contribution (the default option), your money compounds tax-free and you can withdraw any amount from the account whenever you like,” says Expert
          <br/>
          An investor who extends the account with contribution can make only one withdrawal in each financial year. “Over the next five years, the investor can withdraw up to 60 per cent of the balance at the end of the financial year in which the account was extended,” says Expert. These features, according to him, make PPF a good pension instrument.
          </p>
          <br/>
          {/* -----------------Low on liquidity---------- */}
          <p className='text-lg text-justify '>
          <h1 className="text-xl text-yellow-500">Low on liquidity</h1>
          The long maturity period acts as a deterrent, especially for younger investors who desire liquidity. “However, partial withdrawals are allowed beginning from the sixth fiscal year following the original subscription period,” says Experts
          <br/>
          One can’t invest beyond Rs 1.5 lakh in each account in a financial year. No monetary limit exists in mutual funds or the National Pension System (NPS). Non-resident Indians (NRIs) and Hindu Undivided Families (HUFs) cannot invest in PPF.
          </p>
          <br/>
          <br/>

       {/* ---------------------Should existing investors continue?--------------------- */}
          <p className='text-lg text-justify '>
          <h1 className="text-xl text-yellow-500">Should existing investors continue?</h1>
          Existing investors should continue with their PPF account even if they lose the Section 80C benefit. “The reason is tax efficiency: PPF interest income and maturity amount are tax-free. If you invest in other fixed-income instruments like fixed deposits or debt mutual funds, you have to pay taxes every year or at the time of withdrawal,” says Experts
          <br/>
          He adds that investors in the 30 per cent tax bracket would have to earn a 30 per cent higher pre-tax return in other fixed-income instruments to match PPF’s return, which is difficult.
          <br/>
          <br/>

        {/* ------------------------------------Should existing investors continue?------------------ */}
          <h1 className="text-xl text-yellow-500">Should existing investors continue?</h1>
          Expert says young investors with the requisite investment horizon and a conservative profile should definitely go for PPF.
          <br/>
          The low liquidity in PPF acts as a deterrent for some younger investors. But the lock-in can be an advantage. “It prevents impulsive withdrawal of funds and aids long-term savings,” says Experts
          <br/>
          Younger investors should first build an emergency corpus and then invest for short-term goals. “Thereafter, if they have money left, they can use PPF to fill up the fixed-income portion of their long-term portfolio,” says Expert. He adds they should start their PPF accounts at the earliest, even without the Section 80C benefit, to get past the 15-year maturity period at the earliest.
          <br/> <br/>

          {/* --------------------------------Loan available against PPF balance-------------- */}
          <h1 className="text-xl text-yellow-500">Loan available against PPF balance</h1>
          <i className="fa-solid fa-forward"></i> Loans can be taken by submitting a request at the bank branch or post office where the account is held
          <br/>
          <i className="fa-solid fa-forward"></i> It can be taken only after one year from the end of the fiscal year in which the initial subscription was made and must be repaid within five years
          <br/>
          <i className="fa-solid fa-forward"></i> The loan amount can be up to 25 per cent of the balance at the end of the second year immediately preceding the loan application
          <br/>
          <i className="fa-solid fa-forward"></i> Only one loan can be taken in a financial year; a second is not provided until the first is repaid
          <br/>
          <i className="fa-solid fa-forward"></i> If repaid in 36 months, the interest rate is 1 per cent per annum; if after 36 months, it is 6 per cent
          <br/> <br/>

        {/* -----------------------------------------Disclaimer:- ------------------ */}
        <h1 className="text-xl text-yellow-500">Disclaimer:-  </h1>
        The opinions presented are exclusively those of the author and CA Guruji Classes. The material in this piece is intended purely for informational purposes and for individual, non-commercial consumption. It does not constitute expert guidance or an endorsement by any organization. The author, the organization, and its associates are not liable for any form of loss or harm resulting from the information in this article, nor for any decisions made based on it. Furthermore, no segment of this article or newsletter should be employed for any intention unless granted in written form, and we maintain the legal right to address any unauthorized utilization of our article or newsletter.
        </p>
        </section>

    {/* ---------------------------Leave a Comment------------------------------------- */}
      <div className="max-w-xl mx-auto bg-white p-6 rounded shadow-lg my-20">
  
    <h2 className="text-xl font-bold mb-4">Leave a Comment</h2>
    
    <div className="bg-gray-500 text-yellow-500 p-3 rounded mb-6">
      Your email address will not be published. Required fields are marked *
    </div>

    {/* ------------------------ Form ---------------------- */}
    <form>
      <div className="flex items-center mb-4">
        <span className="material-icons text-gray-500 mr-2">person</span>
        <input type="text" name="name" placeholder="Name *" className="w-full p-3 border rounded"/>
      </div>
      
      <div className="flex items-center mb-4">
        <span className="material-icons text-gray-500 mr-2">email</span>
        <input type="email" name="email" placeholder="Email *" className="w-full p-3 border rounded"/>
      </div>
      
      <div className="flex items-center mb-4">
        <span className="material-icons text-gray-500 mr-2">link</span>
        <input type="url" name="website" placeholder="Website" className="w-full p-3 border rounded"/>
      </div>

      <div className="mb-4">
        <textarea name="comment" placeholder="Comment" className="w-full p-3 border rounded  h-24"></textarea>
      </div>
      
      {/* <!-- Submit Button --> */}
      <div className="text-right">
        <button type="submit" className="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-600">
          Post Comment
        </button>
      </div>
    </form>
  </div>

    </>
  );
};

export default Page1;
