import React, { useRef, useState } from 'react'
import {motion} from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Consultation(){
   // --------------------------------------------subscribe to our newsletter--------------------------------
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_2shjaqt', 
        'template_yb1ikfz', 
        form.current, {
        publicKey: 'KapdrjZ67SvjEm1Jl',
      })
      .then(
        () => {
          toast.success('Subscribed', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
            });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  // --------------------------------------------contact---------------------------
  const[user, setUser] = useState(
    {
        Name: '', Number:'', Message:''
    }
  )
  // const [errorMsg, setErrorMsg] = useState("");
  let name, value
  const data = (e) =>
  {
    console.log(user)
    name = e.target.name;
    value = e.target.value;
    setUser({...user, [name]:value});
  }
  const getdata = async (e) => 
  {
  if (!user.Name || !user.Number || !user.Message) {
    // setErrorMsg("Fill all fields");
    return;
  }
    // setErrorMsg("");
    
    const{Name, Number, Message} = user;
    e.preventDefault();
    const options = {
        method: 'POST',
        headers: {
            'Content-type' : 'application/json'
        },
        body: JSON.stringify({
            Name, Number, Message
        })
    }
    const res = await fetch(
        'https://govyapar-contact-default-rtdb.firebaseio.com/UserData.json',
        options
        )
        if(res)
        {
          toast.success('Message sent successfully', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
            });
        }
        else
        {
            alert("Error Ocurred")
        }
      }

   var settings = {
      autoplay: true,
      autoplaySpeed: 1000,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
        }
    ]
    };

    window.scrollTo(0,0);
  return(
    <>
   <div className=''>
    <a href='https://wa.me/919808030923'>
    <img src='WhatsappIcon.png' className='fixed w-[28%] md:w-[13%] lg:w-[10%] right-0 top-[520px] md:top-[470px] z-10' alt=''/></a>
     <Link to ="/scheduleCall"><button className='text-white bg-yellow-600 border-2 border-black p-2 rounded-full cursor-pointer z-20 fixed w-[40%] sm:w-[20%] md:w-[24%] lg:w-[20%] xl:w-[16%] right-3 top-[520px] md:top-[470px] overflow-hidden mt-16 sm:mt-24 xl:mt-24 text-xs md:text-lg'><i className="fa fa-clock text-white text-xs md:text-lg"></i> Schedule a Call</button></Link>
    </div>
    
  {/* ----------------------------------Consult an Expert-------------------------------------------- */}
  <div className='py-10 mx-auto max-w-full my-20 lg:px-20'>
  <h1 className='text-2xl md:text-5xl text-center mb-4 font-bold text-black'>Consult an Expert</h1>
  <div className="px-2 sm:px-40 md:px-10 py-4">
      <motion.ul 
      initial={{y:100, opacity:0}}
      whileInView={{y:0, opacity:1}}
      transition={{delay:0.2, y:{type:"spring", stiffness:60}, opacity:{duration:0.}, ease:"easeIn", duration:1}}
      className="grid grid-rows-4 grid-cols-1 md:grid-cols-4 md:grid-rows-1 gap-6">

    <li className="cursor-pointer  text-center">
    <Link to= "/talkLawyer">
      <img src='talk to lawyer.png' alt='Talk to lawyer' className='w-[40%] mx-auto'/>
      <button className='text-md xl:text-xl text-center text-black bg-yellow-600 p-2 px-3 lg:px-5 leading-5 rounded-full mt-2 shadow-md md:hover:-translate-x-1 ease-in duration-300 hover:scale-105'>Lawyer</button></Link>
      <p className=' py-2'><span className='text-gray-900 text-lg font-semibold'>Talk to a lawyer</span> <br/>to discuss your legal concerns and receive expert advice.</p>
    </li>

    <li className="cursor-pointer  text-center">
    <Link to="/talkCA">
      <img src='Talk to a Chartered Accountant.png' alt='Talk to a Chartered Accountant' className='w-[40%] mx-auto'/>
      <button className='text-md xl:text-xl text-center text-black bg-yellow-600 p-2 px-3 lg:px-5 leading-5 rounded-full mt-2 shadow-md md:hover:-translate-x-1 ease-in duration-300 hover:scale-105'>Chartered Accountant</button></Link>
      <p className=' py-2'><span className='text-gray-900 text-md font-semibold'>Talk to a Chartered Accountant</span> <br/>to get professional guidance on your financial matters.</p>
    </li>

    <li className="cursor-pointer  text-center">
    <Link to="/talkCS">
      <img src='Talk to a Company Secretary.png' alt='Talk to a Company Secretary' className='w-[40%] mx-auto'/>
        <button className='text-md xl:text-xl text-center text-black bg-yellow-600 p-2 px-3 lg:px-5 leading-5 rounded-full mt-2 shadow-md md:hover:-translate-x-1 ease-in duration-300 hover:scale-105'>Company Secretary</button></Link>
      <p className=' py-2'><span className='text-gray-900 text-md font-semibold'>Talk to a Company Secretary</span> <br/>for assistance with corporate governance and compliance.</p>
    </li>

    <li className="cursor-pointer  text-center">
    <Link to="/talkTrademark">
      <img src='Talk to a Trademark Lawyer.png' alt='Talk to a Trademark Lawyer' className='w-[40%] mx-auto'/>
       <button className='text-md xl:text-xl text-center text-black bg-yellow-600 p-2 px-3 lg:px-5 leading-5 rounded-full mt-2 shadow-md md:hover:-translate-x-1 ease-in duration-300 hover:scale-105'>Trademark Lawyer</button></Link>
      <p className=' py-2'><span className='text-gray-900 text-md font-semibold'>Talk to a IP/Trademark Lawyer</span> <br/>for specialized advice on intellectual property and trademark issues.</p>
    </li>

    </motion.ul>
    </div>
  </div>
  
    {/* <div className="container pt-32 mb-20 md:h-auto justify-center text-center mx-0 max-w-full scroll-smooth">  
    <div className='max-w-full mx-0'>
    <div className='md:float-left md:px-10 xl:px-20 md:pt-10'>
    <h1 className="text-2xl hidden md:block font-bold  xl:text-5xl md:text-4xl text-left text-black pb-3">When Your <span className="text-yellow-500">Business</span> Goes Down <br/> In Number.</h1>
    <h1 className="text-2xl font-bold  sm:block md:hidden text-left text-black pb-3">When Your <span className="text-yellow-500">Business</span> Goes Down In Number.</h1>
    <h3 className="text-2xl font-bold  hidden md:block xl:text-5xl md:text-4xl text-left text-black">Arrive at <span className="text-yellow-500">GOVYAPAR </span> and do not  <br/> Wonder.</h3>
    <h3 className="text-2xl font-bold  sm:block md:hidden xl:text-5xl md:text-4xl text-left text-black">Arrive at <span className="text-yellow-500">GOVYAPAR </span> and do not  Wonder.</h3>
    <h3 className="font-bold md:text-left sm:text-md md:text-xl sm:text-center pt-10 md:pt-32 text-yellow-500">Trustworthy Financial and Legal Advice</h3>
    <form className="flex w-full md:py-4 pt-2 justify-left">
       <input 
       type="tel" 
       placeholder="Enter Phone Number" 
       maxLength={10} 
       pattern='[0-9]{4}-[0-9]{3}-[0-9]{3}' 
       name="tel" 
       required 
       className="text-white rounded-md bg-black items-center px-4 py-2  mr-2 focus:outline-none focus:ring-1 focus:ring-yellow-500" />
       <button 
       type="submit" 
       className="bg-yellow-500 hover:bg-yellow-400 text-black font-bold py-2 px-4 rounded-md shadow-black-md text-sm hover:scale-105 duration-300 ease-in">Start Filing</button>
     </form>
    </div> */}
    {/* -------------------------------------------------------Carousal Consultation------------------------------------------- */}
    {/* <Slider  {...settings} transition={{ duration: 2 }} className="rounded-xl xl:px-20 max-w-full mx-0  overflow-hidden">
    <Card className="mt-6 md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 md:my-20 cursor-pointer">
      <img
        src="Slider1.jpg"
        alt="image 1"
        className=''
      />
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          ITR Filing
        </Typography>
        <Typography>
        File your Income Tax Returns electronically for a faster and more convenient experience.
        </Typography>
      </CardBody>
    </Card>

    <Card className="mt-6 md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 md:my-20 cursor-pointer">
      <img
        src="Slider2.jpg"
        alt="image 2"
        className=''
      />
       <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
        GST Filing
        </Typography>
        <Typography>
        Streamline your GST filing with our online platform for a hassle-free experience.
        </Typography>
      </CardBody>
    </Card>

    <Card className="mt-6 md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 md:my-20 cursor-pointer">
      <img
        src="Slider3.jpg"
        alt="image 3"
        className=''
      />
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Income Tax Notice
        </Typography>
        <Typography>
        Resolve your Income Tax notices efficiently with expert guidance. We can help!
        </Typography>
      </CardBody>
    </Card>

    <Card className="mt-6 md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 md:my-20 cursor-pointer">
      <img
        src="Slider4.jpg"
        alt="image 4"
        className=''
      />
       <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Compamy Formation
        </Typography>
        <Typography>
        Start your business quickly and easily. Get expert help with company formation.
        </Typography>
      </CardBody>
    </Card>

    <Card className="mt-6 md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 md:my-20 cursor-pointer">
      <img
        src="Slider5.jpg"
        alt="image 5"
        className=''
      />
       <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Consultation
        </Typography>
        <Typography>
        Schedule a consultation with our tax experts for personalized advice on all your tax needs.
        </Typography>
      </CardBody>
    </Card>

    <Card className="mt-6 md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 md:my-20 cursor-pointer">
      <img
        src="Slider6.jpg"
        alt="image 6"
        className=''
      />
       <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Tax Planner
        </Typography>
        <Typography>
        Optimize your tax strategy with our experienced tax planners. Save money & gain peace of mind.
        </Typography>
      </CardBody>
    </Card>
    </Slider> 
   </div>
    </div> */}
  
{/* --------------------------------------------------Why Govyapar---------------------------------- */}
{/* <div className='py-12 px-2 lg:px-20 xl:px-60 max-w-full mx-0'> 
<img src="govyapar logo.png" className='md:w-[30%] md:float-right lg:pr-40 md:pr-20 px-10 md:block hidden' alt=''/>
    <div className='md:px-20 lg:px-40'>
    <h1 className="sm:text-2xl md:text-3xl xl:text-4xl font-semibold md:text-left sm:text-center text-yellow-500 min-w-full">WHY GOVYAPAR?
    <br/></h1>
    <p className="text-black uppercase font-bold sm:text-xl md:text-xl xl:text-2xl md:text-left sm:text-center">Because you are wise and you love your money</p>
    <br className='md:block hidden'/>
    <h1 className="text:md md:text-2xl text-black md:text-left min-w-full">We love to break that conventional pattern that consumes money and time, for it matters: #GoVyapar</h1>
    </div>
    
    </div> */}

{/* -----------------------------------how do we help------------ */}
<div className='container mx-0 max-w-full bg-black md:py-5 md:mt-10'>
<h1 className="sm:text-xl md:text-3xl xl:text-4xl xl:px-60  text-yellow-500 font-bold text-center pt-5">How Do We Help</h1>
<div className="px-20 sm:px-40 md:px-10 lg:px-32 py-4">
<ul className="grid grid-rows-4 md:grid-cols-4 md:grid-rows-1 gap-6">

   
    <li className="bg-transaparent p-3 border-1 shadow-md rounded-md cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105">
    <p className="text-md lg:text-2xl font-bold"> <img src='online-meeting.png' className='px-5 lg:px-24' alt=''/> </p>
      <h1 className='font-bold text-xl text-center text-yellow-500'>Online Meet</h1>
      <p className='text-sm text-justify text-white'>Conduct online meetings with our clients to save their commuting costs and time</p>
    </li>
    

    <li className="bg-transaparent p-3 border-1 shadow-md rounded-md cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105">
    <p className="text-md lg:text-2xl font-bold"> <img src='web-security.png' className='px-5 lg:px-24' alt=''/> </p>
      <h1 className='font-bold text-xl text-center text-yellow-500'>Secured Platform</h1>
      <p className='text-sm text-justify text-white'>The whole platform is secured and safefor the users</p>
    </li>

    <li className="bg-transparent p-3 border-1 shadow-md rounded-md cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105">
    <p className="text-md lg:text-2xl font-bold"> <img src='checklist.png' className='px-5 lg:px-24' alt=''/> </p>
      <h1 className='font-bold text-xl text-center text-yellow-500'>All Ears to Your Queries</h1>
      <p className='text-sm text-justify text-white'>Your feedback and queries contribute to the development of serviecs tailored to ypour needs</p>
    </li>

    <li className="bg-transparent p-3 border-1 shadow-md rounded-md cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105">
    <p className="text-md lg:text-2xl font-bold"> <img src='notepad.png' className='px-5 lg:px-24' alt=''/> </p>
      <h1 className='font-bold text-xl text-center text-yellow-500'>With a Notebook</h1>
      <p className='text-sm text-justify text-white'>You say it. We note it immediately to get rid of any confusion</p>
    </li>
    </ul>
    </div>
    </div>
   {/* ----------------------------Consultation form------------------------ */}
  {/* <div className='max-w-sm mx-auto pt-5'>
    <h1 className='md:text-4xl text-2xl text-center text-bold text-yellow-500 pb-5'>Consultation form</h1>
    <form className="">
  <label for="countries" className="block mb-2 text-sm font-medium text-black">Select Consultant</label>
  <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

    <option>Consultant 1</option>
    <option>Consultant 2</option>
    <option>Consultant 3</option>
    <option>Consultant 4</option>
  </select>
  <br/>
  <label for="countries" className="block mb-2 text-sm font-medium text-black">Select Service</label>
  <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

    <option>ITR Filing</option>
    <option>Gst Filing</option>
    <option>Income Tax Notices</option>
    <option>Tax Planner</option>
  </select>
  <br/>

<label for="countries" className="block mb-2 text-sm font-medium text-black">Appointment Date</label>
<input type="date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date"/>
<br/>

<div className='justify-center'>
<button type="submit" className="bg-yellow-500 hover:bg-yellow-400 text-black font-bold py-2 px-4 rounded-md shadow-black-md text-sm hover:scale-105 duration-300 ease-in">Book an Appointment</button>
</div>
<motion.img
  whileInView={{x:0, opacity:1}}
  animate={{ x: 100 }}
  transition={{ ease: "easeOut", duration: 2 }}
  src="tap_Consultation.png" className='md:float-left w-[20%] relative bottom-3' alt=''/>
</form>
  </div> */}


  {/* ---------------------------------Testimonials---------------------------- */}
   {/* <!-- Container for demo purpose --> */}
   <div className="container my-24 mx-auto md:px-6">
  <section className="mb-32 text-center">
   <h1 className='text-2xl text-yellow-500 md:text-4xl text-center font-bold md:py-20'>Testimonials </h1>

    <div className="grid gap-x-6 md:grid-cols-3 lg:gap-x-12 max-w-5xl mx-auto">
      <div className="mb-12 md:mb-0 shadow-sm border-1 border-gray-200 py-4 rounded-lg">
        <div className="mb-6 flex justify-center">
          <img src="employee.png"
            className="w-32 rounded-full shadow-lg dark:shadow-black/20"  alt=''/>
        </div>
        <h5 className="mb-2 text-lg font-bold">Maria Smantha</h5>
        <h6 className="mb-4 font-bold text-yellow-600 dark:text-primary-400">
          CA
        </h6>
        <p className="mb-4">
        GoVyapar simplified my taxes! Saved time & money.
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="inline-block w-6">
            <path fill="currentColor"
              d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
          </svg>
        </p>
        <ul className="mb-0 flex justify-center">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m480 757 157 95-42-178 138-120-182-16-71-168v387ZM233 976l65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
        </ul>
      </div>

      <div className="mb-12 md:mb-0 shadow-sm border-1 border-gray-200 py-4 rounded-lg">
        <div className="mb-6 flex justify-center">
          <img src="employee.png"
            className="w-32 rounded-full shadow-lg dark:shadow-black/20"  alt=''/>
        </div>
        <h5 className="mb-2 text-lg font-bold">Lisa Cudrow</h5>
        <h6 className="mb-4 font-bold text-yellow-600 dark:text-primary-400">
          Software Developer
        </h6>
        <p className="mb-4">
        GoVyapar helped navigate my complex tax situation. Maximized deductions!
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="inline-block w-6">
            <path fill="currentColor"
              d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
          </svg>
        </p>
        <ul className="mb-0 flex justify-center">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
        </ul>
      </div>

      <div className="mb-0 shadow-sm border-1 border-gray-200 py-4 rounded-lg">
        <div className="mb-6 flex justify-center">
          <img src="employee.png"
            className="w-32 rounded-full shadow-lg dark:shadow-black/20"  alt=''/>
        </div>
        <h5 className="mb-2 text-lg font-bold">John Smith</h5>
        <h6 className="mb-4 font-bold text-yellow-600 dark:text-primary-400">
          CA
        </h6>
        <p className="mb-4">
          GoVyapar keeps me informed & saves me money. Trusted partner!
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="inline-block w-6">
            <path fill="currentColor"
              d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
          </svg>
        </p>
        <ul className="mb-0 flex justify-center">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
              <path fill="currentColor"
                d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
            </svg>
          </li>
        </ul>
      </div>
    </div>
  </section>
</div>

     
    </>
  )
}
export default Consultation