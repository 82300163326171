import React, { useRef,  useState } from 'react'
import { Link } from 'react-router-dom'
import {motion} from "framer-motion";
import emailjs from '@emailjs/browser';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Career = () => {
 // --------------------------------------------subscribe to our newsletter--------------------------------
 const form = useRef();

 const sendEmail = (e) => {
   e.preventDefault();

   emailjs
     .sendForm(
       'service_2shjaqt', 
       'template_yb1ikfz', 
       form.current, {
       publicKey: 'KapdrjZ67SvjEm1Jl',
     })
     .then(
       () => {
        toast.success('Subscribed', {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
          });
       },
       (error) => {
         console.log('FAILED...', error.text);
       },
     );
 };

 // --------------------------------------------contact---------------------------
 const[user, setUser] = useState(
  {
      Name: '', Number:'', Message:''
  }
)
// const [errorMsg, setErrorMsg] = useState("");
let name, value
const data = (e) =>
{
  console.log(user)
  name = e.target.name;
  value = e.target.value;
  setUser({...user, [name]:value});
}
const getdata = async (e) => 
{
if (!user.Name || !user.Number || !user.Message) {
  // setErrorMsg("Fill all fields");
  return;
}
  // setErrorMsg("");
  
  const{Name, Number, Message} = user;
  e.preventDefault();
  const options = {
      method: 'POST',
      headers: {
          'Content-type' : 'application/json'
      },
      body: JSON.stringify({
          Name, Number, Message
      })
  }
   const res = await fetch(
       'https://govyapar-contact-default-rtdb.firebaseio.com/UserData.json',
       options
       )
       if(res)
       {
        toast.success('Message sent successfully', {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
          });
       }
       else
       {
           alert("Error Ocurred")
       }
     }


  return (
    <div className='mx-0 max-w-full'>
{/* ----------------------------------------------Career-------------------------------- */}
<div className=''>
    <a href='https://wa.me/919808030923'>
    <img src='WhatsappIcon.png' className='fixed w-[28%] md:w-[13%] lg:w-[10%] right-0 top-[520px] md:top-[470px] z-10' alt=''/></a>
     <Link to ="/scheduleCall"><button className='text-white bg-yellow-600 border-2 border-black p-2 rounded-full cursor-pointer z-20 fixed w-[40%] sm:w-[20%] md:w-[24%] lg:w-[20%] xl:w-[16%] right-3 top-[520px] md:top-[470px] overflow-hidden mt-16 sm:mt-24 xl:mt-24 text-xs md:text-lg'><i className="fa fa-clock text-white text-xs md:text-lg"></i> Schedule a Call</button></Link>
    </div>
    <div className='container mt-36 items-center max-w-full md:px-10 xl:px-40 h-screen'>
    <img src='career.jpg' className='md:float-right md:w-[50%] lg:w-[40%] hidden md:block' alt=''/>
      <h1 className='text-2xl text-black md:text-5xl xl:text-6xl text-left font-bold md:pt-10'>Could not find your desired role?</h1>
      <p className='md:text-2xl text-md pt-4 text-left text-black font-semibold'>Let us find one for you. Drop your resume here</p>
      <button type="submit" className="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-3 px-4 mt-4 rounded-md shadow-sm md:hidden block">Drop CV</button>
      <p className='md:text-4xl text-md md:pt-10 text-left  text-black font-semibold'> Come, <span className='text-yellow-500 font-bold'>Join Us</span></p>
      <img src='career.jpg' className='float-right px-12 block md:hidden' alt=''/>
     <button type="submit" className="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-3 px-4 mt-4 rounded-md shadow-sm hidden md:block">Drop CV</button>
    
    
    </div>

    {/* ---------------------------------Govyapar matter------------------------------------- */}
     <div className='container mt-36 items-center w-full xl:px-40'>
     <h1 className='text-xl text-black md:text-5xl xl:text-6xl text-center font-bold md:pt-10'>Why you'll love working at GoVyapar</h1>
    
     <div className='xl:px-24 md:px-10'>
     <p className='md:text-2xl text-md pt-4 text-left text-yellow-500 font-semibold'>Our Core Values</p>
     <img src='career2.jpg' className='md:float-right xl:px-20 md:w-[60%] xl:w-[58%] hidden md:block' alt=''/>
     <table className='career-table'>
     <tr>
        <td>1</td>
        <td>Treat every failure as a learning oppportunity</td>
     </tr>
    <tr>
         <td>2</td>
         <td>Fertilize innovation with customer listening</td>
     </tr>
     <tr>
         <td>3</td>
         <td>Bring simplicity to all that is complex</td>
     </tr>
     <tr>
         <td>4</td>
         <td>Be bold</td>
     </tr>
     <tr>
         <td>5</td>
         <td>Build honest and transparent relationship</td>
     </tr>
     <tr>
         <td>6</td>
         <td>Be passionate </td>
     </tr>
    </table>
    </div>
     </div>

{/* -----------------------------------------Current Openings--------------------------------------- */}
     {/* <div className='container mt-40 items-center w-full md:px-20 lg:px-40'>
     <h1 className='text-xl text-black md:text-3xl text-center font-bold md:pt-10'>Current Openings</h1>
     </div> */}

{/* -----------------------------------Benefits of working with us--------------------------- */}
<div className='container mt-36 items-center w-full md:px-32 xl:px-40'>
     <h1 className='text-xl text-black md:text-4xl text-center font-bold md:pt-10'>Benefits of working with us :)</h1>
     <ul className="grid xl:px-80 md:grid-cols-1 md:grid-rows-4 md:pt-12 text-center">
<li className='space-y-2'> 
  <img src='finance.png' className='md:px-48 px-32' alt=''/>
  <h1 className='font-bold md:text-3xl text-yellow-500'>Professional development</h1>
</li>
<li className='space-y-2'>
  <img src='finance (1).png' className='md:px-52 px-32' alt=''/>
  <h1 className='font-bold md:text-3xl text-yellow-500'>Financial benefits</h1>
</li>
<li className='space-y-2'>
  <img src='finance (2).png' className='md:px-52 px-32' alt=''/>
  <h1 className='font-bold md:text-3xl text-yellow-500'>Retirement benefits</h1>
</li>
<li className='space-y-2'>
  <img src='finance (3).png' className='md:px-52 px-32' alt=''/>
  <h1 className='font-bold md:text-3xl text-yellow-500'>Cost Savings</h1>
</li>
</ul>
     </div>

       
    </div>
  )
}

export default Career
