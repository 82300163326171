import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TawkWidget = () => {
  const location = useLocation();

  useEffect(() => {
    const addTawkScript = () => {
      if (!document.getElementById("tawk-script")) {
        const script = document.createElement("script");
        script.id = "tawk-script";
        script.src = "https://embed.tawk.to/675d4335af5bfec1dbdba75e/1if24t2ec";
        script.async = true;
        script.charset = "UTF-8";
        script.setAttribute("crossorigin", "*");
        document.body.appendChild(script);

        script.onload = () => {
          if (window.Tawk_API) {
            window.Tawk_API.maximize();
          }
        };
      }
    };

    const removeTawkScript = () => {
      const tawkScript = document.getElementById("tawk-script");
      if (tawkScript) {
        document.body.removeChild(tawkScript);
      }
    };

    // Add Tawk script only on the `/assist` page
    if (location.pathname === "/assist") {
      addTawkScript();
    } else {
      removeTawkScript();
    }

    // Cleanup on component unmount or route change
    return () => {
      removeTawkScript();
    };
  }, [location]);

  return null; // No visible component rendered
};

export default TawkWidget;
