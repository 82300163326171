import React, { useRef, useState } from 'react'
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { Link } from 'react-router-dom';
import { motion} from "framer-motion";
import emailjs from '@emailjs/browser';
import { TypeAnimation } from 'react-type-animation';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { SERVER_ENDPOINT } from './constants/api_endpoint';
function Home(){
  // -----------------------------NumberNotify-------------------
  const [phoneNumber, setPhoneNumber] = useState('');
  // const NumberNotify = async (e) => {
  //   e.preventDefault();
  
  //   // Validate phone number
  //   if (!/^\d{10}$/.test(phoneNumber)) {
  //     toast.error("Please enter a valid 10-digit phone number!");
  //     return;
  //   }
  
  //   try {
  //     // API call to save the phone number
  //     const response = await axios.post(
  //       `${SERVER_ENDPOINT}/api/phone/savephone`,
  //       { phone: phoneNumber },
  //       { withCredentials: true }
  //     );
  
  //     // Handle API response
  //     if (response.status === 201) {
  //       toast.success("Phone number saved successfully!");
  //       setPhoneNumber(""); // Clear the input field
  
  //       // Redirect to WhatsApp after successful API response
  //       const whatsappURL = `https://wa.me/${phoneNumber}?text=Hi,%20I%20would%20like%20to%20start%20the%20process%20of%20filing.`;
  //       window.open(whatsappURL, "_blank");
  //     } else {
  //       toast.error(response.data.error || "Failed to save phone number.");
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.data) {
  //       // Backend returned an error response
  //       toast.error(error.response.data.error || "An error occurred.");
  //     } else {
  //       // Other errors (network, etc.)
  //       toast.error("An error occurred. Please try again later.");
  //     }
  //   }
  // };
  const NumberNotify = (e) => {
    e.preventDefault();
  
    // Validate phone number (ensuring the field is not empty or invalid)
    if (!/^\d{10}$/.test(phoneNumber)) {
      toast.error("Please enter a valid 10-digit phone number!");
      return;
    }
  
    // Show success toast
    toast.success("Redirecting to WhatsApp chat...");
  
    // Fixed WhatsApp number (in international format without '+')
    const fixedWhatsappNumber = "917042860621";
  
    // Construct the WhatsApp URL with an encoded message
    const message = `Hi, I would like to start the process of filing. Here is my phone number: ${phoneNumber}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${fixedWhatsappNumber}?text=${encodedMessage}`;
  
    // Redirect to WhatsApp
    window.open(whatsappURL, "_blank");
  
    // Clear the input field
    setPhoneNumber("");
  };
  
  

  

  const[counterState, setCounterState ] = useState(false)

   const form = useRef();

   const sendEmail = (e) => {
     e.preventDefault();
 
     emailjs
       .sendForm(
         'service_2shjaqt', 
         'template_yb1ikfz', 
         form.current, {
         publicKey: 'KapdrjZ67SvjEm1Jl',
       })
       .then(
         () => {
          toast.success('Subscribed', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
            });
         },
         (error) => {
           console.log('FAILED...', error.text);
         },
       );
   };

  const[user, setUser] = useState(
    {
        Name: '', Number:'', Message:''
    }
)
let name, value
const data = (e) =>
{
    console.log(user)
    name = e.target.name;
    value = e.target.value;
    setUser({...user, [name]:value});
}
const getdata = async (e) => 
{
  if (!user.Name || !user.Number || !user.Message) {
    // setErrorMsg("Fill all fields");
    return;
  }
    // setErrorMsg("");
    
    const{Name, Number, Message} = user;
    e.preventDefault();
    const options = {
        method: 'POST',
        headers: {
            'Content-type' : 'application/json'
        },
        body: JSON.stringify({
            Name, Number, Message
        })
    }
    const res = await fetch(
        'https://govyapar-contact-default-rtdb.firebaseio.com/UserData.json',
        options
        )
        if(res)
        {
          toast.success('Message sent successfully', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
            });
        }
        else
        {
            alert("Error Ocurred")
        }
}

const handleChange = (e) => {
  setPhoneNumber(e.target.value);
};
  
  return(
  <>
  <div className=''>
    <a href='https://wa.me/919808030923'>
    <img src='WhatsappIcon.png' className='fixed w-[28%] md:w-[13%] lg:w-[10%] right-0 top-[520px] md:top-[470px] z-10' alt=''/></a>
    <Link to ="/scheduleCall"> <Link to ="/scheduleCall"><button className='text-white bg-yellow-600 border-2 border-black p-2 rounded-full cursor-pointer z-20 fixed w-[40%] sm:w-[20%] md:w-[24%] lg:w-[20%] xl:w-[16%] right-3 top-[520px] md:top-[470px] overflow-hidden mt-16 sm:mt-24 xl:mt-24 text-xs md:text-lg'><i className="fa fa-clock text-white text-xs md:text-lg"></i> Schedule a Call</button></Link></Link>
    </div>
  {/* --------------------------------------------Main Heading--------------------------  */}
  <motion.div
  initial={{x:100, opacity:0}}
  animate={{x:0, opacity:1}}
  transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:1}, ease:"easeIn", duration:1}}
  className="container pt-40 mb-20 h-screen md:h-auto justify-center text-center mx-0  max-w-full scroll-smooth"> 
  <h1 className="text-3xl sm:text-4xl md:text-5xl xl:text-6xl font-bold md:text-center text-center text-black pb-4 min-w-full">STRATEGIC <br className='block md:hidden'/>
  <TypeAnimation className=''
  sequence={[
    " TAX",
    1000, 
    " MANAGEMENT",
    1000,
    " STARTUP",
    1000,
    " GST",
    1000,
  ]}
  wrapper="span"
  speed={50}
  style={{ color: '#eab308', display: 'inline-block' }}
  repeat={Infinity}
/> 
  <br className='block md:hidden'/>
  CONSULTING</h1>
  <h3 className="text-xl animate-bounce font-bold text-center md:text-center sm:text-4xl text-yellow-500">Trustworthy Tax Advice</h3>

  <form
      className="flex w-full md:py-4 pt-2 justify-center"
      onSubmit={NumberNotify}
    >
      <input
        type="text"
        placeholder="Enter Phone Number"
        maxLength={10}
        name="Number"
        onChange={handleChange}
        value={phoneNumber}
        required
        className="text-white rounded-md bg-black items-center px-4 py-2 md:w-[30%] xl:w-[20%] w-full mr-2 focus:outline-none focus:ring-1 focus:ring-yellow-500"
      />
      <button
        type="submit"
        className="bg-yellow-600 hover:bg-yellow-500 text-black font-bold py-2 px-4 rounded-md shadow-black-md text-sm hover:scale-105 duration-300 ease-in"
      >
        Start Filing
      </button>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Flip}
      />
    </form>

<motion.div 
initial={{x:-100, opacity:0}}
whileInView={{x:0, opacity:1}}
transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:1}, ease:"easeIn", duration:1}}
className='container max-w-full justify-center xl:px-40 2xl:px-60'>
<div className="grid grid-cols-1 md:grid-cols-3 md:grid-rows-1 mx-full md:space-x-5 xl:px-0 sm:px-32 md:px-0 lg:px-16">
  <Link to="/itrFiling">
    <img src="photo3.png" className="opacity-100 bg-black w-[30%] px-2 py-1 mx-auto rounded-lg relative top-10 z-10" alt="" />
    <div className="group flex flex-col relative cursor-pointer items-center justify-center overflow-hidden transition-shadow shadow-xl hover:shadow-xl hover:shadow-black/30 hover:rounded-xl border-2 hover:border-none border-black hover:bg-black rounded-2xl">
      <div className="absolute inset-0 group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
      <div className="cursor-pointer p-4 ease-in hover:shadow-xl duration-300 hover:scale-105 rounded-xl md:py-10">
        <h5 className="block mb-2 pt-10 font-sans sm:text-2xl md:text-3xl lg:text-4xl opacity-100 transition-opacity duration-300 group-hover:opacity-0 font-bold text-black">ITR FILING</h5>
        <p className="text-black py-2 opacity-100 transition-opacity duration-300 group-hover:opacity-0">File your taxes yourself with ease, hire our tax experts if you need</p>
        <div className="absolute inset-0 flex flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-[-1]">
          <p className="text-md text-justify text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">Doesn't matter if you're a salaried professional or have capital gains, It's super easy for all. Start with minimal details to file ITR in no time</p>
        </div>
        <div className="flex items-end justify-center h-full">
          <button type="submit" className="bg-yellow-500 border-2 border-black text-black font-extrabold py-2 px-4 rounded-md text-sm hover:scale-105 duration-300 ease-in">Explore More</button>
        </div>
      </div>
    </div>
  </Link>

  <Link to="/virtualCfo">
    <img src="photo2.png" className="opacity-100 bg-black w-[30%] px-2 py-1 mx-auto rounded-lg relative top-10 z-10" alt="" />
    <div className="group flex flex-col relative cursor-pointer items-center justify-center overflow-hidden transition-shadow shadow-xl hover:shadow-xl hover:shadow-black/30 hover:rounded-xl border-2 hover:border-none border-black hover:bg-black rounded-2xl">
      <div className="absolute inset-0 group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
      <div className="cursor-pointer p-4 ease-in hover:shadow-xl duration-300 hover:scale-105 rounded-xl md:py-10">
        <h5 className="block mb-2 pt-10 font-sans sm:text-2xl md:text-3xl lg:text-4xl opacity-100 transition-opacity duration-300 group-hover:opacity-0 font-bold text-black">VIRTUAL CFO</h5>
        <p className="text-black py-2 opacity-100 transition-opacity duration-300 group-hover:opacity-0">Expert Virtual CFO Services to Drive Your Business Growth</p>
        <div className="absolute inset-0 flex flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-[-1]">
          <p className="text-md text-justify text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">Transform your financial strategy with our expert Virtual CFO services, tailored to drive your business growth and success.</p>
        </div>
        <div className="flex items-end justify-center h-full">
          <button type="submit" className="bg-yellow-500 border-2 border-black text-black font-extrabold py-2 px-4 rounded-md text-sm hover:scale-105 duration-300 ease-in">Explore More</button>
        </div>
      </div>
    </div>
  </Link>

  <Link to="/consultation">
    <img src="photo1.png" className="opacity-100 bg-black w-[30%] px-2 py-1 mx-auto rounded-lg relative top-10 z-10" alt="" />
    <div className="group flex flex-col relative cursor-pointer items-center justify-center overflow-hidden transition-shadow shadow-xl hover:shadow-xl hover:shadow-black/30 hover:rounded-xl border-2 hover:border-none border-black hover:bg-black rounded-2xl">
      <div className="absolute inset-0 group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
      <div className="cursor-pointer p-4 ease-in hover:shadow-xl duration-300 hover:scale-105 rounded-xl md:py-10">
        <h5 className="block mb-2 pt-10 font-sans sm:text-2xl md:text-3xl lg:text-4xl opacity-100 transition-opacity duration-300 group-hover:opacity-0 font-bold text-black">CONSULTATION</h5>
        <p className="text-black py-2 opacity-100 transition-opacity duration-300 group-hover:opacity-0">1:1 Consultation. Exclusive Guidance, Direct to You.</p>
        <div className="absolute inset-0 flex flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-[-1]">
          <p className="text-md text-justify text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">Experience personalized tax consultation at Govyapar. Simplify compliance and maximize savings with our expert guidance tailored to your needs.</p>
        </div>
        <div className="flex items-end justify-center h-full">
          <button type="submit" className="bg-yellow-500 border-2 border-black text-black font-extrabold py-2 px-4 rounded-md text-sm hover:scale-105 duration-300 ease-in">Explore More</button>
        </div>
      </div>
    </div>
  </Link>
</div>

</motion.div>
</motion.div>

{/* ------------------------------------------------------------------WELCOME TO GOVYAPAR---------------------------- */}
{/* <Fade bottom> */}
<motion.section 
initial={{x:-100, opacity:0}}
whileInView={{x:0, opacity:1}}
transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
className='container max-w-full mx-0 mt-[600px] md:mt-40 xl:pl-60 pt-20 xl:px-32 md:px-16 scroll-smooth'>
<img src="./consultant.png" className='md:float-right md:pt-20 md:w-[50%] lg:w-[40%]' alt=''/>
    <h3 className='text-lg md:text-2xl font-semibold text-yellow-500'>WELCOME TO GOVYAPAR</h3>
    <h1 className='text-2xl md:text-3xl lg:text-4xl font-bold text-black leading-tight'>Startup Consultant: Key to Financial Success</h1>
    <p className='text-lg text-justify'>
    A Startup Consultant in the first instance provides services to the taxpayers whether individual or organization of tax planning, filing returns, suggesting investment plans, etc. We take into account your risk tolerance, time horizon, and financial resources, providing you with a roadmap that aligns with your goals.
    </p>
    <p>
      <motion.ul
       initial={{x:-100, opacity:0}}
whileInView={{x:0, opacity:1}}
transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
 className="text-lg font-semibold text-black pt-3 overflow-hidden">
        <li className='style-none'><i className="fas fa-check-circle text-yellow-500"></i> Planning for the Future</li>
        <li className='style-none'><i className="fas fa-check-circle text-yellow-500"></i> Time Savings</li>
        <li className='style-none'><i className="fas fa-check-circle text-yellow-500"></i> Maximize Savings</li>
        <li className='style-none'><i className="fas fa-check-circle text-yellow-500"></i> Accuracy</li>
        <li className='style-none'><i className="fas fa-check-circle text-yellow-500"></i> Tax Compliances</li>
        <li className='style-none'><i className="fas fa-check-circle text-yellow-500"></i> Peace of Mind</li>
      </motion.ul>
    </p>
</motion.section>
{/* </Fade> */}
{/* -------------------------------------------------About-us---------------------------- */}
<motion.section
initial={{x:-100, opacity:0}}
whileInView={{x:0, opacity:1}}
transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:1}, ease:"easeIn", duration:1}}
className='container max-w-full mx-0 pt-10 md:pt-40 md:px-16 xl:pr-60 xl:px-40 scroll-smooth overflow-hidden'>
<img src="Why Do.png" className='md:float-left md:w-[60%] xl:w-[50%]' alt=''/>
    <h3 className='text-lg md:text-3xl font-semibold text-yellow-500'>ABOUT US</h3>
    <h1 className='text-xl md:text-3xl lg:text-4xl font-bold text-black leading-tight'>Expert Financial Guidance Tailored To Your Success</h1>
    <p className='text-lg text-justify '>
    Our team of highly qualified Chartered Accountants (CAs) and financial experts. We are dedicated to providing comprehensive solutions for all your financial needs, ensuring your success in every step of your financial journey.
    </p>
    <br/>
    <p className='text-xl text-justify'>
    Our experienced professionals specialize in financial planning, tax optimization, and investment strategies. We understand that each individual and business has unique financial goals and challenges. That's why we offer personalized services, tailoring our expertise to meet your specific needs and aspirations.
    </p>
</motion.section>


<motion.section
initial={{x:-100, opacity:0}}
whileInView={{x:0, opacity:1}}
transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:1}, ease:"easeIn", duration:1}}
className='container max-w-full mx-0 py-10 md:mt-40 md:mb-30 pb-20 scroll-smooth overflow-hidden'>
<ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
<div className='flex justify-center text-center space-x-10 gap-10 font-semibold'>
  <div>
  <p className='text-yellow-500 md:text-2xl text-sm text-bold'>EXPERIENCE</p>
  <h1 className='md:text-5xl text-xl font-bold'>
  {counterState && <CountUp start={0} end={8} duration={2.75}></CountUp>}+</h1>
  </div>
  
  <div>
  <p className='text-yellow-500 md:text-2xl text-sm text-bold'>CLIENTS</p>
  <h1 className='md:text-5xl text-xl font-bold'>
  {counterState && <CountUp start={0} end={2500} duration={2.75}></CountUp>}+</h1>
  </div>
  </div>
  <div className="sm:py-4 md:py-10 lg:px-20 xl:px-60 px-8">
  <img src='experience.jpg' className='items-center md:float-left sm:px-20 md:px-0 md:w-[30%]' alt=''/>
  <h1 className='md:text-4xl xl:text-5xl text-xl text-yellow-500 font-bold sm:pt-2 md:pt-20'>Reduce your tax liability up to 26%</h1>
  <ul className="text-xl sm:text-md font-semibold text-black md:space-x-5 py-3 md:pt-10 md:flex">
      <li className='style-none'><i className="fas fa-check-circle"></i> On-demand tax support</li>
      <li className='style-none'><i className="fas fa-check-circle"></i> Your personal tax expert</li>
      <li className='style-none'><i className="fas fa-check-circle"></i> Guaranteed accuracy</li>
  </ul>

  </div>

</ScrollTrigger>
</motion.section>
   
{/* --------------------------------------COMPANY'S VISION----------------------------------------- */}
<motion.section
initial={{x:-100, opacity:0}}
whileInView={{x:0, opacity:1}}
transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
 className='container max-w-full mx-0 lg:px-24 xl:px-40 pt-10 md:my-20 scroll-smooth md:flex overflow-hidden'>
    <div className=''>
    <h3 className='text-xl md:text-4xl font-semibold text-yellow-500'>COMPANY'S VISION</h3>
    <h1 className='text-2xl md:text-3xl font-bold text-black leading-tight'>Your Tax Success Is Our Top Priority</h1>
    <p className='text-xl text-justify md:pr-20 xl:md:pr-32 md:pt-4'>
    At our firm, our vision is to empower individuals and businesses to achieve financial prosperity and secure their long-term success. We strive to be the trusted advisors that our clients rely on for expert guidance, innovative solutions, and personalized service.
    </p>
    </div>

    <div className='md:float-right md:pt-10 md:flex md:space-x-3 py-3'>
    <div className='cursor-pointer sm:mb-2 md:mb-32 bg-gradient-to-r from-yellow-400 to-yellow-700 shadow-md rounded-sm md:hover:-translate-y-4 bg-black ease-in duration-300 hover:scale-105'>
    <h1 className='text-xl font-semibold text-black text-center leading-tight pt-4 px-2 md:px-3'> Empower Our Clients With The Knowledge</h1>
    <p className='text-sm text-justify py-2 px-3 md:px-5'>
    We empower clients with knowledge for confident financial decisions, unlocking their potential for success and independence.
    </p>
    </div>
    <div className='cursor-pointer md:float-right bg-gradient-to-r from-yellow-400 to-yellow-700 shadow-md md:mt-32 rounded-sm md:hover:-translate-y-4 bg-black ease-in duration-300 hover:scale-105'>
    <h1 className='text-xl font-semibold text-black text-center leading-tight pt-4 px-2 md:px-3'> Tax Advisor Firm For Businesses Of All Sizes</h1>
    <p className='text-sm text-justify py-2 px-3 md:px-5'>
    Trusted tax advisors for businesses of all sizes, providing  expert guidance and solutions for optimal tax management and compliance.
    </p>
    </div>
    </div>
</motion.section>
{/* </Fade> */}

       
  </>
  )
}
export default Home