import React from 'react'
import {motion} from "framer-motion";
import { Link } from 'react-router-dom';
 const Footer = () => {
  return (
    <div className="container bg-black mt-20 pt-10 max-w-full scroll-smooth">
        <div className="md:flex justify-between items-center lg:px-28 py-10 ">
            <motion.div
             initial={{x:-100, opacity:0}}
             whileInView={{x:0, opacity:1}}
             transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
             >
              <h1 className="md:text-4xl text-2xl text-yellow-500 font-bold  sm:text-center md:text-left leading-tight">Subscribe to us!</h1>
              <p className="text-md text-white my-2">Subscribe to our newsletter for exclusive updates and offers.</p>
            </motion.div>
        
            <form >
              <motion.div  initial={{x:-100, opacity:0}}
              whileInView={{x:0, opacity:1}}
              transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
              className="flex">
                <input 
                type="email" 
                placeholder="Enter your email" 
                name="user_email" 
                required 
                className="rounded-md px-4 py-2 w-full mr-2 focus:outline-none focus:ring-1 focus:ring-yellow-500" />
                <input 
                type="submit" 
                value="Subscribe" 
                className="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded-md shadow-sm"/>
        
              </motion.div>
            </form>
          </div>

    {/* ----------------------------------------------------------Contact------------------------------------------------------- */}
<div className='mt-10 text-center lg:px-[200px]'>
<h1 className="text-xl text-yellow-500">GET IN TOUCH</h1>
<motion.ul
 initial={{x:-100, opacity:0}}
 whileInView={{x:0, opacity:1}}
 transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
  className="list-none justify-center md:flex md:space-x-5">
     
        <li className='font-semibold text-white text-lg'><i className="fa fa-phone text-yellow-500"> </i> +91-9808030923</li> 
        <a href= "mailto: support@govyapar.com"><li className='font-semibold text-white text-lg'><i className="fa fa-envelope text-yellow-500"> </i> support@govyapar.com</li> </a>     

<ul className='flex space-x-2 justify-center text-lg'>
        <a href='https://www.linkedin.com/company/govyapar/'><li><i className="fa fa-linkedin text-yellow-500 hover:font-bold"></i></li></a>
        {/* <li><i className="fa fa-twitter text-yellow-500 hover:font-bold"></i></li> */}
        <a href='https://www.instagram.com/govyapar/'><li><i className="fa fa-instagram text-yellow-500 hover:font-bold"></i></li></a>
        <a href = "https://www.facebook.com/govyapar"><li><i className="fa fa-facebook-f text-yellow-500 hover:font-bold"></i></li></a>
        <a href='https://www.youtube.com/@govyapar'><li><i className="fa fa-youtube text-yellow-500 hover:font-bold"></i></li></a>
</ul>
</motion.ul>
<div className='flex xl:px-36 px- pt-3 space-x-10 text-white cursor-pointer justify-center '>
    <div className='mx-auto '>
             <ul className='grid grid-cols-2 grid-rows-2 sm:grid-cols-4 sm:grid-rows-1 font-thin text-white mx-auto px-3 text-justify'>
            <div className='text-sm md:text-lg'>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>MENU</li>
            <Link to="/home"><li className=' hover:text-yellow-500'>Home</li></Link>
             <Link to="/about"> <li className=' hover:text-yellow-500'>About Us</li></Link>
            <Link to="/career"><li className=' hover:text-yellow-500'>Careers</li></Link>
            <Link to="/pricing"><li className=' hover:text-yellow-500'>Pricing</li></Link>
            <Link to="/articles"><li className=' hover:text-yellow-500'>Articles</li></Link>
            </div>

            <div className='text-sm md:text-lg'>
            <Link to="/affiliate"><li className=' hover:text-yellow-500'>Affiliate Program</li></Link>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>SERVICES</li>
            <Link to="/itrFiling"><li className=' hover:text-yellow-500'>ITR Filing</li></Link>
            <Link to="/gstFiling"><li className=' hover:text-yellow-500'>GST Filing</li></Link>
            <Link to="/notice"><li className=' hover:text-yellow-500'>Notices</li></Link>
            <Link to="/consultation"> <li className=' hover:text-yellow-500'>Consultation</li></Link>
            </div>

            <div className='text-sm md:text-lg'>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>SERVICES</li>
            <Link to="/tax-planner"> <li className=' hover:text-yellow-500'>Tax Planner</li></Link>
            <Link to="/ngo"><li className=' hover:text-yellow-500'>NGO</li></Link>
            <Link to="/trademark"><li className=' hover:text-yellow-500'>Trademark</li></Link>
            <Link to="/startupFunding"><li className=' hover:text-yellow-500'>Startup Funding</li></Link>
            </div>

            <div className='text-sm md:text-lg'>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>SERVICES</li>
            <Link to="/virtualCfo"> <li className=' hover:text-yellow-500'>Virtual CFO</li></Link>
            <Link to="/businessLoans"> <li className=' hover:text-yellow-500'>Business Loans</li></Link>
            <Link to="/"><li className=' hover:text-yellow-500'>ISO Certificate</li></Link>
            <Link to="/companyFormation"><li className=' hover:text-yellow-500'>Company Formation</li></Link>
            {/* <Link to="/tax-planner"><li className=' hover:text-yellow-500'> GET ECA FOR ITR FILING</li></Link> */}
            </div>
          </ul>
    </div>
    </div>
</div>
{/* <p className='border-dotted w-full  border-yellow-500 border-2'></p> */}

{/* ------------------------------------------------Footer Links------------------------------------------------- */}
<hr className='text-white w-full my-3'/>

{/* ---------------------------------------------Foooter------------------------------------------- */}
<footer className='text-white text-sm text-center mb-0 mt-2'>
  ©Copyright 2024. All Rights Reserved. Govyapar
</footer>

</div>
  )
}

export default Footer;