import React, { useRef, useState } from 'react'
import {motion} from "framer-motion";
import {  Link } from 'react-router-dom';
// import { Tabs, TabsHeader, TabsBody, Tab, TabPanel,} from "@material-tailwind/react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaStar, FaPhoneVolume , FaFile  } from 'react-icons/fa';

function TalkLawyer () {

   // --------------------------------------------subscribe to our newsletter--------------------------------
   const form = useRef();

   const sendEmail = (e) => {
     e.preventDefault();
 
     emailjs
       .sendForm(
         'service_2shjaqt', 
         'template_yb1ikfz', 
         form.current, {
         publicKey: 'KapdrjZ67SvjEm1Jl',
       })
       .then(
         () => {
           toast.success('Subscribed', {
             position: "top-center",
             autoClose: 4000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "colored",
             transition: Flip,
             });
         },
         (error) => {
           console.log('FAILED...', error.text);
         },
       );
   };
 
   // --------------------------------------------contact---------------------------
   const[user, setUser] = useState(
     {
         Name: '', Number:'', Message:''
     }
   )
   // const [errorMsg, setErrorMsg] = useState("");
   let name, value
   const data = (e) =>
   {
     console.log(user)
     name = e.target.name;
     value = e.target.value;
     setUser({...user, [name]:value});
   }
   const getdata = async (e) => 
   {
   if (!user.Name || !user.Number || !user.Message) {
     // setErrorMsg("Fill all fields");
     return;
   }
     // setErrorMsg("");
     
     const{Name, Number, Message} = user;
     e.preventDefault();
     const options = {
         method: 'POST',
         headers: {
             'Content-type' : 'application/json'
         },
         body: JSON.stringify({
             Name, Number, Message
         })
     }
     const res = await fetch(
         'https://govyapar-contact-default-rtdb.firebaseio.com/UserData.json',
         options
         )
         if(res)
         {
           toast.success('Message sent successfully', {
             position: "top-center",
             autoClose: 4000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "colored",
             transition: Flip,
             });
         }
         else
         {
             alert("Error Ocurred")
         }
       }
 
// ----------------------------------Ratings------------------------------------------
    const stats = [
      {
        icon: <FaStar className="text-3xl text-black" />,
        label: 'Google Reviews',
        value: '4.4/5',
      },
      {
        icon: <FaFile className="text-3xl text-black" />,
        label: 'Cases resolved',
        value: '115500+',
      },
      {
        icon: <FaPhoneVolume className="text-3xl text-black" />,
        label: 'Consulting minutes',
        value: '5 Million+',
      },
    ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

      {/* ------------------------------------------Areas of Expertise------------------------------------ */}
    // const [activeTab, setActiveTab] = useState("Legal Notices");
    const data2 = [
      // ----------------------------------1-----------------------------
      {
        label: (<label className='z-0'>Legal Notices</label>),
        value: "Legal Notices",
        desc: (
          <div>
          <p className='text-xl text-black'>Our lawyers will help you draft and send legal notices to address disputes, breaches of contract, or any other legal issues. We have the expertise to ensure that the notices are properly formulated and legally enforceable.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Clear and concise drafting</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tailor made notices</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Quick filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Timely response</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Top lawyer support</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------2-----------------------------
      {
        label: (<label className='z-0'>Employment Issues</label>),
        value: "Employment Issues",
        desc: (
          <div>
          <p className='text-xl text-black'>Whether you are an employer or an employee, our lawyers can guide you through employment-related matters such as contract drafting, workplace disputes, termination, harassment, or discrimination issues. We will provide valuable advice and representation to protect your rights and interests.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Top labour law expert support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Complete documentation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Legal advice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Complaint filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Mediation</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------3-----------------------------
      {
        label: (<label className='z-0'>Property Succession</label>),
        value: "Property Succession",
        desc: (
          <div>
          <p className='text-xl text-black'>Our lawyers possess a deep understanding of the intricate nature of property succession laws, enabling them to provide valuable assistance in matters pertaining to inheritance, wills, and estate planning. With our guidance, you can smoothly navigate through the necessary legal formalities, ensuring a seamless transfer of property rights to the deserving heirs.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Property transfer support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Will drafting</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Will registration</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Succession certificate</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Expert property lawyers advice</li>
          </ul>
          </div>
  
        )
      },
      // ----------------------------------4-----------------------------
      {
        label: (<label className='z-0'>Property Registration</label>),
        value: "Property Registration",
        desc: (
          <div>
          <p className='text-xl text-black'>Govyaparlawyers can help you with property registration, ensuring that the necessary documents are prepared accurately and submitted in compliance with the applicable laws. We can guide you through the entire registration process, minimising the risk of errors or legal complications.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Property background check</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Sale deed preparation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Stamp duty calculation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Document verification</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Property Registration</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------5-----------------------------
       {
        label: (<label className='z-0'>Property Verification</label>),
        value: "Property Verification",
        desc: (
          <div>
          <p className='text-xl text-black'>We can conduct thorough due diligence and verification of property documents, ensuring that the property you intend to buy or sell is legally sound and free from encumbrances or disputes. They can help you make informed decisions and protect your interests during property transactions.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Property litigation check</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Power of attorney</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Property tax verification</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Encumbrance certificate</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ownership Document Verification</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------6-----------------------------
       {
        label: (<label className='z-0'>Cheque Bounce Cases</label>),
        value: "Cheque Bounce Cases",
        desc: (
          <div>
          <p className='text-xl text-black'>Our lawyers can assist you in dealing with a cheque bounce case. We can guide you through the legal proceedings, including sending legal notices, initiating legal action, and representing you in court if necessary.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Document collection</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Sending legal Notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Follow up</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Mediation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Litigation support</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------7-----------------------------
       {
        label: (<label className='z-0'>Money Recovery Issues</label>),
        value: "Money Recovery Issues",
        desc: (
          <div>
          <p className='text-xl text-black'>We can help you in matters related to money recovery, such as outstanding dues, unpaid loans, or breach of financial agreements. Our lawyers can advise you on the best course of action and represent you in negotiations, mediation, or legal proceedings to recover your money.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a legal notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect proof</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate legal proceedings</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Legal Advice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Litigation support</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------7-----------------------------
       {
        label: (<label className='z-0'>File a Consumer Case</label>),
        value: "File a Consumer Case",
        desc: (
          <div>
          <p className='text-xl text-black'>When you encounter issues with defective products, deficient services, or unfair trade practices, we can assist you in filing a consumer case. Our team can guide you through the process, help gather evidence, draft legal documents, and represent you in consumer courts to seek appropriate compensation or resolution.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a legal Notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proof collection</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Mediation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Complaint Filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Litigation support</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------9-----------------------------
      {
        label: (<label className='z-0'>Will Drafting and Registration</label>),
        value: "Will Drafting and Registration",
        desc: (
          <div>
          <p className='text-xl text-black'>Our lawyers can assist you in drafting and registering your will to ensure your assets are distributed according to your wishes. We can help you understand the legal requirements, draft a comprehensive will that covers all your assets, and facilitate its proper registration to ensure its validity.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Will Drafting</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Will Amendment</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Asset collection</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Registering of Will</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------10-----------------------------
      {
        label: (<label className='z-0'>Debt Recovery Tribunal</label>),
        value: "Debt Recovery Tribunal",
        desc: (
          <div>
          <p className='text-xl text-black'>When it comes to debt recovery matters, we can assist you in filing and pursuing cases before the Debt Recovery Tribunal (DRT). We can help you initiate recovery proceedings, draft legal documents, and represent you in DRT hearings to maximise your chances of successfully recovering your debts.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Filling application</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Presentation and verification of application</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Legal support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Reply for counter- claims</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Pre and post litigation support</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------11-----------------------------
      {
        label: (<label className='z-0'>National Green Tribunal Cases</label>),
        value: "National Green Tribunal Cases",
        desc: (
          <div>
          <p className='text-xl text-black'>Lawyers at Govyaparcan provide legal assistance in environmental matters and cases related to the National Green Tribunal (NGT). We can help you understand environmental regulations, file petitions or appeals before the NGT, and represent your interests to seek appropriate remedies in matters related to pollution, conservation, or environmental compliance.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Filing NGT complaint</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Preparing petitions</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Prepare documentations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Litigation support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Legal Advice</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------12-----------------------------
       {
        label: (<label className='z-0'>Motor Accident Claims</label>),
        value: "Motor Accident Claims",
        desc: (
          <div>
          <p className='text-xl text-black'>In the event of a motor accident, our lawyers can assist you in filing and pursuing motor accident claims. We can help gather evidence, negotiate with insurance companies, and represent you in legal proceedings to seek fair compensation for injuries, property damage, or other losses resulting from the accident.</p>
          <br/>
          <ul className='text-black text-xl'>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Intimate the insurance company</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send legal notice</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proof Collection</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> FIR support</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Insurance mediation</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------13-----------------------------
       {
        label: (<label className='z-0'>Setting up of a Business</label>),
        value: "Setting up of a Business",
        desc: (
          <div>
          <p className='text-xl text-black'>Lawyers at Govyaparcan provide comprehensive assistance in setting up a business. We can advise you on the legal structure, draft and file the necessary documents for incorporation, assist in obtaining relevant licenses and registrations, and ensure compliance with legal requirements throughout the setup process.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Business registration</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Expert lawyers support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> GST registration /Filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Business expert support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Annual Compliance guidance</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------14-----------------------------
       {
        label: (<label className='z-0'>Fundraising for Businesses</label>),
        value: "Fundraising for Businesses",
        desc: (
          <div>
          <p className='text-xl text-black'>Lawyers at Govyaparcan provide comprehensive assistance in setting up a business. We can advise you on the legal structure, draft and file the necessary documents for incorporation, assist in obtaining relevant licenses and registrations, and ensure compliance with legal requirements throughout the setup process.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Business registration</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Expert lawyers support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> GST registration /Filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Business expert support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Annual Compliance guidance</li>
          </ul>
          </div>
        )
      },
    ]

    {/* --------------------------------------------Duties of Lawyer-------------------------------------- */}
    const duties = [
      {
        title: "Legal Representation",
        description: "Lawyers provide legal representation and advocacy for their clients, whether individuals or organizations, in various legal matters."
      },
      {
        title: "Legal Advice",
        description: "Lawyers offer expert legal advice to clients on specific legal issues, helping them understand their rights and obligations."
      },
      {
        title: "Research and Analysis",
        description: "Lawyers conduct extensive research and analysis of legal cases and statutes to build strong arguments and strategies for their clients."
      },
      {
        title: "Drafting Legal Documents",
        description: "Lawyers prepare and review legal documents such as contracts, wills, pleadings, and other legal agreements."
      },
      {
        title: "Negotiation and Mediation",
        description: "Lawyers engage in negotiations with opposing parties to reach settlements or mediate disputes to resolve conflicts outside of the courtroom."
      },
      {
        title: "Courtroom Representation",
        description: "Lawyers represent clients in court proceedings, presenting evidence, examining witnesses, and making oral arguments before judges and juries."
      },
      {
        title: "Client Counselling",
        description: "Lawyers provide ongoing counsel to their clients, keeping them informed about the progress of their case and advising them on the best course of action."
      },
      {
        title: "Maintain Confidentiality",
        description: "Lawyers must uphold high ethical standards, ensuring confidentiality and loyalty to their clients while maintaining integrity and professionalism in their practice."
      }
    ];

    // --------------------------------------Reviews----------------------------------------------------
    const reviews = [
      {
        name: 'Sai Abhishek',
        rating: 5,
        review: 'Got the best legal advice from Govyaparfor my startup. Their team was knowledgeable and responsive, and they helped me navigate complex legal issues with ease. Highly recommended',
      },
      {
        name: 'Swetha',
        rating: 5,
        review: 'The lawyers are professional, diligent, and kept me informed throughout my property dispute case. I appreciated their hard work and would definitely recommend them to anyone in need of legal assistance',
      },
      {
        name: 'Zoya Khan',
        rating: 5,
        review: 'Lawyers from Govyaparreally are the best. They helped me in drafting a legal notice and estate planning seamlessly. The team was patient, thorough, and provided practical advice tailored to my needs. Highly recommend their personalised service',
      },
    ];
    window.scrollTo(0,0);
  return (
    <>
     <div className=''>
    <a href='https://wa.me/919808030923'>
    <img src='WhatsappIcon.png' className='fixed w-[28%] md:w-[13%] lg:w-[10%] right-0 top-[520px] md:top-[470px] z-10' alt=''/></a>
     <Link to ="/scheduleCall"><button className='text-white bg-yellow-600 border-2 border-black p-2 rounded-full cursor-pointer z-20 fixed w-[40%] sm:w-[20%] md:w-[24%] lg:w-[20%] xl:w-[16%] right-3 top-[520px] md:top-[470px] overflow-hidden mt-16 sm:mt-24 xl:mt-24 text-xs md:text-lg'><i className="fa fa-clock text-white text-xs md:text-lg"></i> Schedule a Call</button></Link>
    </div>

   {/* -------------------------------------Mobile devices Registration ngo-------------------------------------/ */}
   <div className='max-w-full mx-0 px-8 block md:hidden pt-32'>
  <div className="relative flex flex-col text-gray-700 bg-[#C0C0C0] shadow-xl z-[-1]">
  <div className="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-clip-border">
  </div>
  <div className="p-6 text-center">
    <p>
        <form className='py-4 text-center'>
        <label className='pt-2'>Email</label><br/>
        <input type='email'
         placeholder='Enter your Email'
         className='w-full rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>Mobile Number</label><br/>
        <input type='tel'
          placeholder="Enter Phone Number" 
          maxLength={10}
         className='w-full rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>City/Pincode</label><br/>
        <input type=''
         placeholder='Enter your City/Pincode'
         className='w-full rounded-sm p-1 border-1'/>
         <br/>
         
       <label for="language" className="pt-2">Language</label>
        <select id="language" className="w-full p-1 rounded-sm">
          <option>English</option>
          <option>Hindi</option>
        </select>
        <br/>

        <label for="problem" className="pt-2">Problem Type</label><br/>
        <select id="problem" className="w-full p-1 rounded-sm">
        <option>Legal Notices</option>
          <option>Employment Issues</option>
          <option>Property Succession</option>
          <option>Property Registration</option>
          <option>Property Verification</option>
          <option>Cheque Bounce Cases</option>
          <option>Money Recovery Issues</option>
          <option>File a Consumer Case</option>
          <option>Will Drafting and Registration</option>
          <option>Debt Recovery Tribunal</option>
          <option>National Green Tribunal Cases</option>
          <option>Motor Accident Claims</option>
          <option>Setting up of a Business</option>
          <option>Fundraising for Businesses</option>
          <option>Others</option>
        </select>
      </form>
    </p>
  </div>
  <div className="flex justify-center p-6 py-2 mb-4 gap-7">
  <button className='items-center bg-black text-white p-2 rounded-md'>Book an Appointment Now</button>
  </div>
</div>
</div>
<div className=' bg-[#E0E0E0] relative bottom-60 z-[-2] pb-10 pt-80 px-4 block md:hidden'>
  <h1 className='text-2xl font-extrabold'>Online Lawyer Consultation</h1>
  <h2 className="py-6 text-2xl font-semibold">
  <ul className='text-lg md:text-xl space-y-2 pb-3 text-justify md:text-left py-2'>
      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Affordable legal solutions from senior lawyers. Full litigation, documentation and support
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Guaranteed satisfaction or your money back
      </li>
  </ul>
  </h2> 
</div>

<div className="max-w-xl mx-auto px-1 relative bottom-32">
      <h2 className="text-center text-md lg:text-xl font-semibold mb-3">Steps to Get Legal Consultation Online</h2>
      <div className="flex justify-around items-center">
        <div className="flex flex-col items-center">
          <img src="Fill Out the Form.png" alt="Fill Out the Form" className="w-16 h-16 mb-4 p-2"/>
          <p className="text-center text-sm">Fill Out the Form</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="Schedule an Appointment.png" alt="Schedule an Appointment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Schedule an Appointment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Make Payment.png" alt="Make Payment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Make Payment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Lawyer Will Call You.png" alt="Lawyer Will Call You" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Lawyer Will Call You</p>
        </div>
      </div>
    </div>

{/* ------------------------------------------------------MEDIUM AND LARGE SCREN- Registration ngo--------------------------------- */}
<div className="w-full mx-0 md:block hidden relative bottom-10">
  <div className='bg-[#E0E0E0] float-left w-[45%] h-screen pl-10 lg:pl-32 pt-24 xl:pl-52 2xl:pl-80'>
  <div className="relative flex flex-col text-gray-700 bg-[#C0C0C0] shadow-xl left-8 lg:left-12">
  <div className="relative mt-2 overflow-hidden text-gray-700 bg-clip-border">
  </div>
  <div className="px-6 py-3 text-center">
    <p>
      <form className='py-4 text-center hidden md:block'>
      <label className='pt-2'>Email</label><br/>
      <input type='email'
       placeholder='Enter your Email'
       className='rounded-sm p-1 border-1 w-full'/>
      <br/>

      <label className='pt-2'>Mobile Number</label><br/>
      <input type='tel'
        placeholder="Enter Phone Number" 
        maxLength={10}
       className='rounded-sm p-1 border-1 w-full'/>
      <br/>

      <label className='pt-2'>City/Pincode</label><br/>
      <input type=''
       placeholder='Enter your City/Pincode'
       className='rounded-sm p-1 border-1 w-full'/>
       <br/>

       <label for="language" className="pt-2">Language</label>
        <select id="language" className="w-full p-1 rounded-sm">
          <option>English</option>
          <option>Hindi</option>
        </select>
        <br/>

        <label for="problem" className="pt-2">Problem Type</label><br/>
        <select id="problem" className="w-full p-1 rounded-sm">
          <option>Legal Notices</option>
          <option>Employment Issues</option>
          <option>Property Succession</option>
          <option>Property Registration</option>
          <option>Property Verification</option>
          <option>Cheque Bounce Cases</option>
          <option>Money Recovery Issues</option>
          <option>File a Consumer Case</option>
          <option>Will Drafting and Registration</option>
          <option>Debt Recovery Tribunal</option>
          <option>National Green Tribunal Cases</option>
          <option>Motor Accident Claims</option>
          <option>Setting up of a Business</option>
          <option>Fundraising for Businesses</option>
          <option>Others</option>
        </select>
    </form>
    </p>
  </div>
  <div className="flex justify-center p-6 py-2 mb-4 gap-7">
  <button className='items-center bg-black text-white p-2 rounded-md'>Book an Appointment Now</button>
  </div>
</div>
  </div>

  <div className='bg-white h-screen px-10 pt-3 pr-28 lg:pt-16 lg:pr-32 xl:pr-52 2xl:pr-80'>
  <div className='relative bottom-64 pb-10 md:pt-[340px] lg:pt-[310px] xl:pt-[] px-7 left-10 lg:left-20 lg:px-10 overflow-hidden'>
  <h1 className='md:text-3xl lg:text-4xl xl:text-6xl font-extrabold'>Online Lawyer Consultation</h1>
  <h2 className="py-4 text-xl lg:text-2xl font-semibold">
    <ul className='text-lg md:text-xl space-y-2 pb-3 text-justify md:text-left py-2'>
      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Affordable legal solutions from senior lawyers. Full litigation, documentation and support
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Guaranteed satisfaction or your money back
      </li>
      </ul>
  </h2>
  <div className="max-w-xl mx-auto px-1">
      <h2 className="text-center text-md lg:text-xl font-semibold mb-3">Steps to Get Legal Consultation Online</h2>
      <div className="flex justify-around items-center">
        <div className="flex flex-col items-center">
          <img src="Fill Out the Form.png" alt="Fill Out the Form" className="w-16 h-16 mb-4 p-2"/>
          <p className="text-center text-sm">Fill Out the Form</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="Schedule an Appointment.png" alt="Schedule an Appointment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Schedule an Appointment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Make Payment.png" alt="Make Payment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Make Payment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Lawyer Will Call You.png" alt="Lawyer Will Call You" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Lawyer Will Call You</p>
        </div>
      </div>
    </div>
</div>
  </div>
</div>


{/* --------------------------------RATINGS------------------------ */}
<div className="bg-gray-100 py-8 px-4 relative bottom-10">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3  gap-6 text-center">
        {stats.map((stat, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="mb-2">{stat.icon}</div>
            <p className="text-lg font-semibold">{stat.label}</p>
            <p className="text-xl font-bold text-yellow-500">{stat.value}</p>
          </div>
        ))}
      </div>
    </div>

{/* -----------------------------slider---------------------- */}
<div className='md:bg-gray-900 overflow-hidden py-5 relative bottom-10'>
 <div className="max-w-2xl mx-auto">
      <Slider {...settings} >
        {/* --------------------------1--------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Legal Notices 1.png" alt="Property Verification" />
            <h3 className="text-sm font-medium mb-4">Property Verification</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------2--------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Employment Issues 2.png" alt="Cheque Bounce Cases" />
            <h3 className="text-sm font-medium mb-4">Cheque Bounce Cases</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------3--------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Property Succession 3.png" alt="Money Recovery Issues" />
            <h3 className="text-sm font-medium mb-4">Money Recovery Issues</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------4--------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Property Registration 4.png" alt="Mutual Divorce" />
            <h3 className="text-sm font-medium mb-4">Mutual <br className='hidden lg:block'/> Divorce</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------5--------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Property Verification 5.png" alt="Property Verification" />
            <h3 className="text-sm font-medium mb-4">Property Verification</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------6--------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Cheque Bounce Cases 6.png" alt="Cheque Bounce Cases" />
            <h3 className="text-sm font-medium mb-4">Cheque Bounce Cases</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------7--------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Money Recovery Issues 7.png" alt="Money Recovery Issues" />
            <h3 className="text-sm font-medium mb-4">Money Recovery Issues</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------8--------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="File a Consumer Case 8.png" alt="File a Consumer Case" />
            <h3 className="text-sm font-medium mb-4">File a Consumer Case</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>
      </Slider>
 </div> 
</div>

    {/* ------------------------------------------Areas of Expertise------------------------------------ */}
    <h1 className='text-5xl text-center text-bold text-black md:block hidden md:pt-5'>Areas of Expertise</h1>
    <div className='px-28 py-10 hidden md:block'>
    {/* <Tabs value={activeTab}
    className="grid grid-cols-2 grid-rows-1">
  
      <TabsHeader
        className="rounded-md bg-white shadow-md border-1 border-black pt-10 z-0 flex-col md:w-[70%] xl:w-[60%]"
        indicatorProps={{
          className:
            "bg-transparent shadow-none rounded-none",
        }}
      >
        {data2.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            className={activeTab === value ? "text-yellow-500 text-xl font-bold bg-black rounded-md" : ""}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody
      className='relative right-10 xl:right-16 text-justify'>
        {data2.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs> */}
    </div>

   {/* ------------------------------------------Areas of Expertise(phone)------------------------------------ */}
   <h1 className='text-3xl text-center text-bold text-black md:hidden block relative bottom-10 md:pt-5'>Areas of Expertise</h1>
    <div className='px-2 lg:px-20 cursor-pointer block md:hidden'>

    {/* ---------------------------------------1--Legal Notices------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Legal Notices</h1>
        <div className='text-md text-black'>
          <p>Our lawyers will help you draft and send legal notices to address disputes, breaches of contract, or any other legal issues. We have the expertise to ensure that the notices are properly formulated and legally enforceable.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Clear and concise drafting</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tailor made notices</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Quick filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Timely response</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Top lawyer support</li>
          </ul>
          </div> 

    {/* ---------------------------------------2--Employment Issues------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Employment Issues</h1>
        <div className='text-md text-black'>
          <p>Whether you are an employer or an employee, our lawyers can guide you through employment-related matters such as contract drafting, workplace disputes, termination, harassment, or discrimination issues. We will provide valuable advice and representation to protect your rights and interests.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Top labour law expert support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Complete documentation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Legal advice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Complaint filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Mediation</li>
          </ul>
          </div>

          {/* ---------------------------------------3---Property Succession------------------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Property Succession</h1>
        <div className='text-md text-black'>
          <p>Our lawyers possess a deep understanding of the intricate nature of property succession laws, enabling them to provide valuable assistance in matters pertaining to inheritance, wills, and estate planning. With our guidance, you can smoothly navigate through the necessary legal formalities, ensuring a seamless transfer of property rights to the deserving heirs.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Property transfer support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Will drafting</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Will registration</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Succession certificate</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Expert property lawyers advice</li>
          </ul>
          </div> 
  
    {/* ---------------------------------------4--Property Registration------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Property Registration</h1>
        <div className='text-md text-black'>
          <p>Govyaparlawyers can help you with property registration, ensuring that the necessary documents are prepared accurately and submitted in compliance with the applicable laws. We can guide you through the entire registration process, minimising the risk of errors or legal complications.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Property background check</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Sale deed preparation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Stamp duty calculation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Document verification</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Property Registration</li>
          </ul>
          </div>

           {/* ---------------------------------------5--Property Verification------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Property Verification</h1>
        <div className='text-md text-black'>
          <p>We can conduct thorough due diligence and verification of property documents, ensuring that the property you intend to buy or sell is legally sound and free from encumbrances or disputes. They can help you make informed decisions and protect your interests during property transactions.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Property litigation check</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Power of attorney</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Property tax verification</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Encumbrance certificate</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ownership Document Verification</li>
          </ul>
          </div> 

          {/* ---------------------------------------6--Cheque Bounce Cases------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Cheque Bounce Cases</h1>
        <div className='text-md text-black'>
          <p>Our lawyers can assist you in dealing with a cheque bounce case. We can guide you through the legal proceedings, including sending legal notices, initiating legal action, and representing you in court if necessary.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Document collection</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Sending legal Notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Follow up</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Mediation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Litigation support</li>
          </ul>
          </div> 

          {/* ---------------------------------------7----Money Recovery Issues----------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Money Recovery Issues</h1>
        <div className='text-md text-black'>
          <p>We can help you in matters related to money recovery, such as outstanding dues, unpaid loans, or breach of financial agreements. Our lawyers can advise you on the best course of action and represent you in negotiations, mediation, or legal proceedings to recover your money.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a legal notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect proof</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate legal proceedings</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Legal Advice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Litigation support</li>
          </ul>
          </div> 

          {/* ---------------------------------------8--File a Consumer Case------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>File a Consumer Case</h1>
        <div className='text-md text-black'>
          <p>When you encounter issues with defective products, deficient services, or unfair trade practices, we can assist you in filing a consumer case. Our team can guide you through the process, help gather evidence, draft legal documents, and represent you in consumer courts to seek appropriate compensation or resolution.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a legal Notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proof collection</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Mediation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Complaint Filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Litigation support</li>
          </ul>
          </div>

          {/* ---------------------------------------9---Will Drafting and Registration------------------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Will Drafting and Registration</h1>
        <div className='text-md text-black'>
          <p>Our lawyers can assist you in drafting and registering your will to ensure your assets are distributed according to your wishes. We can help you understand the legal requirements, draft a comprehensive will that covers all your assets, and facilitate its proper registration to ensure its validity.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Will Drafting</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Will Amendment</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Asset collection</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Registering of Will</li>
          </ul>
          </div>  


      {/* ---------------------------------------10--Debt Recovery Tribunal------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Debt Recovery Tribunal</h1>
        <div className='text-md text-black'>
          <p>When it comes to debt recovery matters, we can assist you in filing and pursuing cases before the Debt Recovery Tribunal (DRT). We can help you initiate recovery proceedings, draft legal documents, and represent you in DRT hearings to maximise your chances of successfully recovering your debts.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Filling application</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Presentation and verification of application</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Legal support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Reply for counter- claims</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Pre and post litigation support</li>
          </ul>
          </div>       

      {/* ---------------------------------------11--National Green Tribunal Cases---------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>National Green Tribunal Cases</h1>
        <div className='text-md text-black'>
          <p>Lawyers at Govyaparcan provide legal assistance in environmental matters and cases related to the National Green Tribunal (NGT). We can help you understand environmental regulations, file petitions or appeals before the NGT, and represent your interests to seek appropriate remedies in matters related to pollution, conservation, or environmental compliance.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Filing NGT complaint</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Preparing petitions</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Prepare documentations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Litigation support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Legal Advice</li>
          </ul>
          </div>  
         
      {/* -------------------------------------------12-Motor Accident Claims---------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Motor Accident Claims</h1>
        <div className='text-md text-black'>
          <p>In the event of a motor accident, our lawyers can assist you in filing and pursuing motor accident claims. We can help gather evidence, negotiate with insurance companies, and represent you in legal proceedings to seek fair compensation for injuries, property damage, or other losses resulting from the accident.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Intimate the insurance company</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send legal notice</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proof Collection</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> FIR support</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Insurance mediation</li>
          </ul>
          </div>    

        {/* ------------------------------------13----Setting up of a Business------------------------------------ */}
        <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Setting up of a Business</h1>
        <div className='text-md text-black'>
          <p>Lawyers at Govyaparcan provide comprehensive assistance in setting up a business. We can advise you on the legal structure, draft and file the necessary documents for incorporation, assist in obtaining relevant licenses and registrations, and ensure compliance with legal requirements throughout the setup process.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Business registration</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Expert lawyers support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> GST registration /Filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Business expert support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Annual Compliance guidance</li>
          </ul>
          </div>

      {/* ----------------------------------------14---Fundraising for Businesses------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Fundraising for Businesses</h1>
        <div className='text-md text-black'>
          <p>Lawyers at Govyaparcan provide comprehensive assistance in setting up a business. We can advise you on the legal structure, draft and file the necessary documents for incorporation, assist in obtaining relevant licenses and registrations, and ensure compliance with legal requirements throughout the setup process.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Business registration</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Expert lawyers support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> GST registration /Filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Business expert support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Annual Compliance guidance</li>
          </ul>
          </div>
    </div>

    {/* --------------------------------------------Duties of Lawyer-------------------------------------- */}
    <div className="bg-gray-50 py-10">
      <h2 className="text-center text-2xl md:text-3xl font-semibold mb-10">Duties of Lawyer</h2>
      <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-6 px-4">
        {duties.map((duty, index) => (
          <div key={index} className="flex items-start space-x-4">
            <svg className="text-yellow-700 w-6 h-6 mt-1" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11.414l2.293 2.293a1 1 0 11-1.414 1.414L10 8.414 7.707 10.707a1 1 0 11-1.414-1.414L9 6.586l1.293-1.293a1 1 0 011.414 1.414z" clipRule="evenodd" />
            </svg>
            <div>
              <h3 className="text-lg font-medium text-yellow-600">{duty.title}</h3>
              <p>{duty.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>

    {/* ------------------------------------------------------Why Govyapar------------------ */}
      <div className='py-10 mx-auto max-w-full my-20 md:px-20 lg:px-36'>
    <h1 className='text-2xl md:text-4xl mb-4 text-center font-bold text-black'>Why Govyapar</h1>
   
    <div className="mt-4 py-8 px-4">
      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3  gap-6">

        <div className="flex flex-col items-center mb-2 bg-white p-6 rounded-xl shadow-md shadow-yellow-700">
          <img src="online-meeting.png" alt="Completely Online" className="w-[30%] md:w-[20%]" />
          <h1 className="text-lg font-semibold py-2 uppercase">Completely Online</h1>
          <p className='text-sm text-justify'>Our team of advisors will provide you with sound advice based on your needs. The entire procedure is very smooth and worry free.</p>
        </div>

        <div className="flex flex-col items-center mb-2 bg-white p-6 rounded-xl shadow-md shadow-yellow-700">
          <img src="video-conferencing.png" alt="Video Call" className="w-[30%] md:w-[20%]" />
          <h1 className="text-lg font-semibold py-2 uppercase">Video Call</h1>
          <p className='text-sm text-justify'>We provide quick fixes to busy clients through video calls. While maintaining their privacy over the phone or via email.</p>
        </div>

        <div className="flex flex-col items-center mb-2 bg-white p-6 rounded-xl shadow-md shadow-yellow-700">
          <img src="gstfiling3.jpg" alt="Save Money" className="w-[30%] md:w-[20%]" />
          <h1 className="text-lg font-semibold py-2 uppercase">Save Money</h1>
          <p className='text-sm text-justify'>No extra fees will be charged. Change lawyers quickly without having to pay more. While saving money, get excellent guidance</p>
        </div>
      </div>
    </div>
    </div>

    {/* --------------------------------Reviews---------------------------------- */}
    <section className="py-12">
      <h2 className="text-3xl font-bold text-center mb-8">Reviews</h2>
      <div className="flex flex-wrap justify-center gap-8">
        {reviews.map((review, index) => (
          <div key={index} className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 w-80">
            <div className="flex items-center mb-4">
              {/* <img src={`https://via.placeholder.com/150`} alt={`${review.name}`} className="w-16 h-16 rounded-full mr-4" /> */}
              <div>
                <h3 className="text-xl font-semibold">{review.name}</h3>
                <div className="text-yellow-500">
                  {'⭐'.repeat(review.rating)}
                </div>
              </div>
            </div>
            <p className="text-gray-700">{review.review}</p>
          </div>
        ))}
      </div>
    </section>

    {/* ---------------------------------------------------FAQS------------------------------------------------------- */}
    <section className=" text-black py-24 min-h-screen">
  <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
    <h2 className="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">Frequently Asked Questions</h2>
    <div className="flex flex-col divide-y sm:px-8 lg:px-20 xl:px-32 divide-yellow-600">
      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What kinds of questions can I ask?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>You can ask about anything that has to do with your legal situation, like how a certain process works, what documents or forms you need, or what certain words or phrases mean. You can ask for advice, help with planning, or information about what might happen. You can also get a second opinion on your legal problem through advice sessions and document review services.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How can I keep my identity private while asking questions?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>Your question and the lawyer's answer will be posted on our website, but we won't show who you are. We will NOT share your name or how to reach you. Lawyers who answer your question may get in touch with you to talk more about it.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">When can I expect a reply to my question?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>The specialisations of the lawyers on Govyaparare different and unique. We try to get your question to the right lawyer quickly so you can get a good answer. After you post your question, you can expect to hear back in 24 working hours. Lawyers on Govyaparare active and want to help you solve your legal problems, so you won't have to wait long to get answers.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How can I find out if someone answered my question?</summary>
        <div className="px-4 pb-4">
          <p>When your question gets an answer, we'll let you know by email and text message.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How experienced are the lawyers who will give Consultation?</summary>
        <div className="px-4 pb-4">
          <p>Govyaparonly has verified and experienced consultation lawyer services. These lawyers work in High Courts and the Supreme Court. Depending on the area of concern you choose, you will be connected to a legal expert who will give you the best and right legal advice.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">If I call again, can I talk to the same lawyer?</summary>
        <div className="px-4 pb-4">
          <p>Yes. you can talk to the same lawyer again. When you call, you'll be able to choose the lawyer with whom you've already spoken. Govyaparis one of the top law firms in India.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can I pick the lawyer I want to consult?</summary>
        <div className="px-4 pb-4">
          <p>You can choose the lawyer you've already communicated to. Otherwise, our system chooses the lawyer based on their rating, practice area, your preferred language, and whether or not they are available.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Is there any reason to look for an online lawyer consultation?</summary>
        <div className="px-4 pb-4">
          <p>There are many benefits of looking for a lawyer online, like saving time, making sure your privacy is protected, and not having a language barrier. You can choose an online lawyer who speaks your language on Vakilsearch. Also, when you choose a lawyer online, you can choose from a number of experienced lawyers with the click of a button.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">In what languages can you get legal advice online?</summary>
        <div className="px-4 pb-4">
          <p>On Vakilsearch, you can get online legal advice in the Indian language you speak best. You can get legal advice in English, Hindi, Telugu, Bengali, Marathi, Tamil, and Kannada.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">When can you hire a labour lawyer?</summary>
        <div className="px-4 pb-4">
          <p>Employees need a labour lawyer when they want to file a lawsuit against the management for any sort of injustice or exploitation. Apart from that, it is advisable to take help from them in case of negotiation or compromise during union strikes. These professionals would proceed legally and formally so that no allegation would be made against the union.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How much do mutual consent divorce lawyers near me charge?</summary>
        <div className="px-4 pb-4">
          <p>While there is no fixed rate for lawyers in India, on average, a mutual divorce can cost anywhere between ₹5,000 and ₹50,000. All lawyers take a fee depending on their stature, experience, and skill. It doesn’t necessarily mean that expensive lawyers are great lawyers. Sometimes, brilliant young lawyers can do an excellent job for you at very affordable prices. It is all about meeting with the right people and choosing the proper counsel.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How to hire family lawyers with good specialisation?</summary>
        <div className="px-4 pb-4">
          <p>You cannot hire a family lawyer with specialisation and experience in divorce cases for your businesses. Legal and medical professionals can provide their services based on their specialisations.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How do banking lawyers work?</summary>
        <div className="px-4 pb-4">
          <p>Banking lawyers must work with specific financial service regulations and legislation that outline how banking clients must handle financial matters in order to comply with applicable laws.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What is the duty of immigration lawyer?</summary>
        <div className="px-4 pb-4">
          <p>The job of an immigration lawyer is to provide pragmatic and strategic legal advice on immigration in India. Immigration attorneys are primarily associated with the responsibility of assisting their clients on complex issues with regards to settling down as citizens of India.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How to file an online divorce application?</summary>
        <div className="px-4 pb-4">
          <p>If your divorce is uncontested, filing online may be the way to go. Getting divorced online is a common way for couples to end their marriage. Many companies provide divorce forms online. These websites prepare forms for you based on the information you provide.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How do you get a one-sided divorce?</summary>
        <div className="px-4 pb-4">
          <p>The divorce is always filed by one spouse against the other, and upon service to the other party and his/her failure to appear, the case will be heard ex parte, or in the absence of the other spouse. If there are sufficient grounds and evidence, the court will grant divorce.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can I hire a family court lawyer from a different state?</summary>
        <div className="px-4 pb-4">
          <p>Yes, you can. However, it is recommended to hire a Family lawyer from the state where the case has been filed as the local lawyer will have better knowledge and understanding of the local laws and procedures followed in that particular state.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What exactly do corporate lawyers do?</summary>
        <div className="px-4 pb-4">
          <p>A corporate attorney, or company lawyer, works on various legal issues related to corporate business practices. They often handle business, legal and financial functions for their clients. For instance, a corporate lawyer might be in charge of corporate taxes or appraise and oversee mergers and acquisitions.</p>
        </div>
      </details>
    </div>
  </div>
</section>
  
            

    </>
  )
}

export default TalkLawyer
