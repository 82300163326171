import React from 'react';
import RazorpayButtonNavbar from './RazorpayButtonNavbar';

const Payment = () => {
  return (
   <>
       {/* -------------------------------------Mobile devices Registration ngo-------------------------------------/ */}
   <div className='max-w-full mx-0 px-8 md:float-left md:w-[50%] md:h-screen pt-40 md:pt-60'>
   <h2 className="text-5xl font-bold text-center text-black mb-6">Payment Process</h2>
            <ol className="list-decimal list-inside mb-4 text-gray-900 text-xl">
                <li className="mb-4 flex items-start">
                <img src="check-mark.png" alt="" className='w-[10%]'/>
                    <span>Review the consultation details discussed with our team.</span>
                </li>
                <li className="mb-4 flex items-start">
                <img src="check-mark.png" alt="" className='w-[10%]'/>
                    <span>Confirm the amount to be paid as per the agreed terms.</span>
                </li>
                <li className="mb-4 flex items-start">
                <img src="check-mark.png" alt="" className='w-[10%]'/>
                    <span>Click on the payment button below to complete your transaction securely.</span>
                </li>
            </ol>
    
  </div>

  <div className=' bg-[#E0E0E0] pb-10 py-10 px-4 md:w-[50%] md:float-right md:h-screen'>
  <div className='pb-10 md:pt-60 px-7 md:lg:left-20'>
                <div className="flex justify-center mt-6">
                    <button>
                      <RazorpayButtonNavbar/>
                    </button>
                </div>
            </div>
            <div className=" text-gray-900 justify-center text-center">
                <p className="text-lg font-semibold">If you have any queries, feel free to reach us at:</p>
                <a href= "mailto: support@govyapar.com"><p className="text-xl mt-2"><i className="fa fa-envelope text-yellow-500"> </i> <strong>Email:</strong> support@govyapar.com</p></a>
                <p className="text-xl"><i className="fa fa-phone text-yellow-500"> </i> <strong>Phone:</strong> +91-9808030923</p>
            </div>
  </div>
  
  {/* ------------------------------------------------------MEDIUM AND LARGE SCREN- Registration ngo--------------------------------- */}
  {/* <div className="w-full mx-0 md:block hidden md:pt-28 pt-12">
    <div className='bg-[#E0E0E0] float-left w-[50%] h-screen pl-10 lg:pl-20 pt-32 xl:pl-32 2xl:pl-40'>
    <h2 className="text-6xl font-bold text-center text-black mb-6">Payment Process</h2>
            <ol className="list-decimal list-inside mb-4 text-gray-900 text-xl">
                <li className="mb-4 flex items-start">
                  <img src="check-mark.png" alt="" className='w-[10%]'/>
                    <span>Review the consultation details discussed with our team.</span>
                </li>
                <li className="mb-4 flex items-start">
                <img src="check-mark.png" alt="" className='w-[10%]'/>
                    <span>Confirm the amount to be paid as per the agreed terms.</span>
                </li>
                <li className="mb-4 flex items-start">
                <img src="check-mark.png" alt="" className='w-[10%]'/>
                    <span>Click on the payment button below to complete your transaction securely.</span>
                </li>
            </ol>
    </div>
  
    <div className='bg-white h-screen px-10 pt-3 pr-28 lg:pt-16 lg:pr-32 xl:pr-52 2xl:pr-80'>
    <div className='relative bottom-40 pb-10 md:pt-[340px] lg:pt-[310px] xl:pt-[] px-7 left-10 lg:left-20 lg:px-10'>
   
            <div className="block justify-center mt-6">
                    <button>
                      <RazorpayButtonNavbar/>
                    </button>
            </div>
            
            <div className="mt-6 text-gray-900">
                <p className="text-lg font-semibold">If you have any queries, feel free to reach us at:</p>
                <a href= "mailto: support@govyapar.com"> <p className="text-xl mt-2"><i className="fa fa-envelope text-yellow-500"> </i> <strong>Email:</strong> support@govyapar.com</p></a>
                <p className="text-xl"><i className="fa fa-phone text-yellow-500"> </i> <strong>Phone:</strong> +91-9808030923</p>
            </div>
  
  </div>
    </div>
  </div> */}
</>
  );
};

export default Payment;

