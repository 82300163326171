import React, { useRef, useState } from 'react'
import {motion} from "framer-motion";
import {  Link } from 'react-router-dom';
// import { Tabs, TabsHeader, TabsBody, Tab, TabPanel,} from "@material-tailwind/react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaStar, FaPhoneVolume , FaFile  } from 'react-icons/fa';

function TalkCS () {

   // --------------------------------------------subscribe to our newsletter--------------------------------
   const form = useRef();

   const sendEmail = (e) => {
     e.preventDefault();
 
     emailjs
       .sendForm(
         'service_2shjaqt', 
         'template_yb1ikfz', 
         form.current, {
         publicKey: 'KapdrjZ67SvjEm1Jl',
       })
       .then(
         () => {
           toast.success('Subscribed', {
             position: "top-center",
             autoClose: 4000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "colored",
             transition: Flip,
             });
         },
         (error) => {
           console.log('FAILED...', error.text);
         },
       );
   };
 
   // --------------------------------------------contact---------------------------
   const[user, setUser] = useState(
     {
         Name: '', Number:'', Message:''
     }
   )
   // const [errorMsg, setErrorMsg] = useState("");
   let name, value
   const data = (e) =>
   {
     console.log(user)
     name = e.target.name;
     value = e.target.value;
     setUser({...user, [name]:value});
   }
   const getdata = async (e) => 
   {
   if (!user.Name || !user.Number || !user.Message) {
     // setErrorMsg("Fill all fields");
     return;
   }
     // setErrorMsg("");
     
     const{Name, Number, Message} = user;
     e.preventDefault();
     const options = {
         method: 'POST',
         headers: {
             'Content-type' : 'application/json'
         },
         body: JSON.stringify({
             Name, Number, Message
         })
     }
     const res = await fetch(
         'https://govyapar-contact-default-rtdb.firebaseio.com/UserData.json',
         options
         )
         if(res)
         {
           toast.success('Message sent successfully', {
             position: "top-center",
             autoClose: 4000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "colored",
             transition: Flip,
             });
         }
         else
         {
             alert("Error Ocurred")
         }
       }
 
// ----------------------------------Ratings------------------------------------------
    const stats = [
      {
        icon: <FaStar className="text-3xl text-black" />,
        label: 'Google Reviews',
        value: '4.4/5',
      },
      {
        icon: <FaFile className="text-3xl text-black" />,
        label: 'Cases resolved',
        value: '115500+',
      },
      {
        icon: <FaPhoneVolume className="text-3xl text-black" />,
        label: 'Consulting minutes',
        value: '5 Million+',
      },
    ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

      {/* ------------------------------------------Areas of Expertise------------------------------------ */}
    const [activeTab, setActiveTab] = useState("Legal Compliances");
    const data2 = [
      // ----------------------------------1--Legal Compliances---------------------------
      {
        label: (<label className='z-0'>Legal Compliances</label>),
        value: "Legal Compliances",
        desc: (
          
          <p className='text-xl text-black'>We understand that navigating legal requirements can be a daunting task for businesses. Our team of secretary services experts can help you identify and comply with all necessary legal regulations. Secretarial services will ensure that your business is always operating and adhering to the law. From drafting legal agreements to providing all the support we offer a range of legal and company secretarial services to help you stay compliant. Our secretaries will make sure that all the records are accurate and up-to-date. Choose our corporate secretarial package that covers filing all the necessary forms and reports with the regulatory bodies, and ensuring that the company adheres to all applicable laws.</p>
                   
        )
      },
      // ----------------------------------2-Annual Compliances----------------------------
      {
        label: (<label className='z-0'>Annual Compliances</label>),
        value: "Annual Compliances",
        desc: (

          <p className='text-xl text-black'>Annual compliances can be time-consuming and tedious. But failing to complete them can result in fines and penalties. Our team of professionals provide company secretarial services ensuring that all of your annual compliances are completed accurately and on time. This includes ensuring that the company files its annual reports and financial statements on time, holding annual general meetings, board meetings, maintaining the company's statutory registers, and annual reports to file taxes. We take care of everything so you can focus on running your business.</p>

        )
      },
      // ----------------------------------3-Expert Board Advisors----------------------------
      {
        label: (<label className='z-0'>Expert Board Advisors</label>),
        value: "Expert Board Advisors",
        desc: (
        
          <p className='text-xl text-black'>Good governance is essential for the success of any organisation. Our team of corporate secretarial(CS) experts can help you establish and maintain effective governance practices, including board composition, structure, and operations. We provide practical advice and support to help you meet governance best practices and comply with all relevant regulations. Board members will benefit from our online CS services. By maintaining accurate records of board decisions and supporting the board in developing and implementing governance policies, we will ensure compliance with legal and regulatory requirements.</p>
  
        )
      },
      // ----------------------------------4--Corporate Governance---------------------------
      {
        label: (<label className='z-0'>Corporate Governance</label>),
        value: "Corporate Governance",
        desc: (
         
          <p className='text-xl text-black'>Corporate governance is the foundation of any successful business. Our team of corporate secretarial (CS) experts can help you establish and maintain effective corporate governance practices, including compliance with relevant regulations, policies, and procedures. We provide practical advice and support to help you navigate the complexities of corporate governance and ensure that your business is operating at the highest levels of integrity and accountability. The company's governance practices will be aligned with best practices through the development and implementation of governance policies.
          </p>
        )
      },
       // ----------------------------------5--Corporate Restructuring---------------------------
       {
        label: (<label className='z-0'>Corporate Restructuring</label>),
        value: "Corporate Restructuring",
        desc: (
          <div>
          <p className='text-xl text-black'>Corporate restructuring involves changing a company's structure, which may impact corporate management, ownership, and operational aspects. This process can include activities such as mergers, acquisitions, and demergers, all aimed at enhancing the value of the firm to shareholders and optimising the capital structure. At Vakilsearch, we offer comprehensive services for corporate restructuring, guiding businesses through every phase, from planning to execution. Our expert advisors ensure a smooth transition while safeguarding your interests.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Strategic planning for restructuring businesses</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Mergers and acquisitions (M&A) advisory</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Debt restructuring and refinancing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Asset sales and divestitures</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Capital restructuring and reorganisation</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------6---FEMA Advisory and Compliance--------------------------
       {
        label: (<label className='z-0'>FEMA Advisory and Compliance</label>),
        value: "FEMA Advisory and Compliance",
        desc: (
          <div>
          <p className='text-xl text-black'>The Foreign Exchange Management Act (FEMA) is a complex law governing foreign exchange transactions in India. Our expert consultants provide FEMA advisory services, ensuring compliance and facilitating external trade and payments while promoting the orderly development of the foreign exchange market. We assist Indian businesses and individuals in adhering to FEMA regulations, minimising the risk of penalties.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advisory on foreign exchange regulations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensuring compliance with FEMA guidelines</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Handling foreign investment and external trade transactions</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> NRI investment and repatriation guidance</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> FEMA compliance reporting</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------7---RBI Advisory and Complaint--------------------------
       {
        label: (<label className='z-0'>RBI Advisory and Complaint</label>),
        value: "RBI Advisory and Complaint",
        desc: (
          <div>
          <p className='text-xl text-black'>The Reserve Bank of India (RBI) is India's central bank, responsible for regulating the country's banking and financial sectors. Vakilsearch's expert advisors offer guidance on RBI regulations and help businesses resolve complaints against RBI-regulated entities. We ensure that your dealings with RBI-regulated institutions are conducted smoothly and in accordance with the relevant rules.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Providing advisory on RBI regulations and policies</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assisting with compliance related to RBI guidelines</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Handling complaints and issues with RBI matters</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Exchange control regulations advisory</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Monitoring and reporting to RBI requirements</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------8--SEBI Registration and Complaint---------------------------
       {
        label: (<label className='z-0'>SEBI Registration and Complaint</label>),
        value: "SEBI Registration and Complaint",
        desc: (
          <div>
          <p className='text-xl text-black'>SEBI is the regulatory authority for India's securities and commodity markets. Our experts assist businesses in the registration process with SEBI and provide support for addressing complaints against SEBI-regulated entities. We help ensure that your business complies with SEBI regulations and handles any grievances efficiently.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assisting with SEBI registration and licensing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advisory on SEBI regulations for securities and markets</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Handling complaints and disputes related to SEBI</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Compliance with securities and trading regulations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Market surveillance and insider trading monitoring</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------9---NCLT Dispute--------------------------
      {
        label: (<label className='z-0'>NCLT Dispute</label>),
        value: "NCLT Dispute",
        desc: (
          <div>
          <p className='text-xl text-black'>The National Company Law Tribunal (NCLT) is a specialised tribunal that adjudicates disputes under the Companies Act 2013. Govyaparcan represent businesses in NCLT disputes, ensuring that your interests are protected, and disputes are resolved effectively within the legal framework.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Legal representation and support in NCLT matters</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Handling insolvency and bankruptcy cases</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Resolving disputes related to company law</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Reconstructing or liquidating companies under NCLT supervision</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Appearing in NCLT for legal proceedings</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------10--Arbitration and Alternative Dispute Resolution--------------------------
      {
        label: (<label className='z-0'>Arbitration and Alternative Dispute Resolution</label>),
        value: "Arbitration and Alternative Dispute Resolution",
        desc: (
          <div>
          <p className='text-xl text-black'>Arbitration and Alternative Dispute Resolution (ADR) are invaluable methods for resolving disputes without going to trial. Our services include drafting and representing businesses in arbitration proceedings, which is an external dispute resolution mechanism. Mediation, arbitration, and neutral evaluation are essential components of ADR, allowing disagreeing parties to reach mutually acceptable resolutions without litigation.
          </p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Resolution of legal disputes through non-court methods.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Arbitration services.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Alternative dispute resolution support.</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------11--Secretarial Audit--------------------------
      {
        label: (<label className='z-0'>Secretarial Audit</label>),
        value: "Secretarial Audit",
        desc: (
          <div>
          <p className='text-xl text-black'>Secretarial audits independently assess a company's compliance with corporate laws and regulations. Our team conducts secretarial audits for businesses to identify and address compliance gaps, ensuring adherence to laws, rules, and regulations, including those outlined in the Companies Act 2013.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Independent audit of secretarial and legal compliances.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensuring adherence to statutory and regulatory requirements.</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------12---Cost Audit--------------------------
       {
        label: (<label className='z-0'>Cost Audit</label>),
        value: "Cost Audit",
        desc: (
          <div>
          <p className='text-xl text-black'>Cost audits involve an independent examination of a company's cost accounts to ensure accuracy and conformity with cost accounting principles. Govyaparconducts cost audits for businesses, helping them identify and reduce costs, ultimately aligning their financial strategies with objectives and principles to maximise efficiency and profitability.</p>
          <br/>
          <ul className='text-black text-xl'>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Examination of cost accounting records</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Verification of compliance with relevant laws and regulations.</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Cost control and compliance services.</li>
          </ul>
          </div>
        )
      },
    ]

     // --------------------------------------Reviews----------------------------------------------------
     const reviews = [
      {
        name: 'Akhil Sidharth',
        rating: 5,
        review: "I had a great experience using Vakilsearch's Company Secretarial Services Online: They helped me stay compliant with all the legal formalities. They literally helped me in every step and made the entire process easy and hassle-free. Highly recommended",
      },
      {
        name: 'Neha Singh',
        rating: 5,
        review: "The Company Secretarial Services was exactly what I needed for my business. Their team helped me with everything from filing annual returns to managing my company's statutory records. They saved me time and money, and provided exceptional service. Really impressed",
      },
      {
        name: 'Yamini Shekar',
        rating: 5,
        review: "Needed a Company Secretary's input for my business and got in touch with Vakilsearch. It was a game-changer. Their team helped me stay on top of all the legal requirements. They provided excellent support and were always available to answer my questions. The best in India",
      },
    ];
    window.scrollTo(0,0); 
  return (
    <>
     <div className=''>
    <a href='https://wa.me/919808030923'>
    <img src='WhatsappIcon.png' className='fixed w-[28%] md:w-[13%] lg:w-[10%] right-0 top-[520px] md:top-[470px] z-10' alt=''/></a>
     <Link to ="/scheduleCall"><button className='text-white bg-yellow-600 border-2 border-black p-2 rounded-full cursor-pointer z-20 fixed w-[40%] sm:w-[20%] md:w-[24%] lg:w-[20%] xl:w-[16%] right-3 top-[520px] md:top-[470px] overflow-hidden mt-16 sm:mt-24 xl:mt-24 text-xs md:text-lg'><i className="fa fa-clock text-white text-xs md:text-lg"></i> Schedule a Call</button></Link>
    </div>

   {/* -------------------------------------Mobile devices Registration ngo-------------------------------------/ */}
   <div className='max-w-full mx-0 px-8 block md:hidden pt-32'>
  <div className="relative flex flex-col text-gray-700 bg-[#C0C0C0] shadow-xl z-[-1]">
  <div className="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-clip-border">
  </div>
  <div className="p-6 text-center">
    <p>
        <form className='py-4 text-center'>
        <label className='pt-2'>Email</label><br/>
        <input type='email'
         placeholder='Enter your Email'
         className='w-full rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>Mobile Number</label><br/>
        <input type='tel'
          placeholder="Enter Phone Number" 
          maxLength={10}
         className='w-full rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>City/Pincode</label><br/>
        <input type=''
         placeholder='Enter your City/Pincode'
         className='w-full rounded-sm p-1 border-1'/>
         <br/>
         
       <label for="language" className="pt-2">Language</label>
        <select id="language" className="w-full p-1 rounded-sm">
          <option>English</option>
          <option>Hindi</option>
        </select>
        <br/>

        <label for="problem" className="pt-2">Problem Type</label><br/>
        <select id="problem" className="w-full p-1 rounded-sm">
        <option>Tax Advisory</option>
        <option>Legal Compliances</option>
          <option>Annual Compliances</option>
          <option>Tax Compliance</option>
          <option>Business Compliance</option>
          <option>Accounting $ Bookkeeping</option>
          <option>Others</option>
        </select>
      </form>
    </p>
  </div>
  <div className="flex justify-center p-6 py-2 mb-4 gap-7">
  <button className='items-center bg-black text-white p-2 rounded-md'>Book an Appointment Now</button>
  </div>
</div>
</div>
<div className=' bg-[#E0E0E0] relative bottom-60 z-[-2] pb-10 pt-80 px-4 block md:hidden'>
  <h1 className='text-2xl font-extrabold'>Company Secretary Services Online</h1>
  <h2 className="py-6 text-2xl font-semibold">
  <ul className='text-lg md:text-xl space-y-2 pb-3 text-justify md:text-left py-2'>
      <li>
        <i className="fas fa-check-square text-yellow-500"></i> 200+ Verified Experts
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Friendly Advice on all compliances
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> No Hidden Costs
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Guaranteed Satisfaction, or Full Refund
      </li>
  </ul>
  </h2> 
</div>

<div className="max-w-xl mx-auto px-1 relative bottom-32">
      <h2 className="text-center text-md lg:text-xl font-semibold mb-3">Steps to Get Legal Consultation Online</h2>
      <div className="flex justify-around items-center">
        <div className="flex flex-col items-center">
          <img src="Fill Out the Form.png" alt="Fill Out the Form" className="w-16 h-16 mb-4 p-2"/>
          <p className="text-center text-sm">Fill Out the Form</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="Schedule an Appointment.png" alt="Schedule an Appointment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Schedule an Appointment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Make Payment.png" alt="Make Payment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Make Payment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Lawyer Will Call You.png" alt="Lawyer Will Call You" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Lawyer Will Call You</p>
        </div>
      </div>
    </div>

{/* ------------------------------------------------------MEDIUM AND LARGE SCREN- Registration ngo--------------------------------- */}
<div className="w-full mx-0 md:block hidden relative bottom-10">
  <div className='bg-[#E0E0E0] float-left w-[45%] h-screen pl-10 lg:pl-32 pt-24 xl:pl-52 2xl:pl-80'>
  <div className="relative flex flex-col text-gray-700 bg-[#C0C0C0] shadow-xl left-8 lg:left-12">
  <div className="relative mt-2 overflow-hidden text-gray-700 bg-clip-border">
  </div>
  <div className="px-6 py-3 text-center">
    <p>
      <form className='py-4 text-center hidden md:block'>
      <label className='pt-2'>Email</label><br/>
      <input type='email'
       placeholder='Enter your Email'
       className='rounded-sm p-1 border-1 w-full'/>
      <br/>

      <label className='pt-2'>Mobile Number</label><br/>
      <input type='tel'
        placeholder="Enter Phone Number" 
        maxLength={10}
       className='rounded-sm p-1 border-1 w-full'/>
      <br/>

      <label className='pt-2'>City/Pincode</label><br/>
      <input type=''
       placeholder='Enter your City/Pincode'
       className='rounded-sm p-1 border-1 w-full'/>
       <br/>

       <label for="language" className="pt-2">Language</label>
        <select id="language" className="w-full p-1 rounded-sm">
          <option>English</option>
          <option>Hindi</option>
        </select>
        <br/>

        <label for="problem" className="pt-2">Problem Type</label><br/>
        <select id="problem" className="w-full p-1 rounded-sm">
          <option>Legal Compliances</option>
          <option>Annual Compliances</option>
          <option>Tax Compliance</option>
          <option>Business Compliance</option>
          <option>Accounting $ Bookkeeping</option>
          <option>Others</option>
        </select>
    </form>
    </p>
  </div>
  <div className="flex justify-center p-6 py-2 mb-4 gap-7">
  <button className='items-center bg-black text-white p-2 rounded-md'>Book an Appointment Now</button>
  </div>
</div>
  </div>

  <div className='bg-white h-screen px-10 pt-3 pr-28 lg:pt-16 lg:pr-32 xl:pr-52 2xl:pr-80'>
  <div className='relative bottom-64 pb-10 md:pt-[340px] lg:pt-[310px] xl:pt-[] px-7 left-10 lg:left-20 lg:px-10 overflow-hidden'>
  <h1 className='md:text-3xl lg:text-4xl xl:text-6xl font-extrabold'>Company Secretary Services Online</h1>
  <h2 className="py-4 text-xl lg:text-2xl font-semibold">
  <ul className='text-lg md:text-xl space-y-2 pb-3 text-justify md:text-left py-2'>
      <li>
        <i className="fas fa-check-square text-yellow-500"></i> 200+ Verified Experts
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Friendly Advice on all compliances
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> No Hidden Costs
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Guaranteed Satisfaction, or Full Refund
      </li>
  </ul>
  </h2>
  <div className="max-w-xl mx-auto px-1">
      <h2 className="text-center text-md lg:text-xl font-semibold mb-3">Steps to Get Legal Consultation Online</h2>
      <div className="flex justify-around items-center">
        <div className="flex flex-col items-center">
          <img src="Fill Out the Form.png" alt="Fill Out the Form" className="w-16 h-16 mb-4 p-2"/>
          <p className="text-center text-sm">Fill Out the Form</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="Schedule an Appointment.png" alt="Schedule an Appointment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Schedule an Appointment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Make Payment.png" alt="Make Payment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Make Payment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Lawyer Will Call You.png" alt="Lawyer Will Call You" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Lawyer Will Call You</p>
        </div>
      </div>
    </div>
</div>
  </div>
</div>


{/* --------------------------------RATINGS------------------------ */}
<div className="bg-gray-100 py-8 px-4 relative bottom-10">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3  gap-6 text-center">
        {stats.map((stat, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="mb-2">{stat.icon}</div>
            <p className="text-lg font-semibold">{stat.label}</p>
            <p className="text-xl font-bold text-yellow-500">{stat.value}</p>
          </div>
        ))}
      </div>
    </div>

    {/* ------------------------------------------Areas of Expertise------------------------------------ */}
    <h1 className='text-5xl text-center text-bold text-black md:block hidden md:pt-5'>Areas of Expertise</h1>
    <div className='px-28 py-10 hidden md:block'>
    {/* <Tabs value={activeTab}
    className="grid grid-cols-2 grid-rows-1">
  
      <TabsHeader
        className="rounded-md bg-white shadow-md border-1 border-black pt-10 z-0 flex-col md:w-[70%] xl:w-[60%]"
        indicatorProps={{
          className:
            "bg-transparent shadow-none rounded-none",
        }}
      >
        {data2.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            className={activeTab === value ? "text-yellow-500 text-xl font-bold bg-black rounded-md" : ""}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody
      className='relative right-10 xl:right-16 text-justify'>
        {data2.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs> */}
    </div>

   {/* ------------------------------------------Areas of Expertise(phone)------------------------------------ */}
   <h1 className='text-3xl text-center text-bold text-black md:hidden block relative bottom-10 md:pt-5'>Areas of Expertise</h1>
    <div className='px-2 lg:px-20 cursor-pointer block md:hidden'>

    {/* ---------------------------------------1--Legal Compliances------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Legal Compliances</h1>
        <div className='text-md text-black'>
         <p>We understand that navigating legal requirements can be a daunting task for businesses. Our team of secretary services experts can help you identify and comply with all necessary legal regulations. Secretarial services will ensure that your business is always operating and adhering to the law. From drafting legal agreements to providing all the support we offer a range of legal and company secretarial services to help you stay compliant. Our secretaries will make sure that all the records are accurate and up-to-date. Choose our corporate secretarial package that covers filing all the necessary forms and reports with the regulatory bodies, and ensuring that the company adheres to all applicable laws.</p>
          </div>

    {/* ---------------------------------------2--Annual Compliances------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Annual Compliances</h1>
        <div className='text-md text-black'>
          <p>Annual compliances can be time-consuming and tedious. But failing to complete them can result in fines and penalties. Our team of professionals provide company secretarial services ensuring that all of your annual compliances are completed accurately and on time. This includes ensuring that the company files its annual reports and financial statements on time, holding annual general meetings, board meetings, maintaining the company's statutory registers, and annual reports to file taxes. We take care of everything so you can focus on running your business.</p>
          </div>

          {/* ---------------------------------------3---Expert Board Advisors------------------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Expert Board Advisors</h1>
        <div className='text-md text-black'>
          <p>Good governance is essential for the success of any organisation. Our team of corporate secretarial(CS) experts can help you establish and maintain effective governance practices, including board composition, structure, and operations. We provide practical advice and support to help you meet governance best practices and comply with all relevant regulations. Board members will benefit from our online CS services. By maintaining accurate records of board decisions and supporting the board in developing and implementing governance policies, we will ensure compliance with legal and regulatory requirements.</p>
          </div>  
  
    {/* ---------------------------------------4--Corporate Governance------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Corporate Governance</h1>
        <div className='text-md text-black'>
          <p>Corporate governance is the foundation of any successful business. Our team of corporate secretarial (CS) experts can help you establish and maintain effective corporate governance practices, including compliance with relevant regulations, policies, and procedures. We provide practical advice and support to help you navigate the complexities of corporate governance and ensure that your business is operating at the highest levels of integrity and accountability. The company's governance practices will be aligned with best practices through the development and implementation of governance policies.
          </p>
          </div>

           {/* ---------------------------------------5--Corporate Restructuring------------------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Corporate Restructuring</h1>
        <div className='text-md text-black'>
          <p>Corporate restructuring involves changing a company's structure, which may impact corporate management, ownership, and operational aspects. This process can include activities such as mergers, acquisitions, and demergers, all aimed at enhancing the value of the firm to shareholders and optimising the capital structure. At Vakilsearch, we offer comprehensive services for corporate restructuring, guiding businesses through every phase, from planning to execution. Our expert advisors ensure a smooth transition while safeguarding your interests.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Strategic planning for restructuring businesses</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Mergers and acquisitions (M&A) advisory</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Debt restructuring and refinancing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Asset sales and divestitures</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Capital restructuring and reorganisation</li>
          </ul>
          </div> 

          {/* ---------------------------------------6--FEMA Advisory and Compliance------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>FEMA Advisory and Compliance</h1>
        <div className='text-md text-black'>
          <p>The Foreign Exchange Management Act (FEMA) is a complex law governing foreign exchange transactions in India. Our expert consultants provide FEMA advisory services, ensuring compliance and facilitating external trade and payments while promoting the orderly development of the foreign exchange market. We assist Indian businesses and individuals in adhering to FEMA regulations, minimising the risk of penalties.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advisory on foreign exchange regulations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensuring compliance with FEMA guidelines</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Handling foreign investment and external trade transactions</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> NRI investment and repatriation guidance</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> FEMA compliance reporting</li>
          </ul>
          </div> 

          {/* ---------------------------------------7--RBI Advisory and Complaint----------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>RBI Advisory and Complaint</h1>
        <div className='text-md text-black'>
          <p>The Reserve Bank of India (RBI) is India's central bank, responsible for regulating the country's banking and financial sectors. Vakilsearch's expert advisors offer guidance on RBI regulations and help businesses resolve complaints against RBI-regulated entities. We ensure that your dealings with RBI-regulated institutions are conducted smoothly and in accordance with the relevant rules.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Providing advisory on RBI regulations and policies</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assisting with compliance related to RBI guidelines</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Handling complaints and issues with RBI matters</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Exchange control regulations advisory</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Monitoring and reporting to RBI requirements</li>
          </ul>
          </div> 

          {/* ---------------------------------------8--SEBI Registration and Complaint------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>SEBI Registration and Complaint</h1>
        <div className='text-md text-black'>
          <p>SEBI is the regulatory authority for India's securities and commodity markets. Our experts assist businesses in the registration process with SEBI and provide support for addressing complaints against SEBI-regulated entities. We help ensure that your business complies with SEBI regulations and handles any grievances efficiently.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assisting with SEBI registration and licensing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advisory on SEBI regulations for securities and markets</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Handling complaints and disputes related to SEBI</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Compliance with securities and trading regulations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Market surveillance and insider trading monitoring</li>
          </ul>
          </div>

          {/* ---------------------------------------9--NCLT Dispute------------------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>NCLT Dispute</h1>
        <div className='text-md text-black'>
          <p>The National Company Law Tribunal (NCLT) is a specialised tribunal that adjudicates disputes under the Companies Act 2013. Govyaparcan represent businesses in NCLT disputes, ensuring that your interests are protected, and disputes are resolved effectively within the legal framework.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Legal representation and support in NCLT matters</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Handling insolvency and bankruptcy cases</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Resolving disputes related to company law</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Reconstructing or liquidating companies under NCLT supervision</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Appearing in NCLT for legal proceedings</li>
          </ul>
          </div>  


      {/* ---------------------------------------10--Arbitration and Alternative Dispute Resolution------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Arbitration and Alternative Dispute Resolution</h1>
        <div className='text-md text-black'>
          <p>Arbitration and Alternative Dispute Resolution (ADR) are invaluable methods for resolving disputes without going to trial. Our services include drafting and representing businesses in arbitration proceedings, which is an external dispute resolution mechanism. Mediation, arbitration, and neutral evaluation are essential components of ADR, allowing disagreeing parties to reach mutually acceptable resolutions without litigation.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Resolution of legal disputes through non-court methods.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Arbitration services.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Alternative dispute resolution support.</li>
          </ul>
          </div>       

      {/* ---------------------------------------11--Secretarial Audit---------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Secretarial Audit</h1>
        <div className='text-md text-black'>
          <p>Secretarial audits independently assess a company's compliance with corporate laws and regulations. Our team conducts secretarial audits for businesses to identify and address compliance gaps, ensuring adherence to laws, rules, and regulations, including those outlined in the Companies Act 2013.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Independent audit of secretarial and legal compliances.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensuring adherence to statutory and regulatory requirements.</li>
          </ul>
          </div>  
         
      {/* -------------------------------------------12-Cost Audit---------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Cost Audit</h1>
        <div className='text-md text-black'>
          <p>Cost audits involve an independent examination of a company's cost accounts to ensure accuracy and conformity with cost accounting principles. Govyaparconducts cost audits for businesses, helping them identify and reduce costs, ultimately aligning their financial strategies with objectives and principles to maximise efficiency and profitability.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Examination of cost accounting records</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Verification of compliance with relevant laws and regulations.</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Cost control and compliance services.</li>
          </ul>
          </div>    

        {/* ------------------------------------13--Tax on Property Purchase & Sales------------------------------------ */}
        <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Tax on Property Purchase & Sales</h1>
        <div className='text-md text-black'>
          <p>Offer guidance on tax implications related to buying and selling property, including stamp duty, capital gains, and property registration taxes.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advise on the tax implications of purchasing and selling property in India</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Calculate and file any applicable capital gains tax</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide assistance with stamp duty and other property-related taxes</li>
          </ul>
          </div>

      {/* ----------------------------------------14---NRI Taxation and ITR Filings------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>NRI Taxation and ITR Filings</h1>
        <div className='text-md text-black'>
          <p>Assist non-resident Indians (NRIs) with tax compliance, including filing Income Tax Returns (ITR) and ensuring adherence to taxation laws for income earned in India.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advise on the tax implications of living and working abroad as an NRI</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Help to prepare and file NRI tax returns</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide assistance with claiming NRI tax benefits and exemptions</li>
          </ul>
          </div>

            {/* ----------------------------------------15---Tax on Consultancy & Freelancing------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Tax on Consultancy & Freelancing</h1>
        <div className='text-md text-black'>
          <p>Assist non-resident Indians (NRIs) with tax compliance, including filing Income Tax Returns (ITR) and ensuring adherence to taxation laws for income earned in India.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advise on the tax implications of living and working abroad as an NRI</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Help to prepare and file NRI tax returns</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide assistance with claiming NRI tax benefits and exemptions</li>
          </ul>
          </div>

            {/* ----------------------------------------16--ITR for Tenders---------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>ITR for Tenders</h1>
        <div className='text-md text-black'>
          <p>Assist non-resident Indians (NRIs) with tax compliance, including filing Income Tax Returns (ITR) and ensuring adherence to taxation laws for income earned in India.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advise on the tax implications of living and working abroad as an NRI</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Help to prepare and file NRI tax returns</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide assistance with claiming NRI tax benefits and exemptions</li>
          </ul>
          </div>

            {/* ----------------------------------------17--Others----------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Others</h1>
        <div className='text-md text-black'>
          <p>Provide advice and guidance on a range of tax issues, including:</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tax planning</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tax compliance</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tax disputes</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tax appeals</li>
          </ul>
          </div>
    </div>


    {/* ------------------------------------Who is a Company Secretary?----------------------- */}
    <div className="bg-yellow-50 text-gray-800 p-5 text-justify">
      <div className="max-w-5xl mx-auto p-8">
        <h1 className="text-2xl font-bold text-gray-700 mt-4">Who is a Company Secretary?</h1>
        <p className="mb-4">
        A Company Secretary (CS) is a senior management professional who is responsible for ensuring that a company complies with all applicable laws and regulations. CSs play a vital role in corporate governance and are responsible for a wide range of tasks, including:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>Advising the board of directors on their legal and regulatory obligations</li>
          <li>Ensuring that the company's constitution and other corporate documents are up to date and compliant with the law</li>
          <li>Arranging and conducting board meetings and general meetings of shareholders</li>
          <li>Filing the company's annual returns and other statutory documents with the Registrar of Companies</li>
          <li>Maintaining the company's corporate records, such as registers of directors and shareholders</li>
          <li>Acting as the company's liaison with regulatory bodies</li>
        </ul>

        {/* ------------------------------------------------How Vakilsearch's CS Helps?----------------------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">How Vakilsearch's CS Helps?</h2>
        <p className="mb-4">
        Vakilsearch's CS team can help you with all aspects of corporate governance, including:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>Advising you on your legal and regulatory obligations</li>
          <li>Drafting and reviewing corporate documents, such as your constitution, shareholder agreements, and board meeting resolutions</li>
          <li>Filing the company's annual returns and other statutory documents with the Registrar of Companies</li>
          <li>Arranging and conducting board meetings and general meetings of shareholders</li>
          <li>Maintaining the company's corporate records</li>
          <li>Acting as the company's liaison with regulatory bodies</li>
        </ul>

        {/* -------------------------------------When do you need a Company Secretary?----------------------------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">When do you need a Company Secretary?</h2>
        <p className="mb-4">
        All publicly listed companies are required to appoint a CS. However, private companies are also strongly advised to appoint a CS, even if they are not required to do so. A CS can help private companies to avoid costly compliance mistakes and to ensure that they are operating in a compliant and ethical manner.Here are some specific examples of when you might need a Company Secretary:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>When you are starting a new business</li>
          <li>When you are making changes to your company's structure, such as merging or demerging with another company</li>
          <li>When you are raising investment from external investors</li>
          <li>When you are expanding your business into new markets</li>
          <li>When you are facing regulatory scrutiny</li>
        </ul>
        <p>If you are unsure whether or not you need a Company Secretary, you should consult with a qualified professional.</p>

        {/* ---------------------------------------List of Company Secretarial Services----------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">List of Company Secretarial Services</h2>
        <p className="mb-4">
        The list of company secretarial services available in India is diverse and includes services such as:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>Business Registrations / Company Formation for NBFC, Nidhi, Producer, NGO Registration, Public Company</li>
          <li>Raising Capital through Issue of Equity, Preference Shares, Debentures and Equity Shares with Differential Voting Rights Issue</li>
          <li>Conversions / Corporate Restructuring such as Private Limited to LLP, OPC into Private Limited, Private Limited into OPC, LLP to Private Limited, Private Limited to Public Company</li>
          <li>Investment from Abroad / Overseas Foreign Direct Investment (FDI), Overseas Direct Investment (ODI), External Commercial Borrowings (ECB)</li>
          <li>Closure / Strike-off of company / Liquidation and Winding up of a company</li>
        </ul>

        {/* ---------------------------------------Duties of an Online CS?----------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">Duties of an Online CS?</h2>
        <p className="mb-4">
        A company secretary is essential to the management and governance of a business. In addition to keeping track of corporate records, providing administrative support, and advising the board of directors on legal and financial issues, they are in charge of making sure that legal and regulatory obligations are followed. The following are just a few of the specific duties of a CS online: <br/>Ensuring compliance with the Companies Act of 2013 and other legal and regulatory requirements
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>Maintaining statutory registers, such as registers of members, directors, and charges</li>
          <li>Filing statutory returns and other paperwork with the ROC</li>
          <li>Organising and attending board meetings, and preparing minutes of the meetings</li>
          <li>Providing administrative support, such as drafting letters, managing email correspondence, and maintaining files and records</li>
          <li>Advising the board of directors on legal and financial matters, such as corporate law, taxation, and financial reporting.Business Registrations / Company Formation for NBFC, Nidhi, Producer, NGO Registration, Public Company</li>
          <li>Raising Capital through Issue of Equity, Preference Shares, Debentures and Equity Shares with Differential Voting Rights Issue</li>
          <li>Conversions / Corporate Restructuring such as Private Limited to LLP, OPC into Private Limited, Private Limited into OPC, LLP to Private Limited, Private Limited to Public Company</li>
          <li>Investment from Abroad / Overseas Foreign Direct Investment (FDI), Overseas Direct Investment (ODI), External Commercial Borrowings (ECB)</li>
          <li>Closure / Strike-off of company / Liquidation and Winding up of a company</li>
        </ul>

        {/* ---------------------------------------Why Company Secretarial Services Are Important?----------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">Why Company Secretarial Services Are Important?</h2>
        <p className="mb-4">
        Company secretarial services play a crucial role in ensuring the smooth operation and compliance of a company. Here's why they are important:
        </p>
        <ul className="list-disc list-inside space-y-4">
          <li>
            <strong>Legal Compliance:</strong>
            <p>Company secretarial services help ensure that the company adheres to all relevant laws, regulations, and statutory requirements. They assist in maintaining accurate and up-to-date records, filing required documents with government authorities, and ensuring timely compliance with legal obligations.</p>
          </li>
          <li>
            <strong>Corporate Governance:</strong>
            <p>Company secretaries help maintain proper corporate governance practices. They facilitate communication between the board of directors, shareholders, and other stakeholders, helping to ensure transparency, accountability, and ethical decision-making within the company.</p>
          </li>
          <li>
            <strong>Shareholder Relations:</strong>
            <p>Company secretaries manage interactions with shareholders, including organizing and documenting shareholder meetings, preparing meeting agendas and minutes, and ensuring that shareholders' rights are respected.</p>
          </li>
          <li>
            <strong>Board Support:</strong>
            <p>Company secretaries provide support to the board of directors by coordinating meetings, distributing relevant materials, and ensuring that board decisions are properly recorded and implemented.
            </p>
          </li>
          <li>
            <strong>Records Maintenance:</strong>
            <p>They are responsible for maintaining accurate and complete company records, including financial statements, shareholder registers, and other important documents. This helps ensure transparency and accountability and simplifies audits and regulatory inspections.</p>
          </li>
          <li>
            <strong>Records Maintenance:</strong>
            <p>They are responsible for maintaining accurate and complete company records, including financial statements, shareholder registers, and other important documents. This helps ensure transparency and accountability and simplifies audits and regulatory inspections.</p>
          </li>
          <li>
            <strong>Risk Management:</strong>
            <p>Company secretaries play a role in identifying and managing legal and regulatory risks that the company may face. By staying informed about changes in laws and regulations, they help the company adapt and make informed decisions to mitigate potential risks.</p>
          </li>
          <li>
            <strong>Company Changes and Transactions:</strong>
            <p>During various stages of a company's life, such as mergers, acquisitions, or restructuring, company secretaries facilitate the necessary legal processes, documentations, and filings. </p>
          </li>
          <li>
            <strong>Time and Resource Savings:</strong>
            <p>Outsourcing company secretarial services can save valuable time and resources for the company's management team. It allows them to focus on core business operations while professionals handle the complex administrative and legal tasks. </p>
          </li>
          <li>
            <strong>Expertise:</strong>
            <p>Company secretaries are knowledgeable about corporate laws, regulations, and best practices. Their expertise helps the company navigate legal complexities and avoid costly errors. </p>
          </li>
          <li>
            <strong>Reputation and Credibility:</strong>
            <p>Proper corporate governance and compliance enhance a company's reputation and credibility in the eyes of investors, clients, and the public. Company secretarial services contribute to maintaining the company's good standing and positive image. </p>
          </li>
        </ul>

        {/* --------------------------------------What Are the Challenges Faced by a Firm or Company?----------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">What Are the Challenges Faced by a Firm or Company?</h2>
        <p className="mb-4">
        Companies and corporations encounter a variety of internal and external problems that may have an impact on their long-term success. The following are a some of the typical difficulties businesses and enterprises encounter:
        </p>
        <ul className="list-disc list-inside space-y-4">
          <li>
            <strong>Increasing Competition:</strong>
            <p>As the business landscape becomes more crowded, firms and companies may struggle to differentiate themselves and attract customers</p>
          </li>
          <li>
            <strong>Changing Consumer Preferences:</strong>
            <p>As consumer preferences and expectations evolve, firms and companies may need to adapt their products or services to remain relevant</p>
          </li>
          <li>
            <strong>Technological Advancements:</strong>
            <p>Advances in technology can provide opportunities for firms and companies, but they can also create challenges as businesses try to keep up with the latest developments</p>
          </li>
          <li>
            <strong>Economic Volatility:</strong>
            <p>Economic downturns, recessions, and other economic factors can impact the financial health of firms and companies, making it challenging to plan for the future. Effective management, strategic planning, and a focus on innovation can help firms and companies overcome these challenges and ensure long-term success.</p>
          </li>
        </ul>

        {/* --------------------------------------Benefits of Hiring a Company Secretary (CS)?----------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">Benefits of Hiring a Company Secretary (CS)?</h2>
        <p className="mb-4">
        Having an CS online can provide a range of benefits to a firm or company. Some of the specific benefits of having an online CS may include:
        </p>
        <ul className="list-disc list-inside space-y-4">
          <li>Ensuring Compliance with Laws and Regulations: A company secretary can help ensure that a firm or company is meeting its legal and regulatory obligations, avoiding fines, penalties, or legal action.</li>
          <li>Good Corporate Governance: A company secretary can help establish and maintain effective corporate governance practices, which can build stakeholder confidence and protect the interests of the company.</li>
          <li>A company secretary (CS) can provide objective advice to the board of directors, helping to balance the interests of different stakeholders, such as shareholders, employees, and customers.</li>
          <li>Expert Advice on Legal and Financial Matters: A company secretary (CS) can provide valuable insights on legal and financial matters, helping the board of directors make informed decisions.
          </li>
        </ul>

        {/* --------------------------------------Role of a Corporate Secretary in India?----------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">Role of a Corporate Secretary in India?</h2>
        <p className="mb-4">
        In India, the corporate secretary plays a crucial role in the effective governance of a company. A corporate secretary in India may have the following specific roles and duties:
        </p>
        <ul className="list-disc list-inside space-y-4">
          <li>Ensuring compliance with the Companies Act, 2013 and other legal and regulatory requirements</li>
          <li>Keeping track of business documents such share registers, meeting minutes, and other required registers</li>
          <li>The submission of statutory returns and other paperwork to the Registrar of Companies (ROC)</li>
          <li>Advising the board of directors on legal and financial matters, such as corporate law, taxation, and financial reporting</li>
          <li>Fostering dialogue between the board of directors and shareholders, as well as promoting accountability and transparency in corporate decision-making</li>
          <li>Giving the board of directors administrative support, such as setting up agendas and scheduling meetings.</li>
        </ul>
        <p>Ensuring a company's effective governance, safeguarding the interests of its stakeholders, and maintaining compliance with legal and regulatory obligations are all important roles played by the online CS.</p>

          {/* -------------------------------------Why Govyaparfor Company Secretary (CS) Services?----------------- */}
          <h2 className="text-xl font-bold text-gray-700 mt-4">Why Govyaparfor Company Secretary (CS) Services?</h2>
        <p className="mb-4">
        Vakilsearch's CS team is made up of experienced and qualified professionals who are experts in corporate governance. We can help you with all aspects of corporate governance, from advising you on your legal and regulatory obligations to filing your annual returns and other statutory documents. We also offer a range of other corporate services, such as company registration, trademark registration, and drafting and reviewing commercial contracts
        </p>
        <ul className="list-disc list-inside space-y-4">
          <li>
            <strong>Expertise and Experience</strong>
            <p>Our team of highly experienced and knowledgeable Company Secretaries (CSs) possesses in-depth expertise in corporate law and regulatory compliance. They stay abreast of the latest legal developments, ensuring your business remains compliant and up-to-date.</p>
          </li>
          <li>
            <strong>Effortless Compliance</strong>
            <p>We simplify the complexities of legal compliance, guiding your company through the labyrinth of regulatory requirements. From filing essential documents to maintaining statutory records, our CS services ensure your business adheres to all legal obligations</p>
          </li>
          <li>
            <strong>Innovative Online Platform</strong>
            <p>Experience the convenience of our user-friendly online platform, designed to streamline the management of your legal and secretarial needs. Access essential services, manage statutory responsibilities, and track progress from anywhere, anytime.</p>
          </li>
          <li>
            <strong>Customer Satisfaction Guaranteed</strong>
            <p>We are committed to providing exceptional customer service, as evidenced by our satisfied clientele's positive reviews and testimonials. Join our community of businesses that have benefited from our reliable and client-centric approach.</p>
          </li>
          <li>
            <strong>Transparent and Competitive Pricing</strong>
            <p>We understand the importance of cost-effectiveness and offer transparent and competitive pricing for our CS services. This ensures that businesses receive top-notch support without compromising their budget.</p>
          </li>
          <li>
            <strong>Comprehensive Service Offering</strong>
            <p>Our comprehensive range of services extends beyond Company Secretary functions, encompassing legal advisory services, trademark registration, and other essential business requirements. We are a one-stop solution for your legal and secretarial needs.</p>
          </li>
          <li>
            <strong>Technologically Advanced Solutions</strong>
            <p>Embrace efficiency with our technologically advanced solutions. We integrate technology into our services to streamline processes, providing a seamless and hassle-free experience managing legal and secretarial tasks for your business.</p>
          </li>
        </ul>

      </div>
    </div>

    
    {/* --------------------------------Reviews---------------------------------- */}
    <section className="py-12">
      <h2 className="text-3xl font-bold text-center mb-8">Reviews</h2>
      <div className="flex flex-wrap justify-center gap-8">
        {reviews.map((review, index) => (
          <div key={index} className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 w-80">
            <div className="flex items-center mb-4">
              {/* <img src={`https://via.placeholder.com/150`} alt={`${review.name}`} className="w-16 h-16 rounded-full mr-4" /> */}
              <div>
                <h3 className="text-xl font-semibold">{review.name}</h3>
                <div className="text-yellow-500">
                  {'⭐'.repeat(review.rating)}
                </div>
              </div>
            </div>
            <p className="text-gray-700">{review.review}</p>
          </div>
        ))}
      </div>
    </section>

    {/* ---------------------------------------------------FAQS------------------------------------------------------- */}
    <section className=" text-black py-24 min-h-screen">
  <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
    <h2 className="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">Frequently Asked Questions</h2>
    <div className="flex flex-col divide-y sm:px-8 lg:px-20 xl:px-32 divide-yellow-600">
      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What is Company Secretarial Practice?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>The term ‘company secretarial practise’ refers to the duties of a company secretary, which include keeping track of business documents, advising the board of directors, and ensuring compliance with legal and regulatory requirements.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What Are the Types of Company Secretaries?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>The types of company secretary include a full-time company secretary, part-time company secretary, and a secretarial firm.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What Are the Three Stages of Becoming a Company Secretary?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>The three stages of a company secretary include pre-incorporation, post-incorporation, and annual compliance.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Who Appoints a Company Secretary?</summary>
        <div className="px-4 pb-4">
          <p>A company secretary is appointed by the board of directors of a company.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What is the Importance of Secretarial?</summary>
        <div className="px-4 pb-4">
          <p>The importance of secretarial practice lies in ensuring legal compliance, good corporate governance, and protecting the interests of the company and its stakeholders.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What Are the Four Functions of a Secretary?</summary>
        <div className="px-4 pb-4">
          <p>The four functions of a secretary include providing administrative support, maintaining company records, ensuring compliance with laws and regulations, and advising the board of directors.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What Are the Qualifications Needed to Become an Online CS?</summary>
        <div className="px-4 pb-4">
          <p>Applicants must complete their class 10+2 from a recognised institution in any discipline, excluding fine arts, if they choose to follow the company secretary programme. Following that, you are qualified to submit an application and register for the CSEE T's first level of the CS Foundation Programme.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What Skills Are Required to Be an Effective Company Secretary?</summary>
        <div className="px-4 pb-4">
          <p>The skills required to be an effective company secretary include strong communication and interpersonal skills, attention to detail, and knowledge of corporate laws and regulations.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How Can a Company Secretary Contribute to the Success of a Company?</summary>
        <div className="px-4 pb-4">
          <p>A company secretary can contribute to the success of a company by ensuring legal compliance, good corporate governance, and providing expert advice on legal and financial matters.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can a Small Business Benefit From Having a Company Secretary?</summary>
        <div className="px-4 pb-4">
          <p>Indeed, having a company secretary may be advantageous for small businesses, especially when it comes to ensuring compliance with legal and regulatory requirements.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What is the Difference Between a Company Secretary and a Company Director?</summary>
        <div className="px-4 pb-4">
          <p>The main difference between a company secretary and a company director is that the company secretary is responsible for administrative and compliance matters, while the company director is responsible for the strategic direction and management of the company.</p>
        </div>
      </details>

    </div>
  </div>
</section>
  
     

    </>
  )
}

export default TalkCS
