import React from 'react';
import { motion} from "framer-motion";

function Page2() {
  window.scrollTo(0,0);
  return (
    <>
 {/* ----------------------------------------------Heading------------------------ */}
      
      {/* -----------------------------------------Sovereign gold bond redemption value falls over 4% on Budget duty cut----------------------- */}
          <section className='container max-w-6xl mx-auto pt-32'>
          <div className="flex flex-col space-y-2 text-yellow-500 text-center mb-10">
          <h1 className="text-xl font-bold md:text-3xl">
          Sovereign gold bond redemption value falls over 4% on Budget duty cut
          </h1>
          <div className="flex items-center space-x-2 text-xs md:text-sm text-gray-600 mx-auto">
            <i className="material-icons">calendar_today</i>
            <span>05 Aug 2024</span>
            <i className="material-icons">visibility</i>
            <span>624 Views</span>
            <i className="material-icons">comment</i>
            <span>0 comment</span>
          </div>
        </div>
          <img src="Why Do.png" className='md:float-left md:w-[60%] xl:w-[50%]' alt=''/>
         
          <p className='text-lg text-justify '>
          The redemption price of sovereign gold bond (SGB) scheme (SGB 2016 -17 Series I – Issue date August 05, 2016) will be Rs 6,938 for gold of the 999 purity (of one gram), the Reserve Bank of India (RBI) said on Friday, adding the date of the redemption will be August 5.
          <br/> 
          The price is around 4.5 per cent lower than the average price of the week before the Union Budget, which was presented on July 23. Gold prices dropped after a reduction in Customs Duty.
          <br/>
          The Budget 2024 proposed to cut Customs Duty to 6 per cent on gold and silver. Finance minister Nirmala Sitharaman also proposed to reduce customs duty on platinum to 6.4 per cent.
          <br/>
          The redemption price of SGB is based on the simple average of closing price of gold of 999 purity of the week (Monday-Friday), preceding the date of redemption, as published by the India Bullion and Jewellers Association Ltd.
          <br/>
          “Redemption price for the final redemption due on August 5, 2024 shall be Rs 6,938/- (Rs Six thousand nine hundred and thirty-eight only) per unit of SGB based on the simple average of closing price of gold for the week between July 29 and August 2, 2024,” RBI said.
          <br/>
          According to the scheme, gold bonds are repayable on the expiration of eight years from the date of issuance. “Accordingly, the final redemption date of the above tranche shall be August 5, 2024,” RBI added.
          <br/> <br/>

        {/* -----------------------------------------Disclaimer:- ------------------ */}
        <h1 className="text-xl text-yellow-500">Disclaimer:- </h1>
        The opinions presented are exclusively those of the author and CA Guruji Classes. The material in this piece is intended purely for informational purposes and for individual, non-commercial consumption. It does not constitute expert guidance or an endorsement by any organization. The author, the organization, and its associates are not liable for any form of loss or harm resulting from the information in this article, nor for any decisions made based on it. Furthermore, no segment of this article or newsletter should be employed for any intention unless granted in written form, and we maintain the legal right to address any unauthorized utilization of our article or newsletter.
        </p>
        </section>

    {/* ---------------------------Leave a Comment------------------------------------- */}
      <div className="max-w-xl mx-auto bg-white p-6 rounded shadow-lg my-20">
  
    <h2 className="text-xl font-bold mb-4">Leave a Comment</h2>
    
    <div className="bg-gray-500 text-yellow-500 p-3 rounded mb-6">
      Your email address will not be published. Required fields are marked *
    </div>

    {/* ------------------------ Form ---------------------- */}
    <form>
      <div className="flex items-center mb-4">
        <span className="material-icons text-gray-500 mr-2">person</span>
        <input type="text" name="name" placeholder="Name *" className="w-full p-3 border rounded"/>
      </div>
      
      <div className="flex items-center mb-4">
        <span className="material-icons text-gray-500 mr-2">email</span>
        <input type="email" name="email" placeholder="Email *" className="w-full p-3 border rounded"/>
      </div>
      
      <div className="flex items-center mb-4">
        <span className="material-icons text-gray-500 mr-2">link</span>
        <input type="url" name="website" placeholder="Website" className="w-full p-3 border rounded"/>
      </div>

      <div className="mb-4">
        <textarea name="comment" placeholder="Comment" className="w-full p-3 border rounded  h-24"></textarea>
      </div>
      
      {/* <!-- Submit Button --> */}
      <div className="text-right">
        <button type="submit" className="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-600">
          Post Comment
        </button>
      </div>
    </form>
  </div>

    </>
  );
};

export default Page2;
