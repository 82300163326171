import React, { useRef, useState } from 'react'
import {motion} from "framer-motion";
import {  Link } from 'react-router-dom';
// import { Tabs, TabsHeader, TabsBody, Tab, TabPanel,} from "@material-tailwind/react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaStar, FaPhoneVolume , FaFile  } from 'react-icons/fa';

function TalkCS () {

   // --------------------------------------------subscribe to our newsletter--------------------------------
   const form = useRef();

   const sendEmail = (e) => {
     e.preventDefault();
 
     emailjs
       .sendForm(
         'service_2shjaqt', 
         'template_yb1ikfz', 
         form.current, {
         publicKey: 'KapdrjZ67SvjEm1Jl',
       })
       .then(
         () => {
           toast.success('Subscribed', {
             position: "top-center",
             autoClose: 4000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "colored",
             transition: Flip,
             });
         },
         (error) => {
           console.log('FAILED...', error.text);
         },
       );
   };
 
   // --------------------------------------------contact---------------------------
   const[user, setUser] = useState(
     {
         Name: '', Number:'', Message:''
     }
   )
   // const [errorMsg, setErrorMsg] = useState("");
   let name, value
   const data = (e) =>
   {
     console.log(user)
     name = e.target.name;
     value = e.target.value;
     setUser({...user, [name]:value});
   }
   const getdata = async (e) => 
   {
   if (!user.Name || !user.Number || !user.Message) {
     // setErrorMsg("Fill all fields");
     return;
   }
     // setErrorMsg("");
     
     const{Name, Number, Message} = user;
     e.preventDefault();
     const options = {
         method: 'POST',
         headers: {
             'Content-type' : 'application/json'
         },
         body: JSON.stringify({
             Name, Number, Message
         })
     }
     const res = await fetch(
         'https://govyapar-contact-default-rtdb.firebaseio.com/UserData.json',
         options
         )
         if(res)
         {
           toast.success('Message sent successfully', {
             position: "top-center",
             autoClose: 4000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "colored",
             transition: Flip,
             });
         }
         else
         {
             alert("Error Ocurred")
         }
       }
 
// ----------------------------------Ratings------------------------------------------
    const stats = [
      {
        icon: <FaStar className="text-3xl text-black" />,
        label: 'Google Reviews',
        value: '4.4/5',
      },
      {
        icon: <FaFile className="text-3xl text-black" />,
        label: 'Cases resolved',
        value: '115500+',
      },
      {
        icon: <FaPhoneVolume className="text-3xl text-black" />,
        label: 'Consulting minutes',
        value: '5 Million+',
      },
    ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

      {/* ------------------------------------------Areas of Expertise------------------------------------ */}
    const [activeTab, setActiveTab] = useState("Objection");
    const data2 = [
      // ----------------------------------1--Objection---------------------------
      {
        label: (<label className='z-0'>Objection</label>),
        value: "Objection",
        desc: (
          <div>
          <p className='text-xl text-black'>Trademark, Copyright, patent, or design objections can arise due to various reasons, such as ownership issues, lack of novelty, similar IP in existence and functional aspects. Our IP lawyers offer a comprehensive solution to address these objections curating responses specific to your case. We conduct thorough research to identify the reasons for the objection and provide an appropriate solution.</p><br/>
          <ul className='text-black text-xl'>
          <h1 className='font-bold'>Trademark Objection Services:</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Trademark search and analysis</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proper tax planning and Income Tax Return filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and filing responses to objections raised by the trademark office</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Negotiating with the trademark office or other parties to overcome objections</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Copyright Objection Services:</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Trademark search and analysis</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proper tax planning and Income Tax Return filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and filing responses to objections raised by the trademark office</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Negotiating with the copyright office or other parties to overcome objections.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Patent Objection Services:</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Responding to patent office first examination report</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proper tax planning and Income Tax Return filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Negotiating with the patent office or other parties to overcome objections</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and filing amendments to the patent specification in order to overcome the objections.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Design Objection Services:</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Design search and analysis</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Responding to design office actions</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and filing responses to objections raised by the design office or third parties</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Negotiating with the design office or other parties to overcome objections.</li>
          </ul>
          
          </div>
                   
        )
      },
      // ----------------------------------2-Hearing----------------------------
      {
        label: (<label className='z-0'>Hearing</label>),
        value: "Hearing",
        desc: (
          <div>
          <p className='text-xl text-black'>Trademark, Copyright, patent, or design discrepancies are mostly resolved through hearing. The registrar may issue a notice directing the applicant to attend a hearing in front of the TLA (Trademark Law Authority) hearing board on a specified date to validate your points. It is crucial to have an experienced IP lawyer to attend copyright objections or hearings.</p><br/>
          <ul className='text-black text-xl'>
          <h1 className='font-bold'>Trademark Hearing services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Attend multiple show cause hearings before TM registry</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Argue objection on distinctiveness & similar trademarks in records</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Filing amendments and documents to increase the chances of winning</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Filing amendments and documents to increase the chances of winning</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Copyright Hearing services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Respond to the Show cause notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse the hearing order</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect and submit proofs for the hearing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> NOC preparation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Report Copyright abuse</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Patent Hearing services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advisory by top Patent Agent with regards to technical amendments</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Presenting the argument on the uniqueness and novelty of the Patent</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Presenting the technical features along with the inventor</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Responding to the First Examination Report (FER) and Subsequent Examination Report (SER)</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Responding to the hearing notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Presenting the case during the Oral Hearing</li> 
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Design Hearing services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tailor made advice from top Design lawyer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Detailed insights on the amendments and designs to be filed to win the case</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse the objections and respond to the notice of hearing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Do a through comparative analysis of the similar designs existing in record</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Arguing the objection raised by the patent office.</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------3-Opposition/Cancellation/Rectification----------------------------
      {
        label: (<label className='z-0'>Opposition/Cancellation/Rectification</label>),
        value: "Opposition/Cancellation/Rectification",
        desc: (
          <div>
          <p className='text-xl text-black'>Receiving or filing a notice of opposition/rectification/cancellation for your copyright, patent, or trademark is a serious matter. It involves a separate legal process where third parties challenge the acceptance or registration or grant of copyrights, patents, or designs. Our senior IP lawyers are well-equipped to deal with this scenario and can assist you in initiating or responding to trademark or patent oppositions.</p><br/>
          <ul className='text-black text-xl'>
          <h1 className='font-bold'>Trademark Opposition services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Detailed advisory by IP lawyers on the litigation process</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and filing an opposition globally in case of trademark misuse</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> File a Counter- statement to a trademark opposition filed against you</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Our lawyers will collect evidence and file affidavits supporting your legitimacy</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Attend hearing and arguing the uniqueness and non similarity of the Trademarks.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Copyright Rectification services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Senior Copyright lawyers will provide you with the perfect Strategy against the infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and Filing a copyright rectification on your behalf</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advisory as to the grounds for filing a copyright rectification against infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Prepare and file all the required essential evidence and documents</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Attending Hearing before the Copyright Office</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Argue the uniqueness and non similarity of the work with the Opposer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Follow-up with the Copyright Office.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Patent Opposition services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Patent infringement report if required to have a threat analysis</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Draft and file Pre and Post-grant opposition against infringing patents</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advisory on the best way to tackle the opposition and the similarity claims</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Respond to Patent opposition with detailed technical & legal arguments</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Presenting & arguing the case before the Controller General of Patents</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Attend and argue the uniqueness of the Patent before the Patent Officer.</li> 
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Design Rectification services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Get insights from top design lawyer for initiating proceedings against infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Filing petition for design rectification or filling a counter for design rectification</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and collating evidence for case argument</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Do a through comparative analysis of the similar designs existing in record</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Attend and argue the non-similarity of the Design before the Patent Officer.</li>
          </ul>
          </div>
  
        )
      },
      // ----------------------------------4--Legal Notice---------------------------
      {
        label: (<label className='z-0'>Legal Notice</label>),
        value: "Legal Notice",
        desc: (
          <div>
          <p className='text-xl text-black'>A Legal Notice holds significant importance when it comes to intellectual property rights, encompassing copyright, patent, and design protections. It stands as a pivotal initial step in addressing potential infringements or violations of these rights. Our team of IP lawyers will help you handle legal notices and initiate the required actions.</p><br/>
          <ul className='text-black text-xl'>
          <h1 className='font-bold'>Trademark Legal Notice services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and sending legal notices to entities who:</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Have applied for a similar brand name for identical nature of business</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Have applied for an identical brand name for different nature of business to prevent any overlap</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Are engaged in a related business and you intent to enter into settlement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Have created counterfeit of one’s products.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Starts using the same/similar trademark and is a result of a family dispute</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Discussion with a trademark lawyer regarding the nature of trademark Violation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Clear insights on the best course of action</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect evidence of the oldest prior user of the brand name, first instance of trademark rights and violation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a legal notice to the other party with threat to stop illegal use</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Demand the pay for all the damages</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Reply to any counter legal notice provides support for negotiation or litigation.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Copyright Legal Notice services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Detailed advisory with a copyright lawyer to fight the case against the infringer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse copyright misuse and violations all across the world</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect evidence on the Copyright violation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a legal notice or reply to a copyright violation notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Attending Hearing before the Copyright Office</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Argue the misuse of Copyright in front of the board.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Patent Legal Notice services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Clear Advisory from expert Patent lawyers on Patent misuse</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Insights on legal & technical aspect of the violations
          </li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse Patent misuse and violation globally</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect evidence and documents for Patent infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a legal notice or reply to a legal notice for immediate action.</li> 
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Design Legal Notice services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Consultation from expert design lawyers on misuse and impending legal process</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect evidence of the latest use of the design name and first instance of violation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Issue a legal notice to stop further use of the design</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Demand an explanation for the Design violation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse and respond to the received legal notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate the required litigation actions as per the need.</li>
          </ul>
          </div>
  
        )
      },
       // ----------------------------------5--Due Diligence---------------------------
       {
        label: (<label className='z-0'>Due Diligence</label>),
        value: "Due Diligence",
        desc: (
          <div>
          <p className='text-xl text-black'>Due diligence in the context of IP involves conducting comprehensive research and assessment to verify the authenticity, ownership, and validity of copyrights, patents, or designs before acquisition, transfer, or litigation. Patents, trademarks, copyrights, trade secrets, designs, and other proprietary information are assessed through document reviews and interviews. Our senior IP lawyer will guide you through the process.</p>
          <br/>
          <ul className='text-black text-xl'>
          <h1 className='font-bold'>Trademark Due Diligence services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Search the Indian Trademark Registry thoroughly for conflicting trademarks</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Verify trademark ownership and associated registrations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assess the proposed trademark's strength in the market</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure compliance with Indian trademark laws</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Identify potential liabilities like ongoing litigations or conflicting rights</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Review all pertinent trademark-related documentation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Compile a concise report with findings and legal strategies</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Recommend actions to resolve conflicts or strengthen the legal standing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Execute post-diligence actions promptly for legal compliance and protection.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Copyright Due Diligence services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Review all copyrighted works involved, including various content types</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advice what are the different works that can be copyrighted</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Verify ownership and validity of copyrights through certificates and agreements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assess licenses and agreements for compliance and legitimacy</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Identify potential infringement risks and ongoing litigations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure compliance with Indian copyright laws and requirements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Examine all relevant documentation thoroughly</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Offer actionable recommendations for risk mitigation and conflict resolution</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure compliance with Indian copyright laws and requirements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Implement necessary actions to safeguard copyright interests effectively.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Patent Due Diligence services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Identify protected and protectable subject matters under patent laws</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse the status of patent rights over the subject matter</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Validate territorial applicability and terms for patent validity</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assess third-party claims on the involved patent rights</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Verify the origin and ownership of the patent rights</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Check for conflicts with third-party intellectual property rights.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Design Due Diligence services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Identify the designs that are potentially safeguarded by design laws</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Evaluate the current status of rights associated with the designs in question</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Confirm the territorial scope and conditions for the validity of these designs</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Examine claims made by third parties concerning the rights linked to these designs</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Authenticate the source and ownership of the rights pertaining to these designs</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Investigate potential conflicts with third-party intellectual property rights.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Business Due Diligence</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Gather extensive data on finances, legalities, and operations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse all the financial records rigorously</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Scrutinise legal documents for compliance</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Evaluate operational aspects and risks</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assess market position and competition</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Review workforce and contracts</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Identify and evaluate risks thoroughly</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Compile a comprehensive report with recommendations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide actionable strategies for risk mitigation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Utilise the report for informed decision-making in India.</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------6--IP Infringement--------------------------
       {
        label: (<label className='z-0'>IP Infringement</label>),
        value: "IP Infringement",
        desc: (
          <div>
          <p className='text-xl text-black'>IP Infringement refers to the unauthorised use, reproduction, or distribution of trademarked products, copyrighted works, patented inventions, or protected designs without permission, constituting a violation of intellectual property rights. Our team of experts will help you in IP infringement .</p>
          <br/>
          <ul className='text-black text-xl'>
          <h1 className='font-bold'>Trademark Infringement services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Thorough research on the type Infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Detailed advisory with a Trademark lawyer to fight the case against the infringer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collecting evidence on the technical aspects of infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Reply to a trademark complaint/takedown notice on social media platforms such as Facebook, Youtube, Instagram, snapchat, etc and e-commerce platforms such as Amazon, Flipkart, Meesho, etc</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Sending a legal notice to stop the immediate usage of the trademark</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate legal action against both direct and indirect infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> The legal proceedings can be initiated before:</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Trademark Registry</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> IP division bench</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> District Court</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> High Court</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Police Department</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Copyright Infringement services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Consultation with a senior Copyright lawyer to fight the case against the infringer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect evidence on the incurred infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a DMCA take down notice to the infringer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Reply to a copyright complaint/takedown notice on social media platforms such as Facebook, Youtube, Instagram, snapchat, etc and e-commerce platforms such as Amazon, Flipkart, Meesho, etc.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse and respond to any legal notices/complaints received and respond to the same</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Present the argument on your behalf and initiate the required legal procedure.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Patent Infringement services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advice from Patent lawyer on infringement and the best course of action on the infringer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Clear insights on legal & technical aspect of the infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a legal notice intimating the infringer about the consequences</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Appropriately initiate litigation proceedings.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Design Infringement services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Expert consultation on design infringement and its consequences</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse and collect the evidences and documents for design infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> File a legal notice against the infringer and stop further usage</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Intimate the infringer about the consequences</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Authenticate the source and ownership of the rights pertaining to these designs</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse the counter and present the argument in the hearing.</li>
          </ul><br/>
          </div>
        )
      },
       // ----------------------------------7---Litigation--------------------------
       {
        label: (<label className='z-0'>Litigation</label>),
        value: "Litigation",
        desc: (
          <div>
          <p className='text-xl text-black'>IP infringement refers to the unauthorised use, reproduction, or distribution of copyrighted works, patented inventions, or protected designs without permission, constituting a violation of intellectual property rights. Our team of experts will assist you in addressing IP infringement.</p>
          <br/>
          <ul className='text-black text-xl'>
          <h1 className='font-bold'>Trademark Litigation Services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Complete pre-Litigation assessment</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Discovery of trademark infringement and evidence collection</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Sending legal notices for the infringer and analysing the counter statement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate litigation in District Courts, High Court or IP Division Bench</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Court hearings and argumentation support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Mediation or settlement negotiation as per the damages</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Trial support from senior IP lawyers</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Copyright Litigation Services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Conduct a thorough pre-litigation analysis to assess the copyright infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate the lawsuit by filing necessary legal documents in the appropriate court</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> File pleadings and seek interim measures to address infringement concerns</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Gather evidence and conduct discovery to support the copyright infringement claim</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Present arguments and evidence during court hearings</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Explore mediation or settlement discussions for potential resolution</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proceed to trial and await the court's judgement based on presented evidence</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Consider an appeal process if dissatisfied with the court's decision.</li>
          
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Patent Litigation Services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Sending a formal cease and desist letter to the alleged infringer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate legal action at the district court or High Court</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect pertinent evidence (documents, witness statements, expert opinions) to substantiate legal claims</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Seek interim relief like injunctions to halt infringement or maintain the status quo</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Present arguments and evidence during court hearings</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Support for mediation or settlement negotiations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Claim Injunctions, Damages, Account of Profits, and Legal Fees or Seizure/Confiscation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Pursue appeals if dissatisfied with the court's decision.</li>
          </ul><br/>
          <ul className='text-black text-xl'>
         <h1 className='font-bold'>Design Litigation Services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Conduct a comprehensive pre-litigation assessment</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate legal action at the district court or high court</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Gather evidence and conduct discovery to substantiate the design infringement claim</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Present arguments and evidence during court hearings</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Explore mediation or settlement discussions for potential resolution</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proceed to trial and await the court's judgement based on the evidence presented</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Claim injunctions, damages, account of profits, and legal fees or confiscation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Consider an appeal process if dissatisfied with the court's decision</li>
          </ul>
          </div>
        )
      },
    ]

     // --------------------------------------Reviews----------------------------------------------------
     const reviews = [
      {
        name: 'Jayashree Gautam',
        rating: 5,
        review: "Govyaparoffers the Top IP Lawyers services and are exceptional. The team is knowledgeable, efficient, and helped me navigate complex IP issues with ease. They provided valuable advice and helped me protect my intellectual property rights and save my brand",
      },
      {
        name: 'Aadit Shah',
        rating: 5,
        review: "I had a great experience working with Vakilsearch's Top IP Lawyers. Their team was professional, responsive, and provided me with expert guidance on trademarks, patents, and copyrights. They made the entire process easy and hassle-free",
      },
      {
        name: 'Rinky Sharma',
        rating: 5,
        review: "Vakilsearch's Top IP Lawyers were a great help to me and my business. Their team provided excellent support and helped me with everything from trademark registration to enforcing my IP rights. They saved me time and money and provided exceptional service. Highly recommend",
      },
    ];
    window.scrollTo(0,0);
  return (
    <>
     <div className=''>
    <a href='https://wa.me/919808030923'>
    <img src='WhatsappIcon.png' className='fixed w-[28%] md:w-[13%] lg:w-[10%] right-0 top-[520px] md:top-[470px] z-10' alt=''/></a>
     <Link to ="/scheduleCall"><button className='text-white bg-yellow-600 border-2 border-black p-2 rounded-full cursor-pointer z-20 fixed w-[40%] sm:w-[20%] md:w-[24%] lg:w-[20%] xl:w-[16%] right-3 top-[520px] md:top-[470px] overflow-hidden mt-16 sm:mt-24 xl:mt-24 text-xs md:text-lg'><i className="fa fa-clock text-white text-xs md:text-lg"></i> Schedule a Call</button></Link>
    </div>

   {/* -------------------------------------Mobile devices Registration ngo-------------------------------------/ */}
   <div className='max-w-full mx-0 px-8 block md:hidden pt-32'>
  <div className="relative flex flex-col text-gray-700 bg-[#C0C0C0] shadow-xl z-[-1]">
  <div className="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-clip-border">
  </div>
  <div className="p-6 text-center">
    <p>
        <form className='py-4 text-center'>
        <label className='pt-2'>Email</label><br/>
        <input type='email'
         placeholder='Enter your Email'
         className='w-full rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>Mobile Number</label><br/>
        <input type='tel'
          placeholder="Enter Phone Number" 
          maxLength={10}
         className='w-full rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>City/Pincode</label><br/>
        <input type=''
         placeholder='Enter your City/Pincode'
         className='w-full rounded-sm p-1 border-1'/>
         <br/>
         
       <label for="language" className="pt-2">Language</label>
        <select id="language" className="w-full p-1 rounded-sm">
          <option>English</option>
          <option>Hindi</option>
        </select>
        <br/>

        <label for="problem" className="pt-2">Problem Type</label><br/>
        <select id="problem" className="w-full p-1 rounded-sm">
        <option>Trademark Registration</option>
          <option>Trademark Objection</option>
          <option>Trademark Hearing</option>
          <option>Trademark Opposition</option>
          <option>IP Due Diligence</option>
          <option>Design Registration</option>
          <option>Design Objection</option>
          <option>Design Hearing</option>
          <option>Patent Search</option>
          <option>Patent Application</option>
          <option>Patent FET Response</option>
          <option>Patent Hearing</option>
          <option>Copyright Registration</option>
          <option>Copyright Discrepancy Response</option>
          <option>Trademark Infringement</option>
          <option>Copyright Infringement</option>
          <option>Design Infringement</option>
          <option>Patent Infringement</option>
          <option>IP Litigation</option>
        </select>
      </form>
    </p>
  </div>
  <div className="flex justify-center p-6 py-2 mb-4 gap-7">
  <button className='items-center bg-black text-white p-2 rounded-md'>Book an Appointment Now</button>
  </div>
</div>
</div>
<div className=' bg-[#E0E0E0] relative bottom-60 z-[-2] pb-10 pt-80 px-4 block md:hidden'>
  <h1 className='text-2xl font-extrabold'>Intellectual Property Lawyers in India</h1>
  <h2 className="py-6 text-2xl font-semibold">
  <ul className='text-lg md:text-xl space-y-2 pb-3 text-justify md:text-left py-2'>
  <li>
        <i className="fas fa-check-square text-yellow-500"></i> Expert IP lawyers assist you get your trademark, Patent, Copyright or Design registered
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Guided assistance via chat throughout the process
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Get a lawyer call back in less than 24 hours
      </li>
  </ul>
  </h2> 
</div>

<div className="max-w-xl mx-auto px-1 relative bottom-32">
      <h2 className="text-center text-md lg:text-xl font-semibold mb-3">Steps to Get Legal Consultation Online</h2>
      <div className="flex justify-around items-center">
        <div className="flex flex-col items-center">
          <img src="Fill Out the Form.png" alt="Fill Out the Form" className="w-16 h-16 mb-4 p-2"/>
          <p className="text-center text-sm">Fill Out the Form</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="Schedule an Appointment.png" alt="Schedule an Appointment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Schedule an Appointment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Make Payment.png" alt="Make Payment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Make Payment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Lawyer Will Call You.png" alt="Lawyer Will Call You" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Lawyer Will Call You</p>
        </div>
      </div>
    </div>

{/* ------------------------------------------------------MEDIUM AND LARGE SCREN- Registration ngo--------------------------------- */}
<div className="w-full mx-0 md:block hidden relative bottom-10">
  <div className='bg-[#E0E0E0] float-left w-[45%] h-screen pl-10 lg:pl-32 pt-24 xl:pl-52 2xl:pl-80'>
  <div className="relative flex flex-col text-gray-700 bg-[#C0C0C0] shadow-xl left-8 lg:left-12">
  <div className="relative mt-2 overflow-hidden text-gray-700 bg-clip-border">
  </div>
  <div className="px-6 py-3 text-center">
    <p>
      <form className='py-4 text-center hidden md:block'>
      <label className='pt-2'>Email</label><br/>
      <input type='email'
       placeholder='Enter your Email'
       className='rounded-sm p-1 border-1 w-full'/>
      <br/>

      <label className='pt-2'>Mobile Number</label><br/>
      <input type='tel'
        placeholder="Enter Phone Number" 
        maxLength={10}
       className='rounded-sm p-1 border-1 w-full'/>
      <br/>

      <label className='pt-2'>City/Pincode</label><br/>
      <input type=''
       placeholder='Enter your City/Pincode'
       className='rounded-sm p-1 border-1 w-full'/>
       <br/>

       <label for="language" className="pt-2">Language</label>
        <select id="language" className="w-full p-1 rounded-sm">
          <option>English</option>
          <option>Hindi</option>
        </select>
        <br/>

        <label for="problem" className="pt-2">Problem Type</label><br/>
        <select id="problem" className="w-full p-1 rounded-sm">
          <option>Trademark Registration</option>
          <option>Trademark Objection</option>
          <option>Trademark Hearing</option>
          <option>Trademark Opposition</option>
          <option>IP Due Diligence</option>
          <option>Design Registration</option>
          <option>Design Objection</option>
          <option>Design Hearing</option>
          <option>Patent Search</option>
          <option>Patent Application</option>
          <option>Patent FET Response</option>
          <option>Patent Hearing</option>
          <option>Copyright Registration</option>
          <option>Copyright Discrepancy Response</option>
          <option>Trademark Infringement</option>
          <option>Copyright Infringement</option>
          <option>Design Infringement</option>
          <option>Patent Infringement</option>
          <option>IP Litigation</option>
        </select>
    </form>
    </p>
  </div>
  <div className="flex justify-center p-6 py-2 mb-4 gap-7">
  <button className='items-center bg-black text-white p-2 rounded-md'>Book an Appointment Now</button>
  </div>
</div>
  </div>

  <div className='bg-white h-screen px-10 pt-3 pr-28 lg:pt-16 lg:pr-32 xl:pr-52 2xl:pr-80'>
  <div className='relative bottom-64 pb-10 md:pt-[340px] lg:pt-[310px] xl:pt-[] px-7 left-10 lg:left-20 lg:px-10 overflow-hidden'>
  <h1 className='md:text-3xl lg:text-4xl xl:text-6xl font-extrabold'>Intellectual Property Lawyers in India</h1>
  <h2 className="py-4 text-xl lg:text-2xl font-semibold">
  <ul className='text-lg md:text-xl space-y-2 pb-3 text-justify md:text-left py-2'>
      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Expert IP lawyers assist you get your trademark, Patent, Copyright or Design registered
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Guided assistance via chat throughout the process
      </li>

      <li>
        <i className="fas fa-check-square text-yellow-500"></i> Get a lawyer call back in less than 24 hours
      </li>
  </ul>
  </h2>
  <div className="max-w-xl mx-auto px-1">
      <h2 className="text-center text-md lg:text-xl font-semibold mb-3">Steps to Get Legal Consultation Online</h2>
      <div className="flex justify-around items-center">
        <div className="flex flex-col items-center">
          <img src="Fill Out the Form.png" alt="Fill Out the Form" className="w-16 h-16 mb-4 p-2"/>
          <p className="text-center text-sm">Fill Out the Form</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="Schedule an Appointment.png" alt="Schedule an Appointment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Schedule an Appointment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Make Payment.png" alt="Make Payment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Make Payment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Lawyer Will Call You.png" alt="Lawyer Will Call You" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Lawyer Will Call You</p>
        </div>
      </div>
    </div>
</div>
  </div>
</div>


{/* --------------------------------RATINGS------------------------ */}
<div className="bg-gray-100 py-8 px-4 relative bottom-10">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3  gap-6 text-center">
        {stats.map((stat, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="mb-2">{stat.icon}</div>
            <p className="text-lg font-semibold">{stat.label}</p>
            <p className="text-xl font-bold text-yellow-500">{stat.value}</p>
          </div>
        ))}
      </div>
    </div>

    {/* ------------------------------------------Areas of Expertise------------------------------------ */}
    <h1 className='text-5xl text-center text-bold text-black md:block hidden md:pt-5'>Areas of Expertise</h1>
    <div className='px-28 py-10 hidden md:block'>
    {/* <Tabs value={activeTab}
    className="grid grid-cols-2 grid-rows-1">
  
      <TabsHeader
        className="rounded-md bg-white shadow-md border-1 border-black pt-10 z-0 flex-col md:w-[70%] xl:w-[60%]"
        indicatorProps={{
          className:
            "bg-transparent shadow-none rounded-none",
        }}
      >
        {data2.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            className={activeTab === value ? "text-yellow-500 text-xl font-bold bg-black rounded-md" : ""}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody
      className='relative right-10 xl:right-16 text-justify'>
        {data2.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs> */}
    </div>

   {/* ------------------------------------------Areas of Expertise(phone)------------------------------------ */}
   <h1 className='text-3xl text-center text-bold text-black md:hidden block relative bottom-10 md:pt-5'>Areas of Expertise</h1>
    <div className='px-2 lg:px-20 cursor-pointer block md:hidden'>

    {/* ---------------------------------------1--Objection------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Objection</h1>
        <div className='text-md text-black'>
         <p>Trademark, Copyright, patent, or design objections can arise due to various reasons, such as ownership issues, lack of novelty, similar IP in existence and functional aspects. Our IP lawyers offer a comprehensive solution to address these objections curating responses specific to your case. We conduct thorough research to identify the reasons for the objection and provide an appropriate solution.</p><br/>
         <ul>
         <h1 className='font-bold'>Trademark Objection Services:</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Copyright discrepancy clearance</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Responding to copyright objections/examination reports/discrepancy letters</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and filing responses to objections raised by the copyright office or third parties</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Negotiating with the trademark office or other parties to overcome objections</li>
          </ul>
          <ul>
         <h1 className='font-bold'>Copyright Objection Services:</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Trademark search and analysis</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proper tax planning and Income Tax Return filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and filing responses to objections raised by the trademark office</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Negotiating with the copyright office or other parties to overcome objections.</li>
          </ul>
          <ul>
         <h1 className='font-bold'>Patent Objection Services:</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Responding to patent office first examination report</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proper tax planning and Income Tax Return filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Negotiating with the patent office or other parties to overcome objections</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and filing amendments to the patent specification in order to overcome the objections.</li>
          </ul>
          <ul>
         <h1 className='font-bold'>Design Objection Services:</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Design search and analysis</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Responding to design office actions</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and filing responses to objections raised by the design office or third parties</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Negotiating with the design office or other parties to overcome objections.</li>
          </ul>
          
          </div>

    {/* ---------------------------------------2--Hearing------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Hearing</h1>
        <div className='text-md text-black'>
          <p>Trademark, Copyright, patent, or design discrepancies are mostly resolved through hearing. The registrar may issue a notice directing the applicant to attend a hearing in front of the TLA (Trademark Law Authority) hearing board on a specified date to validate your points. It is crucial to have an experienced IP lawyer to attend copyright objections or hearings.</p><br/>
          <ul>
         <h1 className='font-bold'>Trademark Hearing services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Attend multiple show cause hearings before TM registry</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Argue objection on distinctiveness & similar trademarks in records</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Filing amendments and documents to increase the chances of winning</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Filing amendments and documents to increase the chances of winning</li>
          </ul>
          <ul>
         <h1 className='font-bold'>Copyright Hearing services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Respond to the Show cause notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse the hearing order</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect and submit proofs for the hearing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> NOC preparation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Report Copyright abuse</li>
          </ul>
          <ul>
         <h1 className='font-bold'>Patent Hearing services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advisory by top Patent Agent with regards to technical amendments</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Presenting the argument on the uniqueness and novelty of the Patent</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Presenting the technical features along with the inventor</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Responding to the First Examination Report (FER) and Subsequent Examination Report (SER)</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Responding to the hearing notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Presenting the case during the Oral Hearing</li> 
          </ul>
          <ul>
         <h1 className='font-bold'>Design Hearing services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tailor made advice from top Design lawyer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Detailed insights on the amendments and designs to be filed to win the case</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse the objections and respond to the notice of hearing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Do a through comparative analysis of the similar designs existing in record</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Arguing the objection raised by the patent office.</li>
          </ul>
          </div>

          {/* ---------------------------------------3--Opposition/Cancellation/Rectification------------------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Opposition/Cancellation/Rectification</h1>
        <div className='text-md text-black'>
        <p>Receiving or filing a notice of opposition/rectification/cancellation for your copyright, patent, or trademark is a serious matter. It involves a separate legal process where third parties challenge the acceptance or registration or grant of copyrights, patents, or designs. Our senior IP lawyers are well-equipped to deal with this scenario and can assist you in initiating or responding to trademark or patent oppositions.</p><br/>
          <ul>
          <h1 className='font-bold'>Trademark Opposition services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Detailed advisory by IP lawyers on the litigation process</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and filing an opposition globally in case of trademark misuse</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> File a Counter- statement to a trademark opposition filed against you</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Our lawyers will collect evidence and file affidavits supporting your legitimacy</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Attend hearing and arguing the uniqueness and non similarity of the Trademarks.</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Copyright Rectification services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Senior Copyright lawyers will provide you with the perfect Strategy against the infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and Filing a copyright rectification on your behalf</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advisory as to the grounds for filing a copyright rectification against infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Prepare and file all the required essential evidence and documents</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Attending Hearing before the Copyright Office</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Argue the uniqueness and non similarity of the work with the Opposer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Follow-up with the Copyright Office.</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Patent Opposition services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Patent infringement report if required to have a threat analysis</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Draft and file Pre and Post-grant opposition against infringing patents</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advisory on the best way to tackle the opposition and the similarity claims</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Respond to Patent opposition with detailed technical & legal arguments</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Presenting & arguing the case before the Controller General of Patents</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Attend and argue the uniqueness of the Patent before the Patent Officer.</li> 
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Design Rectification services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Get insights from top design lawyer for initiating proceedings against infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Filing petition for design rectification or filling a counter for design rectification</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and collating evidence for case argument</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Do a through comparative analysis of the similar designs existing in record</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Attend and argue the non-similarity of the Design before the Patent Officer.</li>
          </ul>
          </div>  
  
    {/* ---------------------------------------4--Legal Notice------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Legal Notice</h1>
        <div className='text-md text-black'>
          <p>A Legal Notice holds significant importance when it comes to intellectual property rights, encompassing copyright, patent, and design protections. It stands as a pivotal initial step in addressing potential infringements or violations of these rights. Our team of IP lawyers will help you handle legal notices and initiate the required actions.
          </p><br/>
          <ul>
          <h1 className='font-bold'>Trademark Legal Notice services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Drafting and sending legal notices to entities who:</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Have applied for a similar brand name for identical nature of business</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Have applied for an identical brand name for different nature of business to prevent any overlap</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Are engaged in a related business and you intent to enter into settlement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Have created counterfeit of one’s products.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Starts using the same/similar trademark and is a result of a family dispute</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Discussion with a trademark lawyer regarding the nature of trademark Violation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Clear insights on the best course of action</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect evidence of the oldest prior user of the brand name, first instance of trademark rights and violation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a legal notice to the other party with threat to stop illegal use</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Demand the pay for all the damages</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Reply to any counter legal notice provides support for negotiation or litigation.</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Copyright Legal Notice services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Detailed advisory with a copyright lawyer to fight the case against the infringer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse copyright misuse and violations all across the world</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect evidence on the Copyright violation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a legal notice or reply to a copyright violation notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Attending Hearing before the Copyright Office</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Argue the misuse of Copyright in front of the board.</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Patent Legal Notice services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Clear Advisory from expert Patent lawyers on Patent misuse</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Insights on legal & technical aspect of the violations
          </li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse Patent misuse and violation globally</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect evidence and documents for Patent infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a legal notice or reply to a legal notice for immediate action.</li> 
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Design Legal Notice services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Consultation from expert design lawyers on misuse and impending legal process</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect evidence of the latest use of the design name and first instance of violation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Issue a legal notice to stop further use of the design</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Demand an explanation for the Design violation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse and respond to the received legal notice</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate the required litigation actions as per the need.</li>
          </ul>
          </div>

           {/* ---------------------------------------5-Due Diligence----------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Due Diligence</h1>
        <div className='text-md text-black'>
          <p>Due diligence in the context of IP involves conducting comprehensive research and assessment to verify the authenticity, ownership, and validity of copyrights, patents, or designs before acquisition, transfer, or litigation. Patents, trademarks, copyrights, trade secrets, designs, and other proprietary information are assessed through document reviews and interviews. Our senior IP lawyer will guide you through the process.</p>
          <br/>
          <ul>
          <h1 className='font-bold'>Trademark Due Diligence services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Search the Indian Trademark Registry thoroughly for conflicting trademarks</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Verify trademark ownership and associated registrations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assess the proposed trademark's strength in the market</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure compliance with Indian trademark laws</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Identify potential liabilities like ongoing litigations or conflicting rights</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Review all pertinent trademark-related documentation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Compile a concise report with findings and legal strategies</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Recommend actions to resolve conflicts or strengthen the legal standing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Execute post-diligence actions promptly for legal compliance and protection.</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Copyright Due Diligence services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Review all copyrighted works involved, including various content types</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advice what are the different works that can be copyrighted</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Verify ownership and validity of copyrights through certificates and agreements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assess licenses and agreements for compliance and legitimacy</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Identify potential infringement risks and ongoing litigations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure compliance with Indian copyright laws and requirements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Examine all relevant documentation thoroughly</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Offer actionable recommendations for risk mitigation and conflict resolution</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure compliance with Indian copyright laws and requirements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Implement necessary actions to safeguard copyright interests effectively.</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Patent Due Diligence services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Identify protected and protectable subject matters under patent laws</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse the status of patent rights over the subject matter</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Validate territorial applicability and terms for patent validity</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assess third-party claims on the involved patent rights</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Verify the origin and ownership of the patent rights</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Check for conflicts with third-party intellectual property rights.</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Design Due Diligence services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Identify the designs that are potentially safeguarded by design laws</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Evaluate the current status of rights associated with the designs in question</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Confirm the territorial scope and conditions for the validity of these designs</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Examine claims made by third parties concerning the rights linked to these designs</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Authenticate the source and ownership of the rights pertaining to these designs</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Investigate potential conflicts with third-party intellectual property rights.</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Business Due Diligence</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Gather extensive data on finances, legalities, and operations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse all the financial records rigorously</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Scrutinise legal documents for compliance</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Evaluate operational aspects and risks</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assess market position and competition</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Review workforce and contracts</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Identify and evaluate risks thoroughly</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Compile a comprehensive report with recommendations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide actionable strategies for risk mitigation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Utilise the report for informed decision-making in India.</li>
          </ul>
          </div> 

          {/* ---------------------------------------6--IP Infringement------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>IP Infringement</h1>
        <div className='text-md text-black'>
          <p>IP Infringement refers to the unauthorised use, reproduction, or distribution of trademarked products, copyrighted works, patented inventions, or protected designs without permission, constituting a violation of intellectual property rights. Our team of experts will help you in IP infringement .</p>
          <br/>
          <ul>
          <h1 className='font-bold'>Trademark Infringement services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Thorough research on the type Infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Detailed advisory with a Trademark lawyer to fight the case against the infringer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collecting evidence on the technical aspects of infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Reply to a trademark complaint/takedown notice on social media platforms such as Facebook, Youtube, Instagram, snapchat, etc and e-commerce platforms such as Amazon, Flipkart, Meesho, etc</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Sending a legal notice to stop the immediate usage of the trademark</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate legal action against both direct and indirect infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> The legal proceedings can be initiated before:</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Trademark Registry</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> IP division bench</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> District Court</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> High Court</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Police Department</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Copyright Infringement services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Consultation with a senior Copyright lawyer to fight the case against the infringer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect evidence on the incurred infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a DMCA take down notice to the infringer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Reply to a copyright complaint/takedown notice on social media platforms such as Facebook, Youtube, Instagram, snapchat, etc and e-commerce platforms such as Amazon, Flipkart, Meesho, etc.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse and respond to any legal notices/complaints received and respond to the same</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Present the argument on your behalf and initiate the required legal procedure.</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Patent Infringement services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advice from Patent lawyer on infringement and the best course of action on the infringer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Clear insights on legal & technical aspect of the infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Send a legal notice intimating the infringer about the consequences</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Appropriately initiate litigation proceedings.</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Design Infringement services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Expert consultation on design infringement and its consequences</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse and collect the evidences and documents for design infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> File a legal notice against the infringer and stop further usage</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Intimate the infringer about the consequences</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Authenticate the source and ownership of the rights pertaining to these designs</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse the counter and present the argument in the hearing.</li>
          </ul><br/>
          </div> 

          {/* ---------------------------------------7-Litigation----------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Litigation</h1>
        <div className='text-md text-black'>
          <p>IP infringement refers to the unauthorised use, reproduction, or distribution of copyrighted works, patented inventions, or protected designs without permission, constituting a violation of intellectual property rights. Our team of experts will assist you in addressing IP infringement.</p>
          <br/>
          <ul>
          <h1 className='font-bold'>Trademark Litigation Services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Complete pre-Litigation assessment</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Discovery of trademark infringement and evidence collection</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Sending legal notices for the infringer and analysing the counter statement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate litigation in District Courts, High Court or IP Division Bench</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Court hearings and argumentation support</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Mediation or settlement negotiation as per the damages</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Trial support from senior IP lawyers</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Copyright Litigation Services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Conduct a thorough pre-litigation analysis to assess the copyright infringement</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate the lawsuit by filing necessary legal documents in the appropriate court</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> File pleadings and seek interim measures to address infringement concerns</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Gather evidence and conduct discovery to support the copyright infringement claim</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Present arguments and evidence during court hearings</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Explore mediation or settlement discussions for potential resolution</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proceed to trial and await the court's judgement based on presented evidence</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Consider an appeal process if dissatisfied with the court's decision.</li>
          
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Patent Litigation Services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Sending a formal cease and desist letter to the alleged infringer</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate legal action at the district court or High Court</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Collect pertinent evidence (documents, witness statements, expert opinions) to substantiate legal claims</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Seek interim relief like injunctions to halt infringement or maintain the status quo</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Present arguments and evidence during court hearings</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Support for mediation or settlement negotiations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Claim Injunctions, Damages, Account of Profits, and Legal Fees or Seizure/Confiscation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Pursue appeals if dissatisfied with the court's decision.</li>
          </ul><br/>
          <ul>
         <h1 className='font-bold'>Design Litigation Services</h1>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Conduct a comprehensive pre-litigation assessment</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Initiate legal action at the district court or high court</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Gather evidence and conduct discovery to substantiate the design infringement claim</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Present arguments and evidence during court hearings</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Explore mediation or settlement discussions for potential resolution</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proceed to trial and await the court's judgement based on the evidence presented</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Claim injunctions, damages, account of profits, and legal fees or confiscation</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Consider an appeal process if dissatisfied with the court's decision</li>
          </ul>
          </div>  
    </div>

    {/* ------------------------------------------------------Why Govyapar------------------ */}
    <div className='py-10 mx-auto max-w-full my-20 md:px-20 lg:px-36'>
    <h1 className='text-2xl md:text-4xl mb-4 text-center font-bold text-black'>Why Govyapar</h1>
   
    <div className="mt-4 py-8 px-4">
      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3  gap-6">

        <div className="flex flex-col items-center mb-2 bg-white p-6 rounded-xl shadow-md shadow-yellow-700">
          <img src="online-meeting.png" alt="Completely Online" className="w-[30%] md:w-[20%]" />
          <h1 className="text-lg font-semibold py-2 uppercase">Completely Online</h1>
          <p className='text-sm text-justify'>Our team of advisors will provide you with sound advice based on your needs. The entire procedure is very smooth and worry free.</p>
        </div>

        <div className="flex flex-col items-center mb-2 bg-white p-6 rounded-xl shadow-md shadow-yellow-700">
          <img src="video-conferencing.png" alt="Video Call" className="w-[30%] md:w-[20%]" />
          <h1 className="text-lg font-semibold py-2 uppercase">Video Call</h1>
          <p className='text-sm text-justify'>We provide quick fixes to busy clients through video calls. While maintaining their privacy over the phone or via email.</p>
        </div>

        <div className="flex flex-col items-center mb-2 bg-white p-6 rounded-xl shadow-md shadow-yellow-700">
          <img src="gstfiling3.jpg" alt="Save Money" className="w-[30%] md:w-[20%]" />
          <h1 className="text-lg font-semibold py-2 uppercase">Save Money</h1>
          <p className='text-sm text-justify'>No extra fees will be charged. Change lawyers quickly without having to pay more. While saving money, get excellent guidance</p>
        </div>
      </div>
    </div>
    </div>

    {/* --------------------------------Reviews---------------------------------- */}
    <section className="py-12">
      <h2 className="text-3xl font-bold text-center mb-8">Reviews</h2>
      <div className="flex flex-wrap justify-center gap-8">
        {reviews.map((review, index) => (
          <div key={index} className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 w-80">
            <div className="flex items-center mb-4">
              {/* <img src={`https://via.placeholder.com/150`} alt={`${review.name}`} className="w-16 h-16 rounded-full mr-4" /> */}
              <div>
                <h3 className="text-xl font-semibold">{review.name}</h3>
                <div className="text-yellow-500">
                  {'⭐'.repeat(review.rating)}
                </div>
              </div>
            </div>
            <p className="text-gray-700">{review.review}</p>
          </div>
        ))}
      </div>
    </section>

    {/* ---------------------------------------------------FAQS------------------------------------------------------- */}
    <section className=" text-black py-24 min-h-screen">
  <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
    <h2 className="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">Frequently Asked Questions</h2>
    <div className="flex flex-col divide-y sm:px-8 lg:px-20 xl:px-32 divide-yellow-600">
      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What's the first step I should take when considering trademark registration?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>The initial step is to conduct a comprehensive trademark search to ensure your chosen mark is unique and not already in use by someone else.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can I trademark anything, or are there limitations?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>Not everything can be trademarked. Marks must be distinctive and not likely to cause confusion with existing trademarks. Descriptive or generic terms might not be eligible.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What information is required for a trademark application?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>You'll need to provide details about your mark, the goods/services it covers, the basis for filing, and your intended use of the mark.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can I protect a slogan or tagline with a trademark?</summary>
        <div className="px-4 pb-4">
          <p>Yes, if your slogan is unique and serves as a source identifier for your products or services, it can be eligible for trademark protection.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What if my trademark application gets rejected?</summary>
        <div className="px-4 pb-4">
          <p>If your application is rejected, you'll receive an office action detailing the issues. You can address these concerns and resubmit your application.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Is trademark registration necessary for local businesses?</summary>
        <div className="px-4 pb-4">
          <p>Even for local businesses, trademark registration can provide protection against potential future conflicts and expansion to other regions.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can I protect my trademark internationally?</summary>
        <div className="px-4 pb-4">
          <p>Yes, you can seek international protection through treaties like the Madrid Protocol, which streamlines the process of registering trademarks in multiple countries.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What responsibilities come with owning a registered trademark?</summary>
        <div className="px-4 pb-4">
          <p>You're responsible for monitoring unauthorised use of your mark and taking legal action if infringement occurs. Regular renewals are also required to maintain the registration.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can I modify my trademark after registration?</summary>
        <div className="px-4 pb-4">
          <p>Yes, you can make certain changes, but they need to be properly documented and may require additional filings with the relevant authorities.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What's the difference between state and federal trademark registration?</summary>
        <div className="px-4 pb-4">
          <p>State-level registration provides protection within the state, while federal registration grants nationwide protection and certain legal advantages.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can I sell or license my trademark to someone else?</summary>
        <div className="px-4 pb-4">
          <p>Yes, you can sell or license your trademark rights to others, which can be a valuable source of revenue.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What is the 'likelihood of confusion' in trademark law?</summary>
        <div className="px-4 pb-4">
          <p>Likelihood of confusion refers to the potential for consumers to mistake one mark for another due to similarities in appearance, sound, or meaning</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What evidence is needed to prove trademark infringement?</summary>
        <div className="px-4 pb-4">
          <p>Evidence might include examples of consumer confusion, instances of actual infringement, and documentation of your trademark's prior use and registration</p>
        </div>
      </details>

    </div>
  </div>
</section>
  
      

    </>
  )
}

export default TalkCS
