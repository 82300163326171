import React, { useRef, useState } from 'react'
import {motion} from "framer-motion";
import {  Link } from 'react-router-dom';
// import { Tabs, TabsHeader, TabsBody, Tab, TabPanel,} from "@material-tailwind/react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaStar, FaPhoneVolume , FaFile  } from 'react-icons/fa';

function TalkCA () {

   // --------------------------------------------subscribe to our newsletter--------------------------------
   const form = useRef();

   const sendEmail = (e) => {
     e.preventDefault();
 
     emailjs
       .sendForm(
         'service_2shjaqt', 
         'template_yb1ikfz', 
         form.current, {
         publicKey: 'KapdrjZ67SvjEm1Jl',
       })
       .then(
         () => {
           toast.success('Subscribed', {
             position: "top-center",
             autoClose: 4000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "colored",
             transition: Flip,
             });
         },
         (error) => {
           console.log('FAILED...', error.text);
         },
       );
   };
 
   // --------------------------------------------contact---------------------------
   const[user, setUser] = useState(
     {
         Name: '', Number:'', Message:''
     }
   )
   // const [errorMsg, setErrorMsg] = useState("");
   let name, value
   const data = (e) =>
   {
     console.log(user)
     name = e.target.name;
     value = e.target.value;
     setUser({...user, [name]:value});
   }
   const getdata = async (e) => 
   {
   if (!user.Name || !user.Number || !user.Message) {
     // setErrorMsg("Fill all fields");
     return;
   }
     // setErrorMsg("");
     
     const{Name, Number, Message} = user;
     e.preventDefault();
     const options = {
         method: 'POST',
         headers: {
             'Content-type' : 'application/json'
         },
         body: JSON.stringify({
             Name, Number, Message
         })
     }
     const res = await fetch(
         'https://govyapar-contact-default-rtdb.firebaseio.com/UserData.json',
         options
         )
         if(res)
         {
           toast.success('Message sent successfully', {
             position: "top-center",
             autoClose: 4000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "colored",
             transition: Flip,
             });
         }
         else
         {
             alert("Error Ocurred")
         }
       }
 
// ----------------------------------Ratings------------------------------------------
    const stats = [
      {
        icon: <FaStar className="text-3xl text-black" />,
        label: 'Google Reviews',
        value: '4.4/5',
      },
      {
        icon: <FaFile className="text-3xl text-black" />,
        label: 'Cases resolved',
        value: '115500+',
      },
      {
        icon: <FaPhoneVolume className="text-3xl text-black" />,
        label: 'Consulting minutes',
        value: '5 Million+',
      },
    ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

      {/* ------------------------------------------Areas of Expertise------------------------------------ */}
    const [activeTab, setActiveTab] = useState("Tax Advisory");
    const data2 = [
      // ----------------------------------1-----------------------------
      {
        label: (<label className='z-0'>Tax Advisory</label>),
        value: "Tax Advisory",
        desc: (
          <div>
          <p className='text-xl text-black'>Our team of skilled Chartered Accountants at Govyaparprovides extensive tax advisory services to help individuals and businesses navigate the intricate world of taxation. We remain well-informed about the latest tax laws and regulations, ensuring that you receive precise and timely advice customised to your specific requirements.Our experts help,</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Better tax planning structure and explains the available deductions</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse your financial situation and assess potential risks.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse your financial situation and assess potential risks.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure that you make informed decisions and remain compliant with tax laws.</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------2-----------------------------
      {
        label: (<label className='z-0'>GST Audit Report (GSTR 9 & 9C)</label>),
        value: "GST Audit Report (GSTR 9 & 9C)",
        desc: (
          <div>
          <p className='text-xl text-black'>Verify and certify the accuracy of a company's GST-related financial information for regulatory compliance.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure compliance with GST regulations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Verify accuracy of financial statements and GST returns</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Detecting and rectifying any discrepancies.</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------3-----------------------------
      {
        label: (<label className='z-0'>Transfer Pricing (Opinion Report)</label>),
        value: "Transfer Pricing (Opinion Report)",
        desc: (
          <div>
          <p className='text-xl text-black'>Provide expert guidance on setting appropriate prices for cross-border transactions, ensuring compliance with tax regulations.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Independent opinion on transfer pricing arrangements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assess compliance with OECD Transfer Pricing Guidelines</li>
          </ul>
          </div>
  
        )
      },
      // ----------------------------------4--Transfer Pricing (Audit Report)---------------------------
      {
        label: (<label className='z-0'>Transfer Pricing (Audit Report)</label>),
        value: "Transfer Pricing (Audit Report)",
        desc: (
          <div>
          <p className='text-xl text-black'>Conduct a thorough examination of a company's transfer pricing records to verify adherence to tax laws and minimize risks associated with cross-border transactions.
          </p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Full audit of transfer pricing arrangements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Review documentation and transactions for compliance with Indian tax laws and regulations</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------5--ITR Filing for Companies---------------------------
       {
        label: (<label className='z-0'>ITR Filing for Companies</label>),
        value: "ITR Filing for Companies",
        desc: (
          <div>
          <p className='text-xl text-black'>Professionally prepare and submit Income Tax Returns for companies, optimising tax efficiency while maintaining compliance with Indian tax regulations.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Preparation and filing of corporate income tax return (ITR)</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure accuracy and completeness of return</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Claim all eligible deductions and credits</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------6---Income Tax Notices--------------------------
       {
        label: (<label className='z-0'>Income Tax Notices</label>),
        value: "Income Tax Notices",
        desc: (
          <div>
          <p className='text-xl text-black'>Handle and respond to various income tax notices, such as inquiries, assessments, and reassessments, ensuring compliance with tax regulations.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Represent you in response to income tax notices</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Help you to understand the reasons for the notice and your rights and obligations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Negotiate with the tax authorities on your behalf</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> File any necessary appeals or objections</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------7---Income Tax Audit--------------------------
       {
        label: (<label className='z-0'>Income Tax Audit</label>),
        value: "Income Tax Audit",
        desc: (
          <div>
          <p className='text-xl text-black'>Conduct comprehensive tax audits, including the examination of financial statements and tax compliance reports to ensure accuracy and compliance with Indian tax laws.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Conduct statutory and tax audits in accordance with applicable laws and regulations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide a detailed audit report with findings and recommendations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assist you in implementing the recommendations to improve your tax compliance</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------8--Net Worth and Valuation Certificate---------------------------
       {
        label: (<label className='z-0'>Net Worth and Valuation Certificate</label>),
        value: "Net Worth and Valuation Certificate",
        desc: (
          <div>
          <p className='text-xl text-black'>Provide professional assessments of a company's net worth and valuation, essential for transactions, investments, and financial decision-making.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Prepare a net worth and valuation certificate in accordance with accepted accounting standards</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Value your assets and liabilities to determine your net worth</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assist you in using the certificate for various purposes, such as obtaining a loan or visa</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------9---Accountancy--------------------------
      {
        label: (<label className='z-0'>Accountancy</label>),
        value: "Accountancy",
        desc: (
          <div>
          <p className='text-xl text-black'>Our in-house chartered accountants help you in preparing financial statements and managing accounts payable. We possess the expertise and knowledge to assist you in a wide range of areas related to accounting and financial management. From simple bookkeeping to complex financial analyses, we offer a wide range of services, such as:</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Preparing  accurate and timely financial statements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assess performance</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Meet reporting requirements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Prepare comprehensive Accounting statements</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------10---Auditing & Taxation--------------------------
      {
        label: (<label className='z-0'>Auditing & Taxation</label>),
        value: "Auditing & Taxation",
        desc: (
          <div>
          <p className='text-xl text-black'>Our chartered accountants can help with your company's finances, auditing, tax planning, startups, or incorporations. We have the skills for preparing tax returns, representing clients before tax authorities, and providing you with general tax advice.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Conducting audits</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Providing independent assessments of your financial statements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assessing the fairness and accuracy of financial records</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Identifying potential risks and providing solutions</li>
          </ul>
          </div>
        )
      },
      // ----------------------------------11--ITR Filing---------------------------
      {
        label: (<label className='z-0'>ITR Filing</label>),
        value: "ITR Filing",
        desc: (
          <div>
          <p className='text-xl text-black'>Our dedicated team of Chartered Accountants is committed to providing expert assistance in filing Income Tax Returns (ITR). We have a thorough understanding of the intricate tax regulations and strive to simplify the process for individuals and businesses.In addition, our Chartered Accountants helps,</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Review your financial documents for accurate and timely ITR filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proper tax planning and Income Tax Return filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> With their expertise in tax laws they provide personalised guidance</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensures you benefit from available tax benefits and avoid potential pitfalls.</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------12---GST Compliance, Audit, and Training--------------------------
       {
        label: (<label className='z-0'>GST Compliance, Audit, and Training</label>),
        value: "GST Compliance, Audit, and Training",
        desc: (
          <div>
          <p className='text-xl text-black'>Our chartered accountants have a strong understanding of GST compliance, audits, and training for the relevant rules and regulations, in addition to compliance for client handling. Govyaparhas been a one-stop solution for any business or entrepreneur looking for a chartered accountant. We have been particularly instrumental in ensuring startups are fully compliant with India’s legal requirements.</p>
          <br/>
          <ul className='text-black text-xl'>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Handling employment issues</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Processing insurance claims</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Resolving consumer complaints</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Managing motor accident claims</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------13--Tax on Property Purchase & Sales---------------------------
       {
        label: (<label className='z-0'>Tax on Property Purchase & Sales</label>),
        value: "Tax on Property Purchase & Sales",
        desc: (
          <div>
          <p className='text-xl text-black'>Offer guidance on tax implications related to buying and selling property, including stamp duty, capital gains, and property registration taxes.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advise on the tax implications of purchasing and selling property in India</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Calculate and file any applicable capital gains tax</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide assistance with stamp duty and other property-related taxes</li>
          </ul>
          </div>
        )
      },
       // ----------------------------------14--NRI Taxation and ITR Filings---------------------------
       {
        label: (<label className='z-0'>NRI Taxation and ITR Filings</label>),
        value: "NRI Taxation and ITR Filings",
        desc: (
          <div>
          <p className='text-xl text-black'>Assist non-resident Indians (NRIs) with tax compliance, including filing Income Tax Returns (ITR) and ensuring adherence to taxation laws for income earned in India.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advise on the tax implications of living and working abroad as an NRI</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Help to prepare and file NRI tax returns</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide assistance with claiming NRI tax benefits and exemptions</li>
          </ul>
          </div>  
        )
      },
         // ----------------------------------15--Tax on Consultancy & Freelancing---------------------------
        {
        label: (<label className='z-0'>Tax on Consultancy & Freelancing</label>),
        value: "Tax on Consultancy & Freelancing",
        desc: (
            <div>
            <p className='text-xl text-black'>Provide insights and services to manage tax liabilities for income earned through consultancy and freelancing work, ensuring compliance with Indian tax laws.</p>
            <br/>
            <ul className='text-black text-xl'>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advise on the tax implications of consultancy and freelancing income</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Calculate and file any applicable income tax</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i>Provide assistance with claiming business expenses and deductions</li>
            </ul>
            </div>
        )
        },
           // ----------------------------------16--ITR for Tenders---------------------------
        {
        label: (<label className='z-0'>ITR for Tenders</label>),
        value: "ITR for Tenders",
        desc: (
            <div>
            <p className='text-xl text-black'>Prepare and file Income Tax Returns specifically tailored to individuals or entities participating in government tenders, ensuring regulatory compliance and transparency.</p>
            <br/>
            <ul className='text-black text-xl'>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Help to prepare and file ITRs for tenders</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure that all relevant deductions and credits are claimed</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Minimise tax liability</li>
            </ul>
            </div>
        )
        },
           // ----------------------------------17--Others---------------------------
        {
        label: (<label className='z-0'>Others</label>),
        value: "Others",
        desc: (
            <div>
            <p className='text-xl text-black'>Provide advice and guidance on a range of tax issues, including:</p>
            <br/>
            <ul className='text-black text-xl'>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tax planning</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tax compliance</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tax disputes</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tax appeals</li>
            </ul>
            </div>
        )
        },    
    ]

    {/* --------------------------------Online CA Services For Business Registrations-------------------------------------- */}
    const duties = [
      {
        title: "Tax Planning & Advisory",
        description: "We minimize your tax liabilities by identifying legal deductions and credits."
      },
      {
        title: "Tax Return Preparation & Filing",
        description: "We ensure accurate and timely filing of income tax returns, GST returns, and other necessary forms, adhering to all compliance requirements."
      },
      {
        title: "Tax Representation",
        description: "Our CAs represent you before tax authorities in disputes or inquiries, providing expert guidance and support."
      },
      {
        title: "Company Formation & Registration",
        description: "We facilitate establishing new businesses by managing all registration aspects, obtaining licenses, and fulfilling legal requirements."
      },
      {
        title: "Financial Modeling & Forecasting",
        description: "Our CAs develop financial models to aid businesses in budgeting, planning, and securing funding, ensuring financial strategies."
      },
      {
        title: "Valuation Services",
        description: "Our CAs accurately determine the fair value of a business or its assets, crucial for mergers, acquisitions, and other financial activities."
      },
      {
        title: "Due Diligence",
        description: "We conduct thorough due diligence reviews to evaluate a business's financial health, providing critical insights before major transactions."
      },
      {
        title: "Debt Financing",
        description: "Our CAs assist businesses in securing loans and other debt financing by preparing complete documentation and financial statements, increasing approval likelihood."
      }
    ];

     // --------------------------------------Reviews----------------------------------------------------
     const reviews = [
      {
        name: 'Dhivya Kumar',
        rating: 5,
        review: "I wanted to consult a CA for planning my business finances. Vakilsearch's CA's were exceptional. They were knowledgeable, efficient, and provided timely support whenever I needed it. They really helped me keep my finances organised and saved me time",
      },
      {
        name: 'Devarsh',
        rating: 5,
        review: "I had a great experience working with Vakilsearch's online CA service. Their team was professional and provided me with valuable tax advice. They helped me with a lot of my finances and streamlined them. I have also started using Zolvit Dash for my business. Highly recommendable",
      },
      {
        name: 'Pintu Hegde',
        rating: 5,
        review: "Vakilsearch's online CA service was a lifesaver for me. Their team helped me file my taxes on time and provided expert advice on deductions and exemptions. I appreciated their personalised service and would highly recommend them to anyone in need of accounting assistance",
      },
    ];
    window.scrollTo(0,0);
  return (
    <>
     <div className=''>
    <a href='https://wa.me/919808030923'>
    <img src='WhatsappIcon.png' className='fixed w-[28%] md:w-[13%] lg:w-[10%] right-0 top-[520px] md:top-[470px] z-10' alt=''/></a>
     <Link to ="/scheduleCall"><button className='text-white bg-yellow-600 border-2 border-black p-2 rounded-full cursor-pointer z-20 fixed w-[40%] sm:w-[20%] md:w-[24%] lg:w-[20%] xl:w-[16%] right-3 top-[520px] md:top-[470px] overflow-hidden mt-16 sm:mt-24 xl:mt-24 text-xs md:text-lg'><i className="fa fa-clock text-white text-xs md:text-lg"></i> Schedule a Call</button></Link>
    </div>

   {/* -------------------------------------Mobile devices Registration ngo-------------------------------------/ */}
   <div className='max-w-full mx-0 px-8 block md:hidden pt-32'>
  <div className="relative flex flex-col text-gray-700 bg-[#C0C0C0] shadow-xl z-[-1]">
  <div className="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-clip-border">
  </div>
  <div className="p-6 text-center">
    <p>
        <form className='py-4 text-center'>
        <label className='pt-2'>Email</label><br/>
        <input type='email'
         placeholder='Enter your Email'
         className='w-full rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>Mobile Number</label><br/>
        <input type='tel'
          placeholder="Enter Phone Number" 
          maxLength={10}
         className='w-full rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>City/Pincode</label><br/>
        <input type=''
         placeholder='Enter your City/Pincode'
         className='w-full rounded-sm p-1 border-1'/>
         <br/>
         
       <label for="language" className="pt-2">Language</label>
        <select id="language" className="w-full p-1 rounded-sm">
          <option>English</option>
          <option>Hindi</option>
        </select>
        <br/>

        <label for="problem" className="pt-2">Problem Type</label><br/>
        <select id="problem" className="w-full p-1 rounded-sm">
        <option>Tax Advisory</option>
          <option>GST Audit Report (GSTR 9 & 9C)</option>
          <option>Transfer Pricing (Opinion Report)</option>
          <option>Transfer Pricing (Audit Report)</option>
          <option>ITR Filing for Companies</option>
          <option>ITR Filing for Individuals</option>
          <option>Auditing & Taxation</option>
          <option>Income Tax Notices under 133(6), 142(1), 143(1), 147, 148</option>
          <option>Net Worth and Valuation Certificate</option>
          <option>Accountancy & Bookkeeping</option>
          <option>International Taxation & FEMA</option>
          <option>NGO Compliance and Taxation</option>
          <option>Tax on Property Purchase & Sales</option>
          <option>NRI Taxation and ITR Filings</option>
          <option>Tax on Consultancy & Freelancing</option>
          <option>ITR for Tenders</option>
          <option>ITR Elite</option>
          <option>Others</option>
        </select>
      </form>
    </p>
  </div>
  <div className="flex justify-center p-6 py-2 mb-4 gap-7">
  <button className='items-center bg-black text-white p-2 rounded-md'>Book an Appointment Now</button>
  </div>
</div>
</div>
<div className=' bg-[#E0E0E0] relative bottom-60 z-[-2] pb-10 pt-80 px-4 block md:hidden'>
  <h1 className='text-2xl font-extrabold'>Online CA Consultation</h1>
  <h2 className="py-6 text-2xl font-semibold">
      <p className='text-lg text-justify'>
      Our CAs excel in providing comprehensive services for both businesses and individuals, including Compliance Services, Accounting and Financial Reporting, Taxation, Advisory, and much more.
      </p>
  </h2> 
</div>

<div className="max-w-xl mx-auto px-1 relative bottom-32">
      <h2 className="text-center text-md lg:text-xl font-semibold mb-3">Steps to Get Legal Consultation Online</h2>
      <div className="flex justify-around items-center">
        <div className="flex flex-col items-center">
          <img src="Fill Out the Form.png" alt="Fill Out the Form" className="w-16 h-16 mb-4 p-2"/>
          <p className="text-center text-sm">Fill Out the Form</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="Schedule an Appointment.png" alt="Schedule an Appointment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Schedule an Appointment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Make Payment.png" alt="Make Payment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Make Payment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Lawyer Will Call You.png" alt="Lawyer Will Call You" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Lawyer Will Call You</p>
        </div>
      </div>
    </div>

{/* ------------------------------------------------------MEDIUM AND LARGE SCREN- Registration ngo--------------------------------- */}
<div className="w-full mx-0 md:block hidden relative bottom-10">
  <div className='bg-[#E0E0E0] float-left w-[45%] h-screen pl-10 lg:pl-32 pt-24 xl:pl-52 2xl:pl-80'>
  <div className="relative flex flex-col text-gray-700 bg-[#C0C0C0] shadow-xl left-8 lg:left-12">
  <div className="relative mt-2 overflow-hidden text-gray-700 bg-clip-border">
  </div>
  <div className="px-6 py-3 text-center">
    <p>
      <form className='py-4 text-center hidden md:block'>
      <label className='pt-2'>Email</label><br/>
      <input type='email'
       placeholder='Enter your Email'
       className='rounded-sm p-1 border-1 w-full'/>
      <br/>

      <label className='pt-2'>Mobile Number</label><br/>
      <input type='tel'
        placeholder="Enter Phone Number" 
        maxLength={10}
       className='rounded-sm p-1 border-1 w-full'/>
      <br/>

      <label className='pt-2'>City/Pincode</label><br/>
      <input type=''
       placeholder='Enter your City/Pincode'
       className='rounded-sm p-1 border-1 w-full'/>
       <br/>

       <label for="language" className="pt-2">Language</label>
        <select id="language" className="w-full p-1 rounded-sm">
          <option>English</option>
          <option>Hindi</option>
        </select>
        <br/>

        <label for="problem" className="pt-2">Problem Type</label><br/>
        <select id="problem" className="w-full p-1 rounded-sm">
          <option>Tax Advisory</option>
          <option>GST Audit Report (GSTR 9 & 9C)</option>
          <option>Transfer Pricing (Opinion Report)</option>
          <option>Transfer Pricing (Audit Report)</option>
          <option>ITR Filing for Companies</option>
          <option>ITR Filing for Individuals</option>
          <option>Auditing & Taxation</option>
          <option>Income Tax Notices under 133(6), 142(1), 143(1), 147, 148</option>
          <option>Net Worth and Valuation Certificate</option>
          <option>Accountancy & Bookkeeping</option>
          <option>International Taxation & FEMA</option>
          <option>NGO Compliance and Taxation</option>
          <option>Tax on Property Purchase & Sales</option>
          <option>NRI Taxation and ITR Filings</option>
          <option>Tax on Consultancy & Freelancing</option>
          <option>ITR for Tenders</option>
          <option>ITR Elite</option>
          <option>Others</option>
        </select>
    </form>
    </p>
  </div>
  <div className="flex justify-center p-6 py-2 mb-4 gap-7">
  <button className='items-center bg-black text-white p-2 rounded-md'>Book an Appointment Now</button>
  </div>
</div>
  </div>

  <div className='bg-white h-screen px-10 pt-3 pr-28 lg:pt-16 lg:pr-32 xl:pr-52 2xl:pr-80'>
  <div className='relative bottom-64 pb-10 md:pt-[340px] lg:pt-[310px] xl:pt-[] px-7 left-10 lg:left-20 lg:px-10 overflow-hidden'>
  <h1 className='md:text-3xl lg:text-4xl xl:text-6xl font-extrabold'>Online CA Consultation</h1>
  <h2 className="py-4 text-xl lg:text-2xl font-semibold">

      <p className='text-xl text-justify'>
      Our CAs excel in providing comprehensive services for both businesses and individuals, including Compliance Services, Accounting and Financial Reporting, Taxation, Advisory, and much more.
      </p>
  </h2>
  <div className="max-w-xl mx-auto px-1">
      <h2 className="text-center text-md lg:text-xl font-semibold mb-3">Steps to Get Legal Consultation Online</h2>
      <div className="flex justify-around items-center">
        <div className="flex flex-col items-center">
          <img src="Fill Out the Form.png" alt="Fill Out the Form" className="w-16 h-16 mb-4 p-2"/>
          <p className="text-center text-sm">Fill Out the Form</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="Schedule an Appointment.png" alt="Schedule an Appointment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Schedule an Appointment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Make Payment.png" alt="Make Payment" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Make Payment</p>
        </div>
        
        <div className="flex flex-col items-center">
          <img src="Lawyer Will Call You.png" alt="Lawyer Will Call You" className="w-16 h-16 mb-4"/>
          <p className="text-center text-sm">Lawyer Will Call You</p>
        </div>
      </div>
    </div>
</div>
  </div>
</div>


{/* --------------------------------RATINGS------------------------ */}
<div className="bg-gray-100 py-8 px-4 relative bottom-10">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3  gap-6 text-center">
        {stats.map((stat, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="mb-2">{stat.icon}</div>
            <p className="text-lg font-semibold">{stat.label}</p>
            <p className="text-xl font-bold text-yellow-500">{stat.value}</p>
          </div>
        ))}
      </div>
    </div>

{/* -----------------------------slider---------------------- */}
<div className='md:bg-gray-900 overflow-hidden py-5 relative bottom-10'>
 <div className="max-w-2xl mx-auto">
      <Slider {...settings} >
        {/* --------------------------1--Tax Advisory------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Tax Advisory 1.png" alt="Tax Advisory" />
            <h3 className="text-sm font-medium mb-4">Tax <br/>Advisory</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------2--GST Audit Report (GSTR 9 & 9C)------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="GST Audit Report 2.png" alt="GST Audit Report (GSTR 9 & 9C)" />
            <h3 className="text-sm font-medium md:mb-4 lg:mb-0">GST Audit Report <br className='block lg:hidden'/> (GSTR 9 & 9C)</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------3--Transfer Pricing (Opinion Report)------------------- */}
        {/* <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Transfer Pricing (Opinion Report) 3.png" alt="Transfer Pricing (Opinion Report)" />
            <h3 className="text-sm font-medium mb-4">Transfer Pricing (Opinion Report)</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div> */}

        {/* --------------------------4--Transfer Pricing (Audit Report)------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Transfer Pricing 4.png" alt="Transfer Pricing (Audit Report)" />
            <h3 className="text-sm font-medium mb-4">Transfer Pricing (Audit Report)</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------5---ITR Filing for Companies------------------ */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="ITR Filing for Companies 5.png" alt="ITR Filing for Companies" />
            <h3 className="text-sm font-medium mb-4">ITR Filing for Companies</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------6----ITR Filing for Individuals----------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="ITR Filing for Individuals 6.png" alt="IITR Filing for Individuals" />
            <h3 className="text-sm font-medium mb-4">ITR Filing for Individuals</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------7---Auditing & Taxation------------------ */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Auditing & Taxation 7.png" alt="Auditing & Taxation" />
            <h3 className="text-sm font-medium mb-4">Auditing & Taxation</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>

        {/* --------------------------8--Income Tax Notices------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Income Tax Notices 8.png" alt="Income Tax Notices" />
            <h3 className="text-sm font-medium mb-4">Income Tax Notices</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div> 

         {/* --------------------------9--Net Worth and Valuation Certificate------------------- */}
         {/* <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Net Worth and Valuation Certificate 9.png" alt="Net Worth and Valuation Certificate" />
            <h3 className="text-sm font-medium mb-4">Net Worth and Valuation Certificate</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>  */}

         {/* --------------------------10--Accountancy & Bookkeeping------------------- */}
         <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Accountancy & Bookkeeping 10.png" alt="Accountancy & Bookkeeping" />
            <h3 className="text-sm font-medium mb-4">Accountancy & Bookkeeping</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div> 

         {/* --------------------------11--International Taxation & FEMA------------------- */}
         {/* <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="AInternational Taxation & FEMA 11.png" alt="International Taxation & FEMA" />
            <h3 className="text-sm font-medium mb-4">International Taxation & FEMA</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>  */}

         {/* --------------------------12--NGO Compliance and Taxation------------------- */}
         {/* <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="NGO Compliance and Taxation 12.png" alt="NGO Compliance and Taxation" />
            <h3 className="text-sm font-medium mb-4">NGO Compliance and Taxation</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>  */}

         {/* --------------------------13--Tax on Property Purchase & Sales------------------- */}
         {/* <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Tax on Property Purchase & Sales 13.png" alt="Tax on Property Purchase & Sales" />
            <h3 className="text-sm font-medium mb-4">Tax on Property Purchase & Sales</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>  */}

         {/* --------------------------14--NRI Taxation and ITR Filings------------------- */}
         <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="NRI Taxation and ITR Filings 14.png" alt="NRI Taxation and ITR Filings" />
            <h3 className="text-sm font-medium mb-4">NRI Taxation and ITR Filings</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div> 

        {/* --------------------------15--Tax on Consultancy & Freelancing------------------- */}
        {/* <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Tax on Consultancy & Freelancing 15.png" alt="Tax on Consultancy & Freelancing" />
            <h3 className="text-sm font-medium mb-4">Tax on Consultancy & Freelancing</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>  */}

        {/* --------------------------16--ITR for Tenders------------------- */}
        <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="ITR for Tenders 16.png" alt="ITR for Tenders" />
            <h3 className="text-sm font-medium mb-4">ITR for <br/>Tenders</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div> 

         {/* --------------------------17--ITR Elite------------------- */}
         <div className="p-2 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg text-center p-4 flex flex-col">
            <img className="w-20 h-20 mx-auto mb-4" src="Slider1.jpg" alt="ITR Elite" />
            <h3 className="text-sm font-medium mb-4">ITR <br/>Elite</h3>
            <button className="bg-yellow-500 text-white p-2 rounded transition duration-300 hover:bg-yellow-700">
              Consult now
            </button>
          </div>
        </div>     
      </Slider>
 </div> 
</div>

    {/* ------------------------------------------Areas of Expertise------------------------------------ */}
    <h1 className='text-5xl text-center text-bold text-black md:block hidden md:pt-5'>Areas of Expertise</h1>
    <div className='px-28 py-10 hidden md:block'>
    {/* <Tabs value={activeTab}
    className="grid grid-cols-2 grid-rows-1">
  
      <TabsHeader
        className="rounded-md bg-white shadow-md border-1 border-black pt-10 z-0 flex-col md:w-[70%] xl:w-[60%]"
        indicatorProps={{
          className:
            "bg-transparent shadow-none rounded-none",
        }}
      >
        {data2.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            className={activeTab === value ? "text-yellow-500 text-xl font-bold bg-black rounded-md" : ""}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody
      className='relative right-10 xl:right-16 text-justify'>
        {data2.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs> */}
    </div>

   {/* ------------------------------------------Areas of Expertise(phone)------------------------------------ */}
   <h1 className='text-3xl text-center text-bold text-black md:hidden block relative bottom-10 md:pt-5'>Areas of Expertise</h1>
    <div className='px-2 lg:px-20 cursor-pointer block md:hidden'>

    {/* ---------------------------------------1--Tax Advisory------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Tax Advisory</h1>
        <div className='text-md text-black'>
         <p>Our team of skilled Chartered Accountants at Govyaparprovides extensive tax advisory services to help individuals and businesses navigate the intricate world of taxation. We remain well-informed about the latest tax laws and regulations, ensuring that you receive precise and timely advice customised to your specific requirements.Our experts help,</p>
         <br/>
         <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Better tax planning structure and explains the available deductions</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse your financial situation and assess potential risks.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Analyse your financial situation and assess potential risks.</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure that you make informed decisions and remain compliant with tax laws.</li>
          </ul>
          </div>

    {/* ---------------------------------------2--GST Audit Report (GSTR 9 & 9C)------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>GST Audit Report (GSTR 9 & 9C)</h1>
        <div className='text-md text-black'>
          <p>Verify and certify the accuracy of a company's GST-related financial information for regulatory compliance.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure compliance with GST regulations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Verify accuracy of financial statements and GST returns</li>
          </ul>
          </div>

          {/* ---------------------------------------3---Transfer Pricing (Opinion Report)------------------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Transfer Pricing (Opinion Report)</h1>
        <div className='text-md text-black'>
          <p>Provide expert guidance on setting appropriate prices for cross-border transactions, ensuring compliance with tax regulations.</p>
          <br/>
          <ul className='text-black text-xl'>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Independent opinion on transfer pricing arrangements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assess compliance with OECD Transfer Pricing Guidelines</li>
          </ul>
          </div>  
  
    {/* ---------------------------------------4--Transfer Pricing (Audit Report)------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Transfer Pricing (Audit Report)</h1>
        <div className='text-md text-black'>
          <p>Conduct a thorough examination of a company's transfer pricing records to verify adherence to tax laws and minimize risks associated with   cross-border transactions.
          </p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Full audit of transfer pricing arrangements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Review documentation and transactions for compliance with Indian tax laws and regulations</li>
          </ul>
          </div>

           {/* ---------------------------------------5--ITR Filing for Companies------------------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>ITR Filing for Companies</h1>
        <div className='text-md text-black'>
          <p>Professionally prepare and submit Income Tax Returns for companies, optimising tax efficiency while maintaining compliance with Indian tax regulations.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Preparation and filing of corporate income tax return (ITR)</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensure accuracy and completeness of return</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Claim all eligible deductions and credits</li>
          </ul>
          </div> 

          {/* ---------------------------------------6--Income Tax Notices------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Income Tax Notices</h1>
        <div className='text-md text-black'>
          <p>Handle and respond to various income tax notices, such as inquiries, assessments, and reassessments, ensuring compliance with tax regulations.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Represent you in response to income tax notices</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Help you to understand the reasons for the notice and your rights and obligations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Negotiate with the tax authorities on your behalf</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> File any necessary appeals or objections</li>
          </ul>
          </div> 

          {/* ---------------------------------------7--Income Tax Audit----------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Income Tax Audit</h1>
        <div className='text-md text-black'>
          <p>Conduct comprehensive tax audits, including the examination of financial statements and tax compliance reports to ensure accuracy and compliance with Indian tax laws.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Conduct statutory and tax audits in accordance with applicable laws and regulations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide a detailed audit report with findings and recommendations</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assist you in implementing the recommendations to improve your tax compliance</li>
          </ul>
          </div> 

          {/* ---------------------------------------8--Net Worth and Valuation Certificate------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Net Worth and Valuation Certificate</h1>
        <div className='text-md text-black'>
          <p>Provide professional assessments of a company's net worth and valuation, essential for transactions, investments, and financial decision-making.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Prepare a net worth and valuation certificate in accordance with accepted accounting standards</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Value your assets and liabilities to determine your net worth</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assist you in using the certificate for various purposes, such as obtaining a loan or visa</li>
          </ul>
          </div>

          {/* ---------------------------------------9--Accountancy------------------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Accountancy</h1>
        <div className='text-md text-black'>
          <p>Our in-house chartered accountants help you in preparing financial statements and managing accounts payable. We possess the expertise and knowledge to assist you in a wide range of areas related to accounting and financial management. From simple bookkeeping to complex financial analyses, we offer a wide range of services, such as:</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Preparing  accurate and timely financial statements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assess performance</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Meet reporting requirements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Prepare comprehensive Accounting statements</li>
          </ul>
          </div>  


      {/* ---------------------------------------10--Auditing & Taxation------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Auditing & Taxation</h1>
        <div className='text-md text-black'>
          <p>Our chartered accountants can help with your company's finances, auditing, tax planning, startups, or incorporations. We have the skills for preparing tax returns, representing clients before tax authorities, and providing you with general tax advice.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Conducting audits</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Providing independent assessments of your financial statements</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Assessing the fairness and accuracy of financial records</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Identifying potential risks and providing solutions</li>
          </ul>
          </div>       

      {/* ---------------------------------------11--ITR Filing---------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>ITR Filing</h1>
        <div className='text-md text-black'>
          <p>Our dedicated team of Chartered Accountants is committed to providing expert assistance in filing Income Tax Returns (ITR). We have a thorough understanding of the intricate tax regulations and strive to simplify the process for individuals and businesses.In addition, our Chartered Accountants helps,</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Review your financial documents for accurate and timely ITR filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Proper tax planning and Income Tax Return filing</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> With their expertise in tax laws they provide personalised guidance</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Ensures you benefit from available tax benefits and avoid potential pitfalls.</li>
          </ul>
          </div>  
         
      {/* -------------------------------------------12-GST Compliance, Audit, and Training---------------------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>GST Compliance, Audit, and Training</h1>
        <div className='text-md text-black'>
          <p>Our chartered accountants have a strong understanding of GST compliance, audits, and training for the relevant rules and regulations, in addition to compliance for client handling. Govyaparhas been a one-stop solution for any business or entrepreneur looking for a chartered accountant. We have been particularly instrumental in ensuring startups are fully compliant with India’s legal requirements.</p>
          <br/>
          <ul>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Handling employment issues</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Processing insurance claims</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Resolving consumer complaints</li>
            <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Managing motor accident claims</li>
          </ul>
          </div>    

        {/* ------------------------------------13--Tax on Property Purchase & Sales------------------------------------ */}
        <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Tax on Property Purchase & Sales</h1>
        <div className='text-md text-black'>
          <p>Offer guidance on tax implications related to buying and selling property, including stamp duty, capital gains, and property registration taxes.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advise on the tax implications of purchasing and selling property in India</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Calculate and file any applicable capital gains tax</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide assistance with stamp duty and other property-related taxes</li>
          </ul>
          </div>

      {/* ----------------------------------------14---NRI Taxation and ITR Filings------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>NRI Taxation and ITR Filings</h1>
        <div className='text-md text-black'>
          <p>Assist non-resident Indians (NRIs) with tax compliance, including filing Income Tax Returns (ITR) and ensuring adherence to taxation laws for income earned in India.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advise on the tax implications of living and working abroad as an NRI</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Help to prepare and file NRI tax returns</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide assistance with claiming NRI tax benefits and exemptions</li>
          </ul>
          </div>

            {/* ----------------------------------------15---Tax on Consultancy & Freelancing------------ */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Tax on Consultancy & Freelancing</h1>
        <div className='text-md text-black'>
          <p>Assist non-resident Indians (NRIs) with tax compliance, including filing Income Tax Returns (ITR) and ensuring adherence to taxation laws for income earned in India.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advise on the tax implications of living and working abroad as an NRI</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Help to prepare and file NRI tax returns</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide assistance with claiming NRI tax benefits and exemptions</li>
          </ul>
          </div>

            {/* ----------------------------------------16--ITR for Tenders---------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>ITR for Tenders</h1>
        <div className='text-md text-black'>
          <p>Assist non-resident Indians (NRIs) with tax compliance, including filing Income Tax Returns (ITR) and ensuring adherence to taxation laws for income earned in India.</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Advise on the tax implications of living and working abroad as an NRI</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Help to prepare and file NRI tax returns</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Provide assistance with claiming NRI tax benefits and exemptions</li>
          </ul>
          </div>

            {/* ----------------------------------------17--Others----------------- */}
      <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold pt-3'>Others</h1>
        <div className='text-md text-black'>
          <p>Provide advice and guidance on a range of tax issues, including:</p>
          <br/>
          <ul>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tax planning</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tax compliance</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tax disputes</li>
          <li><i className="fa-solid fa-check text-xl text-yellow-500"></i> Tax appeals</li>
          </ul>
          </div>
    </div>

    {/* --------------------------------------------Online CA Services For Business Registrations-------------------------------------- */}
    <div className="bg-gray-50 py-10">
      <h2 className="text-center text-2xl md:text-3xl font-semibold mb-2">Online CA Services For Business Registrations</h2>
      <p className='text-md text-black text-center mb-10'>The following are the online CA services for business registrations:</p>
      <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-6 px-4">
        {duties.map((duty, index) => (
          <div key={index} className="flex items-start space-x-4">
            <svg className="text-yellow-700 w-6 h-6 mt-1" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11.414l2.293 2.293a1 1 0 11-1.414 1.414L10 8.414 7.707 10.707a1 1 0 11-1.414-1.414L9 6.586l1.293-1.293a1 1 0 011.414 1.414z" clipRule="evenodd" />
            </svg>
            <div>
              <h3 className="text-lg font-medium text-yellow-600">{duty.title}</h3>
              <p>{duty.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>

    {/* --------------------------------------Who is a CA----------------------- */}
    <div className="bg-yellow-50 text-gray-800 p-5 text-justify">
      <div className="max-w-5xl mx-auto p-8 ">
        <h1 className="text-2xl font-bold text-gray-700 mt-4">Who is a CA?</h1>
        <p className="mb-4">
          A Chartered Accountant (CA) is a highly qualified financial professional recognised for their expertise in accounting, taxation, and business strategy. They are members of a professional accounting body that has granted them the 'Chartered' status after thorough qualifications and rigorous examinations. In order to be legally recognised as a CA they should be:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>A member of a recognised professional accounting body</li>
          <li>Completed extensive training and passed challenging examinations in accounting, finance, and taxation</li>
          <li>Adhere to a strict code of ethics and professional conduct</li>
          <li>Equipped to perform various tasks like managing finances, accounts, budgeting, auditing, and advising on business strategy</li>
        </ul>

        {/* -------------------------------------------------Benefits of online CA Services----------------------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">Benefits of Online CA Services</h2>
        <p className="mb-4">
          Online CA consultation offers the convenience of seeking expert financial advice from the comfort of your home or office. Here are a few benefits given below:
        </p>
        <ul className="list-disc list-inside space-y-4">
          <li>
            <strong>Convenience and Accessibility</strong>
            <p>Enjoy the convenience of accessing your financial information anytime, anywhere. Our online platform ensures accessibility, allowing you to manage your finances on your terms.</p>
          </li>
          <li>
            <strong>Time Efficiency</strong>
            <p>Save valuable time with our efficient online CA services. We understand the importance of time in business, and our platform is designed to optimise processes, allowing you to focus on what matters most – growing your business.</p>
          </li>
          <li>
            <strong>Expert Advice</strong>
            <p>Benefit from expert advice from seasoned professionals. Our team of experienced Chartered Accountants is dedicated to providing you with insights and guidance tailored to your specific financial needs.</p>
          </li>
          <li>
            <strong>Cost-Effectiveness</strong>
            <p>Our cost-effective online services offer financial solutions that fit your budget. Say goodbye to unnecessary expenses while still receiving top-notch CA expertise.</p>
          </li>
          <li>
            <strong>Secure Document Sharing</strong>
            <p>Rest easy knowing that your sensitive documents are handled with the utmost security. Our platform ensures secure data management, safeguarding your critical information from unauthorised access.</p>
          </li>
          <li>
            <strong>Flexible Scheduling</strong>
            <p>Adapt our services to your schedule. Enjoy the flexibility of setting appointments and managing tasks at your convenience, ensuring that our services align with your unique business requirements.</p>
          </li>
          <li>
            <strong>Real-time Solutions</strong>
            <p>Stay ahead with real-time updates and solutions. We understand the fast-paced nature of business, and our platform is equipped to provide timely responses and solutions to keep you informed and in control.</p>
          </li> 
          <li>
            <strong>Remote Support</strong>
            <p>Experience hassle-free support from anywhere you operate. Our online support services connect you with our experts remotely, ensuring that assistance is just a click away.</p>
          </li>
          <li>
            <strong>Increased Efficiency</strong>
            <p>Drive efficiency in your financial operations. Our services are designed to enhance overall efficiency, allowing you to optimise resources and achieve your business goals more effectively..</p>
          </li>
          <li>
            <strong>Transparent Communication</strong>
            <p>Foster transparency in your financial dealings. Our commitment to transparent communication ensures that you are well-informed, building trust and clarity in every interaction.<br/><br/>

            We have the best chartered accountants in India. Our team collaborates with multiple clients to create a lasting partnership that will help them grow and enhance their operations. Govyaparoffer an exceptional online CA service that serves as a virtual directory where customers get access to:
            <br/><br/><ol className='list-disc list-inside'>
              <li><strong>Top chartered accountants in india</strong></li>
              <li><strong>Consult online chartered accountant</strong></li>
              <li><strong>Best online chartered accountant services</strong></li>
              <li><strong>Talk to top chartered accountants in India</strong></li>
              <li><strong>File online taxes through Govyapar- the best CA firms in india</strong></li>
              <li><strong>Online CA services for business</strong></li>
            </ol> 
            </p>
          </li>
        </ul>
        {/* -------------------------------------Why Consult a Chartered Accountant?----------------------------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">Why Consult a Chartered Accountant?</h2>
        <p className="mb-4">
        CA's offer a wide range of benefits for both individuals and businesses. Here are some of the ways where a CA can help you out:
        </p>
        <ul className="list-disc list-inside space-y-4">
          <li>
            <strong>Expertise:</strong>
            <p>Their in-depth knowledge and experience ensure accurate and reliable financial guidance</p>
          </li>
          <li>
            <strong>Compliance:</strong>
            <p>They ensure your financial affairs comply with relevant regulations and tax laws</p>
          </li>
          <li>
            <strong>Strategic Advice:</strong>
            <p>They provide valuable insights and recommendations for maximising financial performance and achieving business goals.</p>
          </li>
        </ul>

        {/* ---------------------------------------Importance of Chartered Accountant----------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">Importance of Chartered Accountant</h2>
        <p className="mb-4">
        CAs play a crucial role in the financial health of individuals, businesses, and the economy as a whole. They ensure transparency, integrity, and efficiency in financial matters, contributing to growth and prosperity.
        </p>

        {/* ---------------------------------------Roles and Responsibilities of Chartered Accountants----------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">Roles and Responsibilities of Chartered Accountants</h2>
        <p className="mb-4">
        CA's play a Key role in financial management. They are responsible for precise reporting, thorough audits, and strategic financial advising. In addition to guaranteeing tax and regulatory compliance, their responsibilities also include offering advice on risk management and cost optimisation. Here are some of the roles and responsibilities of Chartered accountants:
        </p>
        <ul className="list-disc list-inside">
          <li>
            <strong>Audit financial statements</strong>
          </li>
          <li>
            <strong>Prepare and file tax returns</strong>
          </li>
          <li>
            <strong>Provide financial advice and consulting services</strong>
          </li>
          <li>
            <strong>Manage accounts and budgets</strong>
          </li>
          <li>
            <strong>Develop and implement financial strategie</strong>
          </li>
          <li>
            <strong>Analyse financial data and prepare reports</strong>
          </li>
          <li>
            <strong>Act as forensic accountants in fraud investigations.</strong>
          </li>
        </ul>

        {/* ---------------------------------------Highlights of Chartered Accountants----------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">Highlights of Chartered Accountants</h2>
        <p className="mb-4">
        Every nation has a professional organisation that oversees the CA certification process and grants credentials upon the conclusion of the CA employment procedure. The Institute of Chartered Accountants of India (ICAI) oversees the examination and appointment of CA’s in India . Make sure to find the CA’s specialty before you start searching for the best chartered accountant. At Govyaparwe have dedicated CA’s who specialise in each of the above mentioned sectors. Being one  the best of the CA firms in India, we connect you with the right CA’s and help in filing your tax returns on time.
        </p>

        {/* --------------------------------------Why Govyapar for CA Online Consultation?----------------- */}
        <h2 className="text-xl font-bold text-gray-700 mt-4">Why Govyapar for CA Online Consultation?</h2>
        <p className="mb-4">
        At Govyapar, we recognise the significance of expert financial guidance for your business. Here are compelling reasons to choose us for your CA online consultation needs:
        </p>
        <ul className="list-disc list-inside space-y-4">
          <li>
            <strong>Experienced Professionals:</strong>
            <p>Benefit from the expertise of seasoned Chartered Accountants. Our professionals bring years of experience and in-depth knowledge to address your specific financial challenges.</p>
          </li>
          <li>
            <strong>Tailored Solutions:</strong>
            <p>Receive personalised advice and solutions tailored to your business requirements. We understand that each business is unique, and our CAs are dedicated to providing customised financial guidance.</p>
          </li>
          <li>
            <strong>Convenience at Your Fingertips:</strong>
            <p>Enjoy the convenience of accessing top-notch CA services anytime, anywhere. Our online platform ensures that expert consultation is just a click away, providing flexibility that suits your schedule.</p>
          </li>
          <li>
            <strong>Time Efficiency:</strong>
            <p>Save valuable time with our efficient online consultation process. We prioritise your time, delivering prompt and insightful solutions to keep your business moving forward.</p>
          </li>
          <li>
            <strong>Transparent Communication:</strong>
            <p>Experience clear and transparent communication throughout the consultation process. We believe in keeping you informed at every step, fostering trust and understanding.</p>
          </li>
          <li>
            <strong>Cost-Effective Services:</strong>
            <p>Avail cost-effective CA services without compromising on quality. Our commitment to offering value for money ensures that you receive the best financial expertise within your budget.</p>
          </li>
          <li>
            <strong>Real-time Updates:</strong>
            <p>Stay informed with real-time updates on your financial matters. Our platform is equipped to provide instant solutions and updates, keeping you in control of your business finances.</p>
          </li>
          <li>
            <strong>Secure and Confidential:</strong>
            <p>Trust in our secure online platform for confidential document sharing and data management. We prioritise the security of your sensitive information, ensuring privacy and peace of mind.</p>
          </li>
          <li>
            <strong>Efficient Problem Resolution:</strong>
            <p>Experience efficient and effective problem resolution. Our CAs are adept at promptly identifying and addressing financial challenges, contributing to your business's overall success.</p>
          </li>
        </ul>

        

      </div>
    </div>

    {/* --------------------------------Reviews---------------------------------- */}
    <section className="py-12">
      <h2 className="text-3xl font-bold text-center mb-8">Reviews</h2>
      <div className="flex flex-wrap justify-center gap-8">
        {reviews.map((review, index) => (
          <div key={index} className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 w-80">
            <div className="flex items-center mb-4">
              {/* <img src={`https://via.placeholder.com/150`} alt={`${review.name}`} className="w-16 h-16 rounded-full mr-4" /> */}
              <div>
                <h3 className="text-xl font-semibold">{review.name}</h3>
                <div className="text-yellow-500">
                  {'⭐'.repeat(review.rating)}
                </div>
              </div>
            </div>
            <p className="text-gray-700">{review.review}</p>
          </div>
        ))}
      </div>
    </section>

    {/* ---------------------------------------------------FAQS------------------------------------------------------- */}
    <section className=" text-black py-24 min-h-screen">
  <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
    <h2 className="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">Frequently Asked Questions</h2>
    <div className="flex flex-col divide-y sm:px-8 lg:px-20 xl:px-32 divide-yellow-600">
      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What questions should I ask before hiring a chartered accountant?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>Before engaging any chartered accountant, one must be clear that why they want to hire a chartered accountant.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Is online CA consultation confidential?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>Online CA consultations are completely confidential, and Govyaparwill never share your information with anyone. Our customers' privacy is our top priority.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can I communicate with CA in my native language?</summary>
        <div className="px-4 pb-4 text-justify">
          <p>Yes, you can communicate with CA in your native language. Govyaparoffers consultation in 8 Indian languages.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What questions should I ask a chartered accountant?</summary>
        <div className="px-4 pb-4">
          <p>You can ask questions about business registration, legal compliance, tax compliance, GST, tax benefits, tax savings, different types of business formations, income tax, start-up registration, fund raising, and online business, among other things.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Where can you find India's best chartered accountants?</summary>
        <div className="px-4 pb-4">
          <p>Govyaparhas a full fleet of online registered chartered accountants across India and offers a one-stop solution for all financial and compliance services for all of your business needs across India. With Vakilsearch, you can find chartered accountants all over India and book an appointment or use their services online.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What is the operation of an online chartered accountant (CA) services provider?</summary>
        <div className="px-4 pb-4">
          <p>A digital platform where you can find the best financial and compliance solutions for your business is what online CA services are. Govyaparhelps you overcome geographical barriers and take advantage of chartered accountant services.</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What skills do chartered accountants have?</summary>
        <div className="px-4 pb-4">
          <p>Chartered accountants are financial and compliance experts, and their skill sets vary from person to person. A chartered accountant must be proficient in the following areas:</p>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How to find an income tax consultant?</summary>
        <div className="px-4 pb-4">
          <p>Govyaparmakes it simple to find an income tax consultant in your area. The steps to finding an income tax consultant are as follows:</p>
          <ul className='list-disc'>
            <li>Enter your details on the home page, such as your phone number, email address etc</li>
            <li>Choose the service you want to avail</li>
            <li>And then click the 'Book an appointment now' button.</li>
          </ul>
        </div>
      </details>

      <details>
        <summary className="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Which is the best income tax consultancy service provider in India?</summary>
        <div className="px-4 pb-4">
          <p>Govyaparis the best income tax consulting service in India. On our platform, chartered accountants from across the whole of India have signed up to offer their services. You can book an appointment with a chartered accountant in your area to get help with your income tax.</p>
        </div>
      </details>

    </div>
  </div>
</section>
  
     

    </>
  )
}

export default TalkCA
